._Alert {
  background-color: #000;
  color: white;
  height: 50px;
  width: 260px;
  position: absolute;
  z-index: 2;
  bottom: 15px;
  left: 15px;
  font-size: 14px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-family: "Roboto";
}
