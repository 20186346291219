@import '../../../GlobalVariables/colors.scss';
._NewGroupMessage {
  .modal-1 {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 50; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    // background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
    align-content: center;
  }

  /* Modal Content */
  .modal-content-new {
    background-color: $white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px;
    margin-top: 13%;
    width: 557px;
  }
  .modal-content-top {
    background-color: $white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px;
    margin-top: 7%;
    width: 440px;
  }
  .modal-content-1 {
    background: $white;
    margin: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    color: $primary-blue;
    align-content: center;
    font-size: 20px;
    border-bottom: 1px solid $primary-blue;
    font-family: 'Roboto';
    font-weight: 500;
    display: flex;
    padding: 15px;
    .arrow {
      height: 16px;
      width: 16px;
      cursor: pointer;
      align-self: center;
      margin-left: 10px;
    }
    .header {
      margin-left: 10px;
      font-size: 14px;
      font-family: 'Roboto';
      font-weight: 500;
    }
  }
  .modal-content-add-members {
    background: $white;
    margin: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    color: $primary-blue;
    align-content: center;
    font-size: 20px;
    border-bottom: 1px solid $primary-blue;
    font-family: 'Roboto';
    font-weight: 500;
    display: flex;
    padding-bottom: 10px;
    .plus-container-left {
      background-color: $primary-blue;
      width: 40px;
      margin-left: 30px;
      display: flex;
      justify-content: center;
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .plus {
        height: 16px;
        width: 16px;
        filter: brightness(0) invert(1);
      }
    }
    .add-header {
      margin-left: 10px;
      margin-top: 10px;
      font-size: 14px;
      font-family: 'Roboto';
      font-weight: 500;
    }

    
    .close-guest{
        width: 10px;
        margin-left: 50%;
        cursor: pointer;
 
      }
  }
  .modal-content-2 {
    background: $white;
    margin: auto;
    padding-left: 20px;
    padding-bottom: 15px;
    margin-top: -15px;
    display: flex;
    color: $primary-blue;
    font-weight: 500;
    align-content: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 18px;
    border-bottom: 0.5px solid $primary-blue;
    font-family: 'Roboto';
    flex: 1;
    .left {
      display: flex;
      flex: 3;
      .camera {
        background-color: $primary-blue;
        width: 43px;
        height: 40px;
        margin-left: 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        .img {
          width: 21px;
          height: 21px;
          margin: auto;
        }
      }
      .popup-div1-h1 {
        padding-left: 30px;
        padding-top: 10px;
      }
    }

    .right {
      display: flex;
      flex: 1;
      margin-right: 5%;

      .reupload {
        background-color: $primary-blue;
        color: $white;
        height: max-content;
        font-size: 11px;
        padding: 3px 14px;
        border-radius: 20px;
        margin-top: 20px;
        display: flex;
        cursor: pointer;
        margin-right: 15%;
        .img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
          align-self: center;
        }
      }
      .close {
        margin-top: 20px;
        width: 15px;
        align-self: center;
        cursor: pointer;
      }
    }
  }
  .circle {
    background-color: $primary-blue;
    margin: auto;
    padding: 20px;
    width: 60px;
    float: right;
    margin-top: -28px;
    height: 60px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -2px;
    cursor: pointer;
    position: relative;
  }
  .inner-container {
    display: flex;
    justify-content: center;
  }
  .inner-container-2 {
    display: flex;
    height: 220px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .upload-grp-pic {
    background-color: $light-gray;
    height: 80px;
    width: 80px;
    border-radius: 40px;
    justify-content: center;
    display: flex;
    margin: auto;
    margin-top: 25px;
  }
  .search-bar-wrapper-1 {
    background-color: $light-gray;
    width: 350px;
    border-radius: 24px;
    margin-bottom: 30px;
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
    align-self: center;

    .selected {
      background-color: $gray;
      border-radius: 16px;
      padding-right: 16px;
      padding-left: 16px;
      margin-right: 16px;
      padding-top: 4px;
      padding-bottom: 4px;
      text-align: left;
      font-family: 'Roboto';
      letter-spacing: 0px;
      color: $text-black;
      margin-bottom: 10px;
      font-size: 12px;
      .close-ic {
        height: 8px;
        width: 8px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    input {
      outline: none;
      border: none;
      background-color: $light-gray;
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
    }
    ::placeholder {
      /* Most modern browsers support this now. */
      color: $dark-gray;
    }
  }
  .search-bar-wrapper {
    background-color: $light-gray;
    width: 350px;
    border-radius: 24px;
    margin-bottom: 30px;
    padding-left: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 12px;
    align-self: center;

    .selected {
      background-color: $gray;
      border-radius: 16px;
      padding-right: 16px;
      padding-left: 16px;
      margin-right: 16px;
      padding-top: 4px;
      padding-bottom: 4px;
      text-align: left;
      font-family: 'Roboto';
      letter-spacing: 0px;
      color: $text-black;
      margin-bottom: 10px;
      font-size: 12px;
      .close-ic {
        height: 8px;
        width: 8px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    input {
      outline: none;
      border: none;
      background-color: $light-gray;
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
    }
    ::placeholder {
      /* Most modern browsers support this now. */
      color: $dark-gray;
    }
  }
  .modal-content {
    box-shadow: 2px 6px 8px 0px rgba(0, 0, 0, 0.75) !important;
    margin-top: 5%;
    width: 500px;
  }
  .inner-container-3 {
    height: 45vh;
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
  .popup-div1 {
    height: 80px;
    background-color: $primary-blue;
    display: flex;
    flex: 1;
    align-items: center;
    .popup-div1-h1 {
      color: $white;
      font-size: 20px;
      font-family: 'roboto';
      font-weight: bold;
      flex: 2;
      margin-left: 20px;
    }

    .popup-div1-p {
      float: right;
      color: $white;
      font-size: 13px;
      cursor: pointer;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
    }
    .close-icon {
      height: 12px;
      margin-right: 10px;
      align-items: center;
      cursor: pointer;
    }
  }

  .reactEasyCrop_Container {
    position: absolute;
    top: 7%;
    left: 10%;
    bottom: 0;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    touch-action: none;
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 80%;
  }
  .reactEasyCrop_Contain {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    position: absolute;
    top: -10px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .reactEasyCrop_CropArea {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0 0 0 9999em;
    color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
  .add-photo-button {
    background-color: $buttn-blue;
    border: none;
    border-radius: 5px;
    width: 19%;
    height: 4.5vh;
    margin-top: -7.5%;
    margin-right: 10%;
    color: $white;
    float: right;
  }
  .MuiSlider-rail {
    width: 445px;
    height: 1.5px;
    display: block;
    opacity: 0.38;
    position: absolute;
    background-color: $dark-gray;
  }
  .MuiSlider-track {
    height: 1.5px;
    width: 47.5%;
    display: block;
    position: absolute;
    border-radius: 1px;
  }
  .MuiSlider-root {
    color: $buttn-blue;
    width: 445px;
    cursor: pointer;
    height: 1.5px;
    display: inline-block;
    padding: 13px 0;
    position: absolute;
    box-sizing: content-box;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    margin-top: -35px;
  }
  .MuiSlider-thumb {
    width: 6px;
    height: 6px;
    outline: 0;
    position: absolute;
    box-sizing: border-box;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    margin-top: -2.5px;
    margin-left: -3px;
  }
  .reactEasyCrop_CropArea {
    position: absolute;
    left: 50%;
    top: 48.8%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0 0 0 9999em;
    color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }

  .cropper {
    height: 90%;
    position: relative;
    margin-top: 0px;
  }
  .slider {
    height: 10%;
    display: flex;
    align-items: center;
    margin: auto;
    width: 50%;
  }
  .add-photo-div:hover {
    border: $primary-blue 0.5px;
    box-shadow: 0 0 7px $primary-blue;
    outline: none;
  }

  .add-photo-div {
    width: 75px;
    height: 75px;
    margin: auto;
    border-radius: 50%;
    cursor: pointer;
    background-color: $box-bakground;
  }
  .popup-image-after {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .add-photo-after-div {
    width: 36px;
    height: 36px;
    margin-top: -35%;
    margin-left: 75%;
  }

  .add-photo-after {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $white;
    padding: 5px;
  }
  .add-photo {
    margin-left: 26.5%;
    // border-radius: 50%;
    width: 45%;
    background-color: $box-bakground;
    margin-top: 26.5%;
  }
  .minus-container {
    margin-top: -35px;
    margin-right: 10px;
    .img {
      width: 8px;
    }
  }
  .plus-container {
    margin-top: -35px;
    margin-left: 87.5%;
    .img {
      width: 8px;
    }
  }
}

.list-container {
  border-radius: 8px;
  background-color: #ffff;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 3% 5%;
  height: 40vh;
  position: relative;
  .list-itm {
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #7070702d;
    padding-top: 15px;

    .list-details {
      margin-left: 10px;
      font-family: 'Roboto';
      width: 70%;
      .name {
        font-size: 14px;
        color: #1e1e1e;
        font-weight: 500;
        text-transform: capitalize;
      }
      .designation {
        font-size: 12px;
        color: #666666;
      }
    }

    .chack-mark{
      width: 20px;
      height: 20px;
    }


    /* The container */
    .container {
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      align-self: center;
      margin-top: -20px;
    }
  }
}
.sideBarSingleMessage {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  background: none;
  max-width: 110px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sideBarSingleMessageProPicWrapper {
  padding: 8px;
  width: 75px;
  text-align: center;
}

.sideBarSingleMessageLeft {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.sideBarSingleMessageProPic {
  min-width: 28px;
  height: 28px !important;
  border-radius: 50%;
  object-fit: cover;
  margin-left: -15px;
}

.sideBarSingleMessageAndSender {
  padding: 8px;
  width: 220px;
  margin-left: -27px;
}
.sideBarSingleMessageSender {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
}
.sideBarSingleMessage {
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  background: none;
  max-width: 110px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loader {
  border: 1px solid $loader-white; /* Light grey */
  border-top: 1px solid $primary-blue; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  ._NewGroupMessage {
    .modal-content-new {
      width: 96%;
    }

    .modal-content-top {
      width: 96%;
    }

    .modal-content-2 {
      padding-left: 0px;
      .right {
        .close {
          margin-top: 0;
        }
      }
    }

    .MuiSlider-rail {
      width: 100%;
      height: 1.5px;
      display: block;
      opacity: 0.38;
      position: absolute;
      background-color: $dark-gray;
    }
    .MuiSlider-track {
      height: 1.5px;
      width: 47.5%;
      display: block;
      position: absolute;
      border-radius: 1px;
    }
    .MuiSlider-root {
      color: $buttn-blue;
      width: 135%;
      cursor: pointer;
      height: 1.5px;
      display: inline-block;
      padding: 13px 0;
      position: absolute;
      box-sizing: content-box;
      touch-action: none;
      -webkit-tap-highlight-color: transparent;
      margin-top: -35px;
    }
    .MuiSlider-thumb {
      width: 6px;
      height: 6px;
      outline: 0;
      position: absolute;
      box-sizing: border-box;
      transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      align-items: center;
      border-radius: 50%;
      justify-content: center;
      margin-top: -2.5px;
      margin-left: -3px;
    }

    .list-container {
      max-width: 330px;
      margin-left: 12px;
    }

    .search-bar-wrapper-1{
      width: 320px;
    }
  }
}
