@import '../../../GlobalVariables/colors.scss';
$defultTextFont: "SF Pro Display", sans-serif;
$bixFont:"Roboto", sans-serif;
$folder-title:"proxima-nova", sans-serif;


.save-wrapper{
    width: 100%;
    // margin: auto;
    align-self: center;
    background-color: rgba(0, 0, 0, 0.45);

    
}

.save-wrapper .is-active {
    background-color: azure;
}

.modal-content {
    // box-shadow: 2px 6px 8px 0px rgba(0, 0, 0, 0.75) !important;
    margin-top: 5%;
    width: 100%;
    height:100vh;
    background-color: $white;
    border-radius: 20px;
    padding-bottom: 30px;


    .bix-title{
        font-family: $bixFont;
        color: $primary-blue;
        font-size: 15px;
        margin-left: 30px;
        margin-top: 10px;
        font-weight: 500;
        float: left;
        
    }
    
    .cloud-icon{
        height: 25px;
        margin-top: 10px;
        color: white;
    }
    
    .icon-placeholder{
        background-color: $primary-blue !important;
        top: 0;
        height: 45px;
        width: 50px;
        margin-left: 40px;
        position: relative;
        text-align: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        
       
    }
    
    .folders-div{
        width:170px;
        margin-left: 60px;
    }
    
    
    
    // .folder-title{
    //     font-family: $defultTextFont !important;
    //     font-weight: bold !important;
    //     font-size: 20px !important;
    // }
    
    .folder-display-div{
        width: 95%;
        height: 65%;
        background-color: $light-gray;
        border: black 1px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 20px;
        

        .float-child {
            float: left;
            padding: 10px;

            .single-folder-div{
                width: 120px;
                height: 150px;
                border: 2px $div-border solid;
                border-radius: 10px;
                cursor: pointer;
                padding: 25px 20px;
                // margin: auto;

                .folder-icon{
                    text-align: center;             
                    // position: relative;
                    width:80px;
                }

        
            }

            
        }
    }


   

    

    .btn-div{
        width:250px;
        justify-content: space-between;
        // border: black solid 2px;
        float: right;
        margin-right: 30px;
    }

    .cncl-btn{
        border: $primary-blue solid 2px;
        border-radius: 6px;
        height:44px;
        width: 100px;
        text-align: center;
        line-height: 40px;
        color: $primary-blue;
        font:$defultTextFont;
        font-size: 20px;
        cursor: pointer;
    }
    
    .save-btn{
        border: $primary-blue solid 2px;
        border-radius: 6px;
        height:46px;
        width: 110px;
        text-align: center;
        line-height: 40px;
        background-color: $primary-blue;
        color: $white;
        font:$defultTextFont;
        font-size: 20px;
        cursor: pointer;
        
    }

    
.new-folder-btn{
    cursor: pointer;
    display: flex;
    background-color: $folder-title-clr;
    padding: 10px 15px;
    border-radius: 10px;
    
    align-self: center;
    .new-text {
      font-family: $folder-title;
      font-size: 15px;
      color: $white;
      line-height: 1.08;
      align-self: center;
      padding-left: 10px;
    
    }
    .add{
      align-self: center;
      height: 13px;
      width: 13px;
      margin-right: 2px;
    }
    
  }
    
}

.folder-title{
    font-family: $folder-title;
    color: $folder-title-clr;
    font-weight: 550;
    margin-top: 20px;
    font-size: 13px;
    white-space: nowrap;

}









