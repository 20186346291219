.dropzone {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    width: 100%;
    label {
      box-sizing: border-box;
      height: 900px;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 2;
      min-width: 100%;
    }
  
  
  }
  .dropzone span {
    position: absolute;
    top: 35%;
    left: 30%;
    font-size: 72px;
    font-family: "Sf Pro" display;
  }