@import "../../../GlobalVariables/colors.scss";

$defultTextFont: "SF Pro Display", sans-serif;
.LoadingScreen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato";
  margin-top: -3%;

  .logoContainer {
    display: block;

  }
  .bix-logo {
    color: $primary-blue-dark;
    font-family: "Lato";
    font-size: 55px;
    text-align: center;
    font-weight: 400;
  }

  .bix-subtext {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  .progressbarContainer {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: 40px;
  }

  .barWrapper {
    border-radius: 50px;
  }

  .barContainer {
    background-color: #dcdcdc;
    height: 3px !important;
    width: 100%;
    border-radius: 50px;
  }

  .barContainer .barCompleted {
    background-color: $primary-blue-dark;
    height: 3px;
    border-radius: inherit;
    animation: progress-animation 2.5s forwards;
    border-radius: 50px;
  }

  @keyframes progress-animation {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 600px) {
  .LoadingScreen {
    .logoContainer {
      .bix-logo {
        font-size: 40px;
      }
      .bix-subtext{
          margin-top: -10px;
      }
    }
  }
}
