@import "../../../GlobalVariables/colors.scss";
._SendComponent {
  min-height: 100vh;
  border-right: 1px solid $gray;
  background: $white;
  width: 24.6%;
  min-width: 24.6%;
  max-width: 24.6%;
  margin-top: 57px;
  .modal-content-center {
    background: $white;
    margin: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    color: $primary-blue;
    align-content: center;
    font-size: 20px;
    border-bottom: 1px solid $primary-blue;
    font-family: "Roboto";
    font-weight: 500;
    display: flex;
    padding-bottom: 10px;
    .plus-container-left {
      background-color: $primary-blue;
      width: 40px;
      margin-left: 30px;
      display: flex;
      justify-content: center;
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .info {
        height: 16px;
        width: 16px;
        filter: brightness(0) invert(1);
      }
    }
    .header {
      margin-left: 10px;
      margin-top: 10px;
      font-size: 14px;
      font-family: "Roboto";
      font-weight: 500;
      width: 70%;
      .margin-top {
        margin-top: 10px;
      }
    }
    .close {
      height: 14px;
      width: 14px;
      align-self: center;
      cursor: pointer;
      margin-top: 10px;
    }
  }
  .inner-container {
    display: block;
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
    .topper {
      padding: 5%;
    }
    .top-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      animation: slide-right 0.7s;
      position: relative;
    }
    .header-container {
      display: flex;
      cursor: pointer;
      .header {
        font-family: "Roboto";
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: normal;
        text-align: left;
        color: $head-name;
        align-self: center;
        margin-left: 8px;
      }
    }
    .header-list {
      color: "#000";
      font-family: "Roboto";
      font-size: 1.1rem;
      font-weight: 500;
      display: flex;
      img {
        height: 0.8rem;
        width: 0.8rem;
        transform: rotate(-90deg);
        align-self: center;
        margin-top: -0.3rem;
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .search-bar-wrapper-new {
      display: flex;
      background-color: $light-gray;
      padding: 5px 30px 5px 15px;
      border-radius: 6px;
      align-self: center;
      width: 100%;
      flex: 1;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      .img-icon-style {
        height: 16px;
        width: 16px;
        align-self: center;
      }
      .input-style-new {
        outline: none;
        border: none;
        background-color: $light-gray;
        font-family: "Roboto";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: left;
        margin-left: 15px;
        flex: 1;
      }
      ::placeholder {
        /* Most modern browsers support this now. */
        color: $dark-gray;
        font-size: 14px;
        font-weight: normal;
      }
    }
    @keyframes slide-right {
      from {
        left: -200px;
      }
      to {
        left: 0px;
      }
    }
    .list-container {
      border-radius: 8px;
      background-color: #ffff;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 3% 5%;
      height: 75vh;
      position: relative;
      animation: slide-right 0.7s;
      .list-itm {
        display: flex;
        padding-bottom: 15px;
        border-bottom: 1px solid #7070702d;
        padding-top: 15px;

        .list-details {
          margin-left: 10px;
          font-family: "Roboto";
          width: 70%;
          .name {
            font-size: 14px;
            color: #1e1e1e;
            font-weight: 500;
            text-transform: capitalize;
          }
          .designation {
            font-size: 12px;
            color: #666666;
          }
        }
        /* The container */
        .container {
          display: block;
          position: relative;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          align-self: center;
          margin-top: -20px;
        }

        /* Hide the browser's default checkbox */
        .container input {
          position: absolute;
          opacity: 1;
          cursor: pointer;
          height: 20px;
          width: 20px;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #fff;
          border: 0.8px solid #707070;
          border-radius: 4px;
        }
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
          left: 7px;
          top: 2px;
          width: 6px;
          height: 12px;
          border: solid #00c900;
          border-width: 0 1.5px 1.5px 0;
          -webkit-transform: rotate(40deg);
          -ms-transform: rotate(40deg);
          transform: rotate(40deg);
        }
      }
    }

    .button-container {
      display: flex;
      align-self: center;
      .yes-button {
        background-color: $primary-blue;
        border: none;
        cursor: pointer;
        font-size: 12px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 4px;
        padding-bottom: 4px;
        border-radius: 4px;
        width: 70px;
        height: max-content;
        color: $white;
        display: flex;
        .line-h {
          width: 1px;
          background-color: white;
          height: 18px;
          margin-left: 5px;
          margin-right: 5px;
        }
        img {
          transform: rotate(45deg);
          height: 16px;
          width: 16px;
        }
        .loader {
          border: 1px solid #42b0d1; /* Light grey */
          border-top: 1px solid $white; /* Blue */
          border-radius: 50%;
          width: 16px;
          height: 16px;
          animation: spin 0.5s linear infinite;
          align-self: center;
          justify-content: center;
          display: flex;
          align-items: center;
          margin: auto;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  ._SendComponent {
    width: 100%;
    min-width: 100%;
    margin-top: 0;
  }
}
