//////////////////////////////////  Variables //////////////////////////////////////
@import "../../../GlobalVariables/colors.scss";
$defultTextFont: Lato;

@mixin formInput {
  width: 100%;
  margin: 6px 0 8px;
  // padding: 18px 10px;
  padding: 3% 2%;
  box-sizing: border-box;
  font-size: 0.7rem;
  height: 36px;
  outline: none;
  border: none;
  border-bottom: solid 1px rgb(199, 199, 199);
  margin-left: 3px;
  margin-right: 3px;
  color: #414141;
}

.__SettingsWrapper {
  // .modal-background {
  //   // background: none !important;
  // }
  .modal-background {
    background-color: rgba(0, 0, 0, 0.6);
  }
  .modal-card-body {
    padding: 0;
    display: flex;
    overflow: hidden;
  }

  .modal-card {
    width: 60%;
    border-radius: 16px;
    height: max-content;
  }

  .input-wrapper {
    display: grid;
    font-size: 13px;
    font-weight: 500;
    padding: 1px 5px;
    width: 100%;
    color: #1e1e1e;
    font-family: "Lato";

    .text-input {
      @include formInput;
    }

    .text-input:focus {
      border-bottom: 1px solid #0d2a4c;
    }

    .bio-input {
      @include formInput;
      width: 100%;
      resize: none;
      height: 90px;
      outline-color: #0d2a4c;
      margin-top: -10px;
    }
    .bio-input:focus {
      border-bottom: 1px solid #0d2a4c;
    }

    .input-not-valid {
      border-bottom: 1px solid red;
    }
    // .bio-input:focus {
    //   border: 1px solid #0d2a4c !important;
    // }
  }

  .left-wrapper {
    height: 100%;
    width: 35%;
    background-color: rgb(248, 246, 246);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    .left-wrapper-header {
      padding-top: 18px;
      padding-left: 45px;
      padding-right: 20px;
      color: #1e1e1e;
      font-size: 1rem;
      display: none;
      justify-content: space-between;
      .logged-username {
        font-weight: 600;
      }

      .icons-close {
        height: 15px;
        width: 15px;
        cursor: pointer;
        margin-top: -2px;
      }
    }
    .generalChatToggleWrapper {
      display: flex;
      font-size: 13px;
      text-align: center;
      border-bottom: 1px solid #e7e7e7;
      height: 55px;
      padding-top: 20px;
      justify-content: space-evenly;
    }

    .generalChatToggle {
      color: gray;
      text-align: center;
      margin-bottom: -1px;
    }

    .selectedGeneralChatToggle {
      color: #0d2a4c;
      border-bottom: 2px solid #0d2a4c;
    }

    .setting-option {
      padding: 12px 5px;
      top: 30%;
      font-size: 13px;
      cursor: pointer;
      margin-top: 10px;
    }

    .setting-menus {
      padding: 10px;
      color: $black;
      width: 80%;
      margin: auto;
      // background-color: #9f9f9f2c;
      border-radius: 5px;
    }
    .setting-menus-selected {
      background-color: #9f9f9f2c;
    }

    .icons-setting {
      height: 15px;
      width: 15px;
      align-self: center;
      margin-right: 8px;
      cursor: pointer;
    }
    .next-setting {
      height: 14px;
      width: 14px;
      align-self: center;
      margin-right: 8px;
      cursor: pointer;
      float: right;
      margin-top: 3px;
      display: none;
    }
  }

  .right-wrapper {
    // padding: 10px 12px;
    // overflow-y: auto;
    width: 65%;

    .right-wrapper-header {
      border-bottom: 1px solid #e7e7e7;
      height: 55px;
      padding-top: 18px;
      padding-left: 45px;
      padding-right: 20px;
      color: #1e1e1e;
      font-size: 1rem;
      display: flex;
      justify-content: space-between;
      .back-settings {
        height: 14px;
        width: 14px;
        transform: rotate(180deg);
        margin-top: 5px;
        margin-left: -25px;
        margin-right: 20px;
        display: none;
      }
      .logged-username {
        font-weight: 500;
        flex: 1;
      }

      .icons-close {
        height: 15px;
        width: 15px;
        cursor: pointer;
        margin-top: -2px;
      }
    }
    .pol {
      font-family: "Lato";
      font-size: 0.8rem;
      height: 57vh;
      color: #707070;
      overflow-y: auto;
      margin-top: 4vh;
      margin-bottom: 4vh;
      .div-pol {
        overflow-y: auto;
        .head-pol {
          font-size: 0.85rem;
          font-weight: 500;
          margin-top: 20px;
        }
      }
    }
    .agr {
      font-family: "Lato";
      font-size: 0.8rem;
      height: 57vh;
      color: #707070;
      overflow-y: auto;
      margin-top: 4vh;
      margin-bottom: 4vh;
    }
    .account {
      font-family: "Lato";
      font-size: 0.85rem;
      height: 57vh;
      overflow-y: auto;
      margin-top: 4vh;
      margin-bottom: 4vh;
      .delete-head {
        font-weight: 600;
        color: #ff0000;
        display: flex;
        align-self: center;
        img {
          height: 18px;
          width: 18px;
          margin-right: 10px;
        }
      }
      .delete-cont {
        padding-left: 12px;
        margin-top: 17px;
        .cont-del {
          display: flex;
          align-self: center;
          .dot {
            height: 0.35rem;
            width: 0.35rem;
            background-color: $black;
            border-radius: 100%;
            margin-right: 5px;
            align-self: center;
          }
        }
      }

      .submit-button {
        width: max-content;
        border-radius: 6px;
        background-color: #f00;
        color: $white;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
        font-family: "Lato";
        letter-spacing: normal;
        line-height: 0.94;
        margin-top: 5vh;
        margin-left: 10px;
        font-size: 0.85rem;
        border-width: 0px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        padding: 4% 6%;
      }
    }

    .signature {
      font-family: "Lato";
      font-size: 0.85rem;
      height: 57vh;
      overflow-y: auto;
      margin-top: 4vh;
      margin-bottom: 4vh;
      .signature-head {
        font-weight: 600;
        color: #000000;
        display: flex;
        align-self: center;
        img {
          height: 18px;
          width: 18px;
          margin-right: 10px;
        }
      }
      .signature-cont {
        padding-left: 12px;
        margin-top: 17px;
        .cont-del {
          display: flex;
          align-self: center;
          .dot {
            height: 0.35rem;
            width: 0.35rem;
            background-color: $black;
            border-radius: 100%;
            margin-right: 5px;
            align-self: center;
          }
        }
      }

      .submit-button {
        width: max-content;
        border-radius: 6px;
        /* background-color: #f00; */
        color: black;
        text-align: center;
        cursor: pointer;
        font-weight: 600;
        font-family: "Lato";
        letter-spacing: normal;
        line-height: 0.94;
        margin-top: 5vh;
        /* margin-left: 10px; */
        font-size: 0.85rem;
        border-width: 1px;
        padding: 4% 6%;
      }
    }

    .display {
      font-family: "Lato";
      font-size: 0.85rem;
      height: 57vh;
      overflow-y: auto;
      margin-top: 4vh;
      margin-bottom: 4vh;

      .font-head {
        font-weight: 600;
        color: #1e1e1e;
        display: flex;
        align-self: center;
      }
      .slidercontainer {
        width: 100%;
        margin-top: 20px;
        display: block;
        .select {
          width: 100%;
          border: transparent;
          height: 27px;
          -webkit-appearance: none;
          padding-left: 10px;
          border-radius: none;
          font-size: 12px;
          font-family: "Lato";
        }
        border-bottom: 1px solid #9d9d9d;
        .create-signature {
          margin-top: 20px;
        }

        .font {
          font-family: "Lato";
          font-size: 12px;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          span {
            margin-left: 20px;
          }
        }
      }

      .font-slider {
        -webkit-appearance: none;
        width: 80%;
        height: 3px;
        border-radius: 5px;
        background: #dcdcdc;
        outline: none;
        opacity: 0.7;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
      }

      .font-slider:hover {
        opacity: 1;
      }

      .font-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #0d2a4c;
        cursor: pointer;
      }

      .font-slider::-moz-range-thumb {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #0d2a4c;
        cursor: pointer;
      }
    }
    .manageUsers {
      font-family: "Lato";
      font-size: 0.85rem;
      height: 57vh;
      overflow-y: auto;
      margin-top: 4vh;
      margin-bottom: 4vh;

      .users-head {
        font-weight: 600;
        color: #1e1e1e;
        display: flex;
        align-self: center;
      }
      .users-sub {
        color: #9d9d9d;
        margin-top: 10px;
        font-size: 13px;
      }
      .link{
        width: 100%;
        max-width: 100%;
        font-size: 14px;
        overflow-wrap: break-word;
        a{
          color: #379df4;
        text-decoration: underline;
        }
      }
    }
    .input-wrapper-div {
      padding: 0px 12px;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      overflow-y: auto;
      .wid {
        width: 91%;
      }
      .pro-pic-and-bio {
        display: flex;
        height: 130px;

        margin-top: 5px;
        margin-left: 10px;

        .pro-pic-wrapper {
          align-self: center;
          min-width: 90px;
          display: flex;
          position: relative;
          .profile-img {
            height: 67px;
            width: 67px;
            position: relative;
            border-radius: 50%;
            object-fit: cover;
            margin-left: 7%;
            margin-top: 2%;
          }

          .add-photo-icon-setting {
            background-repeat: no-repeat;
            object-fit: cover;
            margin: auto;
            box-shadow: 0 0 4px $box-shadow-gray;
            border-radius: 50%;
            height: 18px;
            width: 18px;
            padding: 5px;
            position: absolute;
            margin-top: 57px;
            margin-left: 50px;
            background: white;
            cursor: pointer;
            // margin-left: 60px;
          }
        }
      }
      .name-des-div {
        height: max-content;
        align-self: center;
        color: #1e1e1e;
        font-family: "Lato";
        font-size: 1rem;
        font-weight: 500;
        .desi {
          font-size: 0.75rem;
          font-weight: normal;
        }
      }
      .hr-div {
        background: #e7e7e7;
        height: 1px;
        width: 65%;
        margin-bottom: 15px;
        margin-left: 10%;
      }
      .double-input-wrapper {
        display: flex;
      }
      .margin-left {
        margin-left: 20px;
      }
      .margin-left-15 {
        margin-left: 15px;
      }
      .margin-right {
        margin-right: 20px;
      }

      .name-limit-error {
        font-size: 8px;
        color: red;
        font-style: italic;
        margin-top: -10px;
        margin-left: 5px;
      }
    }

    .error-message-wrapper {
      color: red;
      font-size: 11px;
      font-style: italic;
      height: 36px;
    }

    .button-wrapper {
      padding: 5px;
      margin-top: -25px;
      .add-photo-button-sttings {
        background-color: $buttn-blue;
        border: none;
        border-radius: 5px;
        height: 4.5vh;
        color: $white;
        padding: 0 20px;
        float: right;
        margin-bottom: 15px;
        font-size: 12px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        font-weight: 500;
        margin-bottom: 30px;
      }

      .add-photo-button-sttings-disable {
        border: solid rgb(226, 226, 226) 1px;
        border-radius: 5px;
        height: 4.5vh;
        color: black;
        padding: 0 20px;
        background: white;
        float: right;
        margin-bottom: 15px;
        font-size: 12px;
        cursor: not-allowed;
        margin-bottom: 30px;
      }
    }
  }
}

.single-block-option {
  color: gray;
  text-align: center;
  margin-bottom: -1px;
}

@media only screen and (max-width: 1200px) {
  .__SettingsWrapper {
    .modal-card {
      width: 80%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .__SettingsWrapper {
    .modal-card {
      width: 90%;
      background-color: #ffffff;
      height: 75vh;
    }

    .left-wrapper {
      width: 100%;
      padding: 0;
      background-color: $white;
      .next-setting {
        display: block;
      }
      .left-wrapper-header {
        display: flex;
      }
    }
    .right-wrapper {
      width: 100%;
      padding: 0;
      display: none;
      .right-wrapper-header {
        .back-settings {
          display: block;
        }
      }

      .input-wrapper-div {
        padding: 0;
        padding-left: 1rem;
        .wid {
          width: 100%;
        }
      }
    }

    .modal-card-body {
      width: 96%;
    }
  }
}
