@import '../../../GlobalVariables/colors.scss';
._centerModal {
  .center-modal-black-background {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 50; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    // background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
    align-content: center;
  }
  .center-modal-content-inner {
    background-color: $white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px;
    margin-top: 13%;
    width: 500px;
    .center-modal-content-center {
      background: $white;
      margin: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      color: $primary-blue;
      align-content: center;
      font-size: 20px;
      border-bottom: 1px solid $primary-blue;
      font-family: "Roboto";
      font-weight: 500;
      display: flex;
      padding-bottom: 10px;
      .plus-container-left {
        background-color: $primary-blue;
        width: 40px;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .info {
          height: 16px;
          width: 16px;
          filter: brightness(0) invert(1);
        }
      }
      .header {
        margin-left: 10px;
        margin-top: 10px;
        font-size: 14px;
        font-family: "Roboto";
        font-weight: 500;
        width: 75%;
      }
      .close {
        height: 14px;
        width: 14px;
        align-self: center;
        cursor: pointer;
        margin-top: 10px;
      }
    }
    .inner-container {
      display: block;
      height: auto;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      padding-bottom: 30px;
      .warning {
        font-family: "Roboto";
        font-size: 13px;
        color: $form-fail;
        width: 80%;
        margin: auto;
        font-style: italic;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .description {
        font-size: 17px;
        color: $text-black;
        font-family: "roboto";
        font-weight: 500;
        margin-top: 40px;
      }
      .button-container {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
        margin-right: 40px;
        .no-button {
          background-color: $primary-blue;
          border: none;
          cursor: pointer;
          color: $white;
          font-size: 12px;
          padding-left: 18px;
          padding-right: 18px;
          padding-top: 6px;
          padding-bottom: 6px;
          border-radius: 4px;
          margin-right: 20px;
        }
        .yes-button {
          background-color: $white;
          border: 1px solid $border-light-gray;
          cursor: pointer;
          color: $black;
          font-size: 12px;
          padding-left: 18px;
          padding-right: 18px;
          padding-top: 6px;
          padding-bottom: 6px;
          border-radius: 4px;
          .loader {
            border: 1px solid $text-black; /* Light grey */
            border-top: 1px solid $white; /* Blue */
            border-radius: 50%;
            width: 15px;
            height: 15px;
            animation: spin 2s linear infinite;
            align-self: center;
            justify-content: center;
            display: flex;
            align-items: center;
            margin: auto;
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  ._centerModal {
    .center-modal-content-inner {
      width: 96%;
    }
  }
}
