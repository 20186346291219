@import '../../GlobalVariables/colors.scss';


//////////////////////////////////  Variables //////////////////////////////////////

$desktopBackground: $desktop-background;
$defultTextFont: Roboto;

////////////////////////////////////////////// Mixines ////////////////////////

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

@mixin mainContainer {
  width: 100%;
  height: 100%;
  background: $desktopBackground;
  padding: 2vh 0;
  overflow-x: auto;
}

@mixin wrapper {
  background: $white;
  width: 37.5%;
  margin: auto;
  border-radius: 60px;
  // box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  padding: 3.3% 2.6% 2%;
}

@mixin wrapper-1200 {
  background: $white;
  width: 50%;
  margin: auto;
  border-radius: 12px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  padding: 3.3% 2.6% 2%;
}

@mixin wrapper-992 {
  background: $white;
  width: 55%;
  margin: auto;
  border-radius: 12px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  padding: 3.3% 2.6% 2%;
}

@mixin wrapper-768 {
  background: $white;
  width: 60%;
  margin: auto;
  border-radius: 12px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  padding: 3.3% 2.6% 2%;
}

@mixin mobileViewWrapper {
  background: $white;
  // min-height: 883px;
  width: 100%;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 30px 10px;
}

@mixin appLogo {
  margin: auto;
  height: max-content;
  width: max-content;
  background: $black;
  border-radius: 8px;
  color: $white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;
  font-size: 19px;
  padding-left: 8px;
  padding-right: 8px;
}

@mixin header {
  margin: 8px auto;
  font-family: $defultTextFont;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: $login-header;
}

@mixin formWrapper {
  max-width: 82%;
  margin: auto;
}

@mixin inputWrapper {
  margin: auto;
  // margin-top: 30px;
  // margin-top: 3.8vh;
  // margin-bottom: 7.5vh;
}

@mixin formLabel {
  width: 160px;
  font-family: $defultTextFont;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  font-weight: 500;
  letter-spacing: normal;
  text-align: left;
  color: $dark-gray;
}

@mixin formInput {
  width: 100%;
  margin: 6px 0 8px;
  border-radius: 8px;
  border: solid 1px $primary-blue;
  background-color: $form-gray;
  // padding: 18px 10px;
  padding: 3% 2%;
  box-sizing: border-box;
  font-size: 14px;
}

@mixin formInput2 {
  width: 100%;
  margin: 6px 0 8px;
  border-radius: 8px;
  border: solid 1px $primary-blue;
  background-color: $form-gray;
  box-sizing: border-box;
  font-size: 13px;
  padding-left: 7px;
}

@mixin formSuccess {
  font-size: 11px;
  font-weight: normal;
  color: $form-success;
  font-family: "Roboto";
}

@mixin formFail {
  font-size: 11px;
  font-weight: normal;
  color: $form-fail;
  font-family: "Roboto";
  align-items: center;
}

@mixin formCaption {
  text-align: left;
  color: $dark-gray;
  font-size: 12px;
  margin-top: 26px;
}

@mixin checkboxLabel {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $text-black;
  align-items: center;
  align-self: center;
  align-content: center;
}

@mixin checkboxWrapper {
  margin-top: 20px;
  display: flex;
}
@mixin imagepreview {
  position: relative;
  width: 230px;
  height: 230px;
  background-color: blue;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  z-index: 2;
  top: 0;
  left: 0;
}
@mixin center {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 60%;
  margin-top: 25px;
}
@mixin customfileupload {
  color: $black;
  cursor: pointer;
  text-align: center;
  margin-left: 200px;
  align-items: center;
  justify-content: center;
}

@mixin errormsg {
  color: $form-fail;
  font-size: 24px;
}

@mixin submitButton {
  // margin: 15px auto;
  padding: 6% 2%;
  width: 100%;
  // box-sizing: border-box;
  border-radius: 8px;
  background-color: $buttn-blue;
  color: $white;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-family: "Roboto";
  letter-spacing: normal;
  line-height: 0.94;
  margin-top: 6%;
  font-size: 16px;
  border-width: 0px;
  height: 56px;
}


@mixin skipButton {
  margin: 15px auto;
  width: 100%;
  padding: 15px 5px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: $white;
  color: $buttn-blue;
  border: $buttn-blue 2px solid;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-family: "Roboto";
  font-size: 16px;
  height: 56px;
}

@mixin footerText {
  text-align: center;
  font-size: 15px;
  margin-top: 4vh;
  color: $small-text-black;
  font-family: Roboto;
  font-weight: 500;
}

@mixin footerTextLink {
  text-align: left;
  width: 500px;
  font-size: 15px;
  color: $buttn-blue;
  cursor: pointer;
  font-weight: 500;
}
@mixin popup-box {
  position: absolute;
  background: $modal-background;
  width: 50%;
  height: 100vh;
  top: 60px;
  left: 80px;
  z-index: 99;
}

@mixin box {
  position: absolute;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: $white;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid $border-gray;
  overflow: auto;
  z-index: 99;
  top: 60px;
  left: 80px;
}

@mixin close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: $light-gray-background;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid $border-gray;
  font-size: 20px;
}

@mixin container-cropper {
  height: 90%;
  padding: 5px;
}

@mixin cropper {
  height: 90%;
  position: relative;
  margin-top: 0px;
}
@mixin slider {
  height: 10%;
  display: flex;
  align-items: center;
  margin: auto;
  width: 50%;
}
@mixin container-buttons {
  border: 1px solid $white;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin multipleInputWrapper {
  display: flex;
  justify-content: space-around;
}

@mixin multipleInputWrapperDouble {
  display: flex;
  justify-content: space-around;
}

@mixin responsiveMultipleInputWrapperDouble {
}

// Mixin for 768  - 1401

/* Signup Styles */
.__Signup-container {
  @include mainContainer;
}

// Setup Profile Styles
.__CreateUserName {
  .wrapper {
    @include wrapper;
  }

  .app-logo {
    @include appLogo;
  }

  .header {
    @include header;
  }

  .form-wrapper {
    @include formWrapper;
  }

  .input-wrapper {
    @include inputWrapper;
  }

  .inputwrapper {
    margin-bottom: 74px;
  }

  .form-label {
    @include formLabel;
  }

  .form-input {
    @include formInput;
  }
  .form-input2 {
    @include formInput2;
  }

  .form-success {
    @include formSuccess;
  }

  .form-fail {
    @include formFail;
    align-items: center;
    display: flex;
  }

  .form-caption {
    @include formCaption;
  }

  .checkbox-label {
    @include checkboxLabel;
    display: flex;
  }

  .checkbox-wrapper {
    @include checkboxWrapper;
  }

  .submit-button {
    @include submitButton;
    // margin-top: 54px;
  }

  .footer-text {
    @include footerText;
  }

  .footer-text-link {
    @include footerTextLink;
  }

  .danger-image {
    height: 14px;
    width: 14px;
    margin-right: 6px;
  }
  .danger-image2 {
    height: 11px;
    width: 11px;
    margin-right: 6px;
    align-self: center;
  }
  .available {
    font-size: 13px;
    .right-text {
      color: $dark-gray;
      font-family: "Roboto";
      font-weight: 500;
    }
  }
  .username-margin-top-reg {
    margin-top: 3.8vh;
    margin-bottom: 4.5vh;
  }
  .create-username-input-reg {
    background-color: $form-gray;
    border-color: transparent;
    flex: 2;
    // width: 62%;
  }

  .inside-username {
    color: $small-text-black;
    flex: 1;
    margin-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .inputwrapper {
    margin-top: 0px;
  }

  .check-box-reg {
    height: 18px;
    width: 18px;
  }

  .show-password-reg-p {
    margin-left: 16px;
    font-size: 13px;
    color: $text-black;
  }
  .next-btn {
    margin-top: 4vh;
  }
  .innerText-input {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .password-input-reg {
    background-color: $form-gray;
    border-color: transparent;
    width: 100%;
    border-bottom-color: transparent;
  }
}

.__CompleteYourProfile {
  .wrapper {
    @include wrapper;
  }

  .app-logo {
    @include appLogo;
  }

  .header {
    @include header;
  }

  .form-wrapper {
    @include formWrapper;
  }

  .input-wrapper {
    @include inputWrapper;
    // display: flex;
  }
  .multiple-input-wrapper {
    @include multipleInputWrapper;
    .input-wrapper {
      flex: 1;
      margin-top: 0px;
    }

    .month-margin {
      margin-right: 8px;
    }
    .day-margin {
      margin-left: 8px;
      margin-right: 8px;
    }
    .year-margin {
      margin-left: 8px;
    }
    .wrap2 {
      margin-top: 2px;
    }
  }

  .wrap {
    margin-top: 3.5vh;
    margin-bottom: 3vh;
  }

  .multiple-input-wrapper-double {
    @include multipleInputWrapperDouble;
    margin-bottom: 6vh;
  }

  .form-label {
    @include formLabel;
  }
 

  .form-input {
    @include formInput;
  }
  .form-input2 {
    @include formInput2;
  }

  .form-success {
    @include formSuccess;
  }

  .form-fail {
    @include formFail;
    align-items: center;
    display: flex;
  }

  .form-caption {
    @include formCaption;
  }

  .checkbox-label {
    @include checkboxLabel;
  }

  .checkbox-wrapper {
    @include checkboxWrapper;
  }

  .submit-button {
    @include submitButton;
  }

  .footer-text {
    @include footerText;
  }

  .footer-text-link {
    @include footerTextLink;
  }

  .danger-complete-your-profile {
    height: 12px;
    width: 12px;
    margin-right: 6px;
  }

  .input-height {
    height: 47px;
  }
  .input-style {
    background-color: $form-gray;
    border-color: transparent;
    width: 100%;
  }
  .multi-container-div {
    display: flex;
    flex: 1;
    margin-top: 3.8vh;
 
  }
  .firstName-conatiner {
    flex: 1;
    margin-right: 8px;
  }
  .lastName-container {
    flex: 1;
    margin-left: 8px;
  }
  select.minimal-black {
    background: url("../../Assets//Icons/triangle-down-black.svg") 96% / 10px
      no-repeat $form-gray;
  }

  select.minimal-black::after {
    background: url("../../Assets/Icons/triangle-up-black.svg") 96% / 10px
      no-repeat $form-gray;
  }
  .minimal-gray {
    background: url("../../Assets//Icons/triangle-down-gray.svg") 96% / 10px
      no-repeat $form-gray;
  }

  select.minimal-gray::after {
    background: url("../../Assets/Icons/triangle-up-gray.svg") 96% / 10px
      no-repeat $form-gray;
  }
  select.minimal-black-day {
    background: url("../../Assets//Icons/triangle-down-black.svg") 90% / 10px
      no-repeat $form-gray;
  }

  select.minimal-black-day::after {
    background: url("../../Assets/Icons/triangle-up-black.svg") 90% / 10px
      no-repeat $form-gray;
  }
  .minimal-gray-day {
    background: url("../../Assets//Icons/triangle-down-gray.svg") 90% / 10px
      no-repeat$form-gray;
  }

  select.minimal-gray-day::after {
    background: url("../../Assets/Icons/triangle-up-gray.svg") 90% / 10px
      no-repeat $form-gray;
  }
  .next-btn {
    margin-top: 11.8vh;
    margin-bottom: 6vh;
  }
}

// .modal-card-body {
//   width: 80%;
//   height: max-content;
//   margin-left: 10%;
//   border-radius: 10px;
//   padding: 40px;
// }

.ageError {
  justify-content: center;
}
.error-warning {
  height: 120px;
}
.sorry {
  color: $black;
  font-size: 21px;
  font-weight: bold;
  margin-left: 33%;
}

.ageError {
  text-align: center;
  margin-top: 3%;
  color: $black;
}

.ageErrorButton {
  background-color: $buttn-blue;
  border: none;
  border-radius: 5px;
  width: 24%;
  height: 6vh;
  margin-left: 39%;
  margin-top: 3%;
  color: $white;
}

.__SetupDigitalCard {
  .wrapper {
    @include wrapper;
  }

  .app-logo {
    @include appLogo;
  }

  .header {
    @include header;
  }

  .form-wrapper {
    @include formWrapper;
  }
  .loader {
    border: 2px solid $white;
    border-radius: 50%;
    border-top: 2px solid $primary-blue;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 0.6s linear infinite; /* Safari */
    animation: spin 0.6s linear infinite;
    margin: auto;
  }
  .code-scanner-anim {
    width: 60%;
    margin-left: 20%;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  .submit-button {
    @include submitButton;
  }

  .skip-button {
    @include skipButton;
  }
  .disabledDiv {
    pointer-events: none;
    opacity: 0.4;
  }
}

.__SetupMoreDetails {
  .wrapper {
    @include wrapper;
  }

  .app-logo {
    @include appLogo;
  }

  .header {
    @include header;
  }

  .form-wrapper {
    @include formWrapper;
  }

  .input-wrapper {
    @include inputWrapper;
    // display: flex;
  }

  .wrap-more-details {
    margin-top: 3.8vh;
    margin-bottom: 3.5vh;
  }

  .wrap-setup-more {
    margin-top: 3.5vh;
    margin-bottom: 1vh;
  }

  .multiple-input-wrapper {
    @include multipleInputWrapper;
  }

  .form-label {
    @include formLabel;
  }

  .form-input {
    @include formInput;
  }
  .form-input2 {
    @include formInput2;
  }

  .form-success {
    @include formSuccess;
  }

  .form-fail {
    @include formFail;
  }

  .form-caption {
    @include formCaption;
  }
  .imagepreview {
    @include imagepreview();
  }
  .center {
    @include center();
  }
  .customfileupload {
    @include customfileupload();
  }
  .submit-button {
    @include submitButton;
  }

  .skip-button {
    @include skipButton;
  }
  .container-cropper {
    @include container-cropper;
  }
  .cropper {
    @include cropper;
  }
  .slider {
    @include slider;
  }
  .container-buttons {
    @include container-buttons;
  }
  .modal-content {
    box-shadow: 2px 6px 8px 0px rgba(0, 0, 0, 0.75) !important;
    margin-top: 5%;
    width: 500px;
  }
  .modal-card-body {
    height: 75vh;
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
  .loader {
    border: 2px solid $white;
    border-radius: 50%;
    border-top: 2px solid $primary-blue;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 0.6s linear infinite; /* Safari */
    animation: spin 0.6s linear infinite;
    margin: auto;
  }
  .add-photo {
    background-repeat: no-repeat;
    object-fit: cover;
    margin: auto;
    margin-top: 2.5vh;
    margin-left: 18%;
    border-radius: 50%;
    width: 65%;
  }

  .add-photo-div:hover {
    border: $primary-blue 0.5px;
    box-shadow: 0 0 7px $primary-blue;
    outline: none;
  }

  .add-photo-div {
    width: 120px;
    height: 120px;
    margin: auto;
    border-radius: 50%;
  }

  .popup-div1 {
    height: 80px;
    background-color: $primary-blue;
    display: flex;
    flex: 1;
    align-items: center;
    .popup-div1-h1 {
      color: $white;
      font-size: 20px;
      font-family: "roboto";
      font-weight: bold;
      flex: 2;
      margin-left: 20px;
    }

    .popup-div1-p {
      float: right;
      color: $white;
      font-size: 13px;
      cursor: pointer;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
    }
    .close-icon {
      height: 12px;
      margin-right: 10px;
      align-items: center;
      cursor: pointer;
    }
  }

  .popup-div2 {
    width: 89%;
    height: 390px;
    position: absolute;
    background: grey;
  }

  .reactEasyCrop_Container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    touch-action: none;
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 63vh;
  }
  .reactEasyCrop_Contain {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    position: absolute;
    top: -10px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .reactEasyCrop_CropArea {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0 0 0 9999em;
    color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }

  .add-photo-button {
    background-color: $buttn-blue;
    border: none;
    border-radius: 5px;
    width: 19%;
    height: 4.5vh;
    margin-top: -7.5%;
    margin-right: 10%;
    color: $white;
    float: right;
  }

  .popup-image-after {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .add-photo-after-div {
    width: 44.4px;
    height: 44.4px;
    margin-top: -45%;
    margin-left: 75%;
  }

  .add-photo-after {
    width: 44.4px;
    height: 44.4px;
    border-radius: 50%;
    box-shadow: 2px 2px 3px rgb(153, 150, 150);
  }

  .MuiSlider-rail {
    // width: 100%;
    width:445px;
    height: 2px;
    display: block;
    opacity: 0.38;
    position: absolute;
    background-color: currentColor;
  }
  .MuiSlider-root {
    color: $buttn-blue;
    width: 58%;
    cursor: pointer;
    height: 2px;
    display: inline-block;
    padding: 13px 0;
    position: relative;
    box-sizing: content-box;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    margin-top: 22%;
  }
  .reactEasyCrop_CropArea {
    position: absolute;
    left: 50%;
    top: 48.8%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0 0 0 9999em;
    color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
  .input-height {
    height: 50px;
  }
  .disabledDiv {
    pointer-events: none;
    opacity: 0.4;
  }
}

.__VerifyPhoneNumber {
  .wrapper {
    @include wrapper;
  }

  .app-logo {
    @include appLogo;
  }

  .header {
    @include header;
  }

  .form-wrapper {
    @include formWrapper;
  }

  .input-wrapper {
    @include inputWrapper;
    // display: flex;
  }

  .multiple-input-wrapper {
    @include multipleInputWrapper;
  }

  .form-label {
    @include formLabel;
  }

  .form-input {
    @include formInput;
  }
  .form-input2 {
    @include formInput2;
  }
  .form-success {
    @include formSuccess;
  }

  .form-fail {
    @include formFail;
  }

  .form-caption {
    @include formCaption;
  }

  .submit-button {
    @include submitButton;
  }

  .skip-button {
    @include skipButton;
  }

  .footer-text {
    @include footerText;
    color: $dark-gray;
  }
}

.__Login-container {
  @include mainContainer;
}

.__LoginMainPage {
  .wrapper {
    @include wrapper;
  }

  .app-logo {
    @include appLogo;
  }

  .header {
    @include header;
  }

  .form-wrapper {
    @include formWrapper;
  }

  .input-wrapper {
    @include inputWrapper;
    // margin-Bottom: 54px;
  }

  .form-label {
    @include formLabel;
  }

  .form-input {
    @include formInput;
  }
  .form-input2 {
    @include formInput2;
  }

  .form-success {
    @include formSuccess;
  }

  .form-fail {
    @include formFail;
  }

  .form-caption {
    @include formCaption;
  }

  .check-box {
    height: 18px;
    width: 18px;
  }

  .checkbox-label {
    @include checkboxLabel;
    display: flex;
  }

  .checkbox-wrapper {
    @include checkboxWrapper;
    margin-top: 25px;
  }

  .submit-button {
    @include submitButton;
  }

  .footer-text {
    @include footerText;
  }

  .footer-text-link {
    @include footerTextLink;
  }

  .fname {
    color: $small-text-black;
    margin-left: 10px;
    padding-right: 10px;
    float: right;
  }
  .username-margin-top-login {
    margin-top: 3.8vh;
    margin-bottom: 7.5vh;
  }
  .userName-input-login {
    background-color: $form-gray;
    border-color: transparent;
    flex: 2;
    height: 20px;
  }

  .password-input-login {
    background-color: $form-gray;
    border-color: transparent;
    width: 100%;
  }

  .show-password-login-p {
    margin-left: 16px;
    font-size: 13px;
    color: $text-black;
  }

  .login-button {
    height: 19px;
    width: 44px;
  }

  .innerText-input {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .danger-login {
    height: 12px;
    width: 12px;
    margin-right: 6px;
  }
}

.c-gradient {
  background: -webkit-linear-gradient(left, $app-header-green, $app-header-yellow);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 400;
}
.a-gradient {
  background: -webkit-linear-gradient(left, $app-header-yellow, $primary-blue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 400;
}
.input-textfield {
  width: 62px;
  height: 17px;
  margin: 74px 17px 8px 0;
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
  color: $dark-gray;
}
input:focus {
  outline: none;
}
.forgetText {
  text-align: right;
  font-size: 15px;
  margin-top: 4vh;
  margin-bottom: 8vh;
}

.forgetTextLink {
  width: fit-content;
  font-size: 15px;
  color: $buttn-blue;
  cursor: pointer;
  font-weight: 500;
  font-family: "Roboto";
}
.submitButton-photo {
  // margin: 15px auto;
  padding: 28px 6px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: $buttn-blue;
  color: $white;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-family: Roboto;
  letter-spacing: normal;
  line-height: 0.94;
  font-size: 16px;
}
.topText {
  text-align: center;
  font-size: 16px;
  margin-top: 12px;
  color: $dark-gray;
  font-family: Roboto;
  font-weight: 300;
}

.__ForgetPasswordContainer {
  @include mainContainer;
  .input-height {
    height: 50px;
  }
  .input-style {
    background-color: $form-gray;
    border-color: transparent;
    width: 100%;
  }
}
.__ForgetPassword {
  .wrapper {
    @include wrapper;
  }

  .app-logo {
    @include appLogo;
  }

  .header {
    @include header;
  }

  .form-wrapper {
    @include formWrapper;
  }

  .input-wrapper {
    @include inputWrapper;
    // display: flex;
    // margin-bottom: 28px
  }

  .multiple-input-wrapper {
    @include multipleInputWrapper;
    margin-top: 20px;
    margin-bottom: 20px;
    .input-wrapper {
      width: 28%;
      margin-top: 0px;
    }

    .wrap-forget2 {
      margin-top: 2px;
    }

    .date-input {
      width: 114%;
      margin-left: -8%;
      padding: 8%;
    }
  }

  .form-label {
    @include formLabel;
    margin-left: -2px;
  }

  .form-input {
    @include formInput;
  }
  .form-input2 {
    @include formInput2;
  }

  .form-success {
    @include formSuccess;
  }

  .form-fail {
    @include formFail;
  }

  .form-caption {
    @include formCaption;
  }

  .submit-button {
    @include submitButton;
    margin-top: 0px;
  }

  .skip-button {
    @include skipButton;
  }

  .footer-text {
    @include footerText;
    color: $dark-gray;
  }

  .innerText-input {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
  }

  select.minimal-black-day {
    background: url("../../Assets//Icons/triangle-down-black.svg") 90% / 10px
      no-repeat $form-gray;
  }

  select.minimal-black-day::after {
    background: url("../../Assets/Icons/triangle-up-black.svg") 90% / 10px
      no-repeat $form-gray;
  }
  .minimal-gray-day {
    background: url("../../Assets//Icons/triangle-down-gray.svg") 90% / 10px
      no-repeat $form-gray;
  }

  select.minimal-gray-day::after {
    background: url("../../Assets/Icons/triangle-up-gray.svg") 90% / 10px
      no-repeat $form-gray;
  }
}

.__SuccessPage {
  .wrapper {
    @include wrapper;
  }

  .app-logo {
    @include appLogo;
  }

  .header {
    @include header;
  }
  .form-wrapper {
    @include formWrapper;
  }
  .submit-button {
    @include submitButton;
  }
  .inner-container {
    display: block;
    text-align: center;
    font-family: "roboto";
    font-size: 14px;
    margin-bottom: 20%;
    margin-top: 30%;
  }
  .email-style {
    color: $primary-blue;
    font-family: "roboto";
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .welcome-message-style {
    color: $dark-gray;
    // margin-bottom: 10px;
    // margin-top: 5px;
    font-size: 18px;
  }
  .get-started {
    margin-bottom: 15%;
  }
  .anim-container {
    height: 120px;
  }
}

.fname {
  color: $small-text-black;
  margin-left: 10px;
  padding-right: 10px;
}

.form-fail {
  @include formFail;
  align-items: center;
  display: flex;
}

.dob {
  margin-top: -20px;
  margin-bottom: 20px;
}

.forget-show-password {
  margin-left: 16px;
}

.danger-forget-password {
  height: 14px;
  width: 14px;
  margin-right: 6px;
}

.__ForgetPassword3 {
  .wrapper {
    @include wrapper;
  }

  .app-logo {
    @include appLogo;
  }

  .header {
    @include header;
  }

  .form-wrapper {
    @include formWrapper;
  }

  .input-wrapper {
    @include inputWrapper;
    // display: flex;
  }

  .multiple-input-wrapper {
    @include multipleInputWrapper;
  }

  .form-label {
    @include formLabel;
  }

  .form-input {
    @include formInput;
  }
  .form-input2 {
    @include formInput2;
  }

  .form-success {
    @include formSuccess;
  }

  .form-fail {
    @include formFail;
    align-items: center;
    display: flex;
  }

  .form-caption {
    @include formCaption;
  }

  .submit-button {
    @include submitButton;
  }

  .skip-button {
    @include skipButton;
  }

  .wrap-forget {
    margin-top: 13%;
  }

  .footer-text {
    @include footerText;
    color: $dark-gray;
  }
  .check-box {
    height: 19px;
    width: 19px;
  }

  .checkbox-label {
    @include checkboxLabel;
    display: flex;
  }

  .checkbox-wrapper {
    @include checkboxWrapper;
    margin-top: 29px;
  }

  .show-password-login-p {
    margin-left: 16px;
  }
}

.checkbox-forget {
  display: flex;
}

.check-box {
  height: 19px;
  width: 19px;
}

.danger-forget {
  height: 14px;
  width: 14px;
  margin-right: 6px;
}

.select-option-style {
  color:$text-black;
}
.condition-strength{
  font-family: "roboto";
  font-size: 11px;
  position: relative;
  animation-name: slide-in;
  animation-duration: 0.5s;
  margin: 5px;
  margin-top: -1vh;
.item2{
  align-self: center;
  color:  $dark-gray;
	display: flex;
}
.item{
  align-self: center;
	display: flex;
  color: $dark-gray
}
}
@keyframes slide-in {
  0% {
    top: -10px;
  }
  100% {
    top: 0px;
  }
}



/* Small devices (portrait tablets and large phones, 600px and up) */

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .__Signup-container {
    width: 100%;
    background: $desktopBackground;
    padding: 2% 0;
    min-width: 0h;
  }

  .__CreateUserName {
    .wrapper {
      @include wrapper-1200;
    }
  }

  .__ForgetPassword {
    .wrapper {
      @include wrapper-1200;
    }
  }

  .__CompleteYourProfile {
    .wrapper {
      @include wrapper-1200;
    }

    .multiple-input-wrapper-double {
      flex-direction: column !important;

      .input-wrapper {
        width: 100%;

        input {
          width: 100% !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    }

    .multiple-input-wrapper {
      .date-input {
        width: 95%;
        margin-left: -0%;
      }

      .input-wrapper {
        width: 33%;
      }
    }
  }

  .__SetupDigitalCard {
    .wrapper {
      @include wrapper-1200;
    }

    .code-scanner-anim {
      width: 80%;
      margin-left: 10%;
    }
  }
  .__SuccessContainer {
    .wrapper {
      @include wrapper-1200;
    }
  }

  .__SetupMoreDetails {
    .wrapper {
      @include wrapper-1200;
    }
  }

  .__VerifyPhoneNumber {
    .wrapper {
      @include wrapper-1200;
    }
  }

  .__LoginMainPage {
    .wrapper {
      @include wrapper-1200;
    }
  }

  .__SuccessPage {
    .wrapper {
      @include wrapper-1200;
    }
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .__Signup-container {
    width: 100%;
    background: $desktopBackground;
    padding: 2% 0;
    min-width: 0h;
  }

  .__CreateUserName {
    .wrapper {
      @include wrapper-992;
    }
  }

  .__SuccessPage {
    .wrapper {
      @include wrapper-992;
    }
  }


  .__ForgetPassword {
    .wrapper {
      @include wrapper-992;
    }
  }

  .__CompleteYourProfile {
    .wrapper {
      @include wrapper-992;
    }

    .multiple-input-wrapper-double {
      flex-direction: column !important;

      .input-wrapper {
        width: 100%;

        input {
          width: 100% !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    }

    .multiple-input-wrapper {
      .date-input {
        width: 95%;
        margin-left: -0%;
      }

      .input-wrapper {
        width: 33%;
      }
    }
  }

  .__SetupDigitalCard {
    .wrapper {
      @include wrapper-992;
    }

    .code-scanner-anim {
      width: 80%;
      margin-left: 10%;
    }
  }
  .__SuccessContainer {
    .wrapper {
      @include wrapper-992;
    }
  }

  .__SetupMoreDetails {
    .wrapper {
      @include wrapper-992;
    }
  }

  .__VerifyPhoneNumber {
    .wrapper {
      @include wrapper-992;
    }
  }

  .__LoginMainPage {
    .wrapper {
      @include wrapper-992;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .__Signup-container {
    width: 100%;
    background: $desktopBackground;
    padding: 2vh 0;
    min-width: 0h;
  }

  .__SuccessPage {
    .wrapper {
      @include wrapper-768;
    }
  }


  .__CreateUserName {
    .wrapper {
      @include wrapper-768;
    }
  }

  .__ForgetPassword {
    .wrapper {
      @include wrapper-768;
    }
  }


  .__SuccessPage {
    .wrapper {
      @include wrapper-768;
    }
  }


  .__CompleteYourProfile {
    .wrapper {
      @include wrapper-768;
    }

    .multiple-input-wrapper-double {
      flex-direction: column !important;

      .input-wrapper {
        width: 100%;

        input {
          width: 100% !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    }

    .multiple-input-wrapper {
      .date-input {
        width: 95%;
        margin-left: -0%;
      }

      .input-wrapper {
        width: 33%;
      }
    }
  }

  .__SetupDigitalCard {
    .wrapper {
      @include wrapper-768;
    }

    .code-scanner-anim {
      width: 80%;
      margin-left: 10%;
    }
  }
  .__SuccessContainer {
    .wrapper {
      @include wrapper-768;
    }
  }

  .__SetupMoreDetails {
    .wrapper {
      @include wrapper-768;
    }
  }

  .__VerifyPhoneNumber {
    .wrapper {
      @include wrapper-768;
    }
  }

  .__LoginMainPage {
    .wrapper {
      @include wrapper-768;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .__Login-container{
    background: white;
  }
  .__Signup-container {
    width: 100%;
    background: white;
    padding: 0 0;
    min-width: 0h;
  }

  .__SuccessPage {
    background: white;

    .wrapper {
      @include mobileViewWrapper;
    }
  }


  .__CreateUserName {
    background: white;

    .wrapper {
      @include mobileViewWrapper;
    }
  }

  .__ForgetPassword {
    background: white;

    .wrapper {
      @include mobileViewWrapper;
    }
  }

  .__CompleteYourProfile {
    background: white;

    .wrapper {
      @include mobileViewWrapper;
    }

    .multiple-input-wrapper-double {
      flex-direction: column !important;

      .input-wrapper {
        width: 100%;

        input {
          width: 100% !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    }

    .multiple-input-wrapper {
      .date-input {
        width: 95%;
        margin-left: -0%;
      }

      .input-wrapper {
        width: 33%;
      }
    }
  }

  .__SetupDigitalCard {
    background: white;

    .wrapper {
      @include mobileViewWrapper;
    }

    .code-scanner-anim {
      width: 80%;
      margin-left: 10%;
    }
  }
  .__SuccessContainer {
    background: white;

    .wrapper {
      @include mobileViewWrapper;
    }
  }
  .__SetupMoreDetails {
    background: white;

    .wrapper {
      @include mobileViewWrapper;
    }
  }

  .__VerifyPhoneNumber {
    background: white;

    .wrapper {
      @include mobileViewWrapper;
    }
  }

  .__LoginMainPage {
    background: white;

    .wrapper {
      @include mobileViewWrapper;
    }
  }
}
select:focus {
  outline: none;
}
.terms{
  font-size: 12px;
  color: $dark-gray;
  font-family: "Roboto";
  margin-top: 20px;
}
.arrow {
  border: solid $dark-gray;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: 10px;
}
.link{
  font-size: 12px;
  color: $primary-blue;
  font-family: "Roboto";
  margin-top: 20px;
  cursor: pointer;
}
.check{
  margin-right: 10px;
}