@import "../../../GlobalVariables/colors.scss";
@import "../../../GlobalVariables/font.scss";

.__LeftNavbarWrapper {
  background: $primary-blue-dark;
  z-index: 1;
  width: 100%;
  height: 57px;
  display: flex;
  position: absolute;
  flex: 1;
  .logo-wrapper {
    width: 64px;
    height: 24px;
    margin: 40px auto;
    border-radius: 8px;
    background: $black;
    // display: none;
    color: $white;
    display: flex;
    font-size: 14px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .topicon-wrapper {
    padding-top: 116px;

    .icon1-wrapper {
      height: 25px;
      margin-bottom: 40px;
      cursor: pointer;
      align-self: center;
    }
  }
  .centerText {
    flex: 1;
    display: flex;
    justify-content: center;

    .unselected {
      font-size: 12px;
      color: $white;
      font-weight: 400;
      cursor: pointer;
      width: 79px;
      text-align: center;
      height: 100%;
      font-family: $lato;
      padding-top: 20px;
    }
    .selected {
      font-size: 12px;
      color: $primary-blue-dark;
      font-weight: 400;
      background-color:$white;
      width: 79px;
      text-align: center;
      height: 100%;
      font-family: $lato;
      padding-top: 20px;
    }
  }
  .header-small {
    font-family: "Lato";
    font-size: 16px;
    color: $white;
    align-self: center;
    font-weight: 700;
    padding-left: 22px;
    width: max-content;
    flex: 1;
    div {
      span {
        font-weight: 400;
      }
    }
  }.header-medium{
    font-family: "Lato";
    font-size: 18px;
    color: $white;
    align-self: center;
    font-weight: 700;
    padding-left: 22px;
    width: max-content;
    flex: 1;
    div {
      span {
        font-weight: 400;
      }
    }
  }
  .header-large{
    font-family: "Lato";
    font-size: 20px;
    color: $white;
    align-self: center;
    font-weight: 700;
    padding-left: 22px;
    width: max-content;
    flex: 1;
    div {
      span {
        font-weight: 400;
      }
    }
  }
  .bottom-iconwrapper {
    flex: 1;
    justify-content: right;
    display: flex;
    .icon2-wrapper {
      // top: 48vh;
      display: flex;
      cursor: pointer;
      align-self: center;
      width: 76px;
    }
    .profile-img {
      height: 38px;
      width: 38px;
      border-radius: 50%;
      object-fit: cover;
      margin-top: 6.5px;
      margin-right: 30px;
    }
    .img-cont {
      padding-left: 10px;
      padding-bottom: 10px;
      display: flex;
      padding-right: 15px;
      border-bottom: 0.5px solid #707070;
      margin-bottom: 10px;
      .profile-img2 {
        height: 31px;
        width: 31px;
        border-radius: 50%;
        object-fit: cover;
      }
      .data {
        display: block;
        padding-left: 10px;
        .name {
          font-family: "Roboto";
          font-size: 13px;
          color: $text-black;
          font-weight: 500;
        }
        .email {
          font-family: "Roboto";
          font-size: 10px;
          color: $text-black;
          margin-top: -5px;
        }
      }
    }

    .arrow-img-up {
      height: 14px;
      width: 14px;
      background-color: $gray;
      padding: 3px;
      border-radius: 50%;
      transform: rotate(-90deg);
      position: absolute;
      bottom: 0;
      right: 1px;
    }
    .arrow-img-down {
      height: 14px;
      width: 14px;
      background-color: $gray;
      padding: 3px;
      border-radius: 50%;
      transform: rotate(90deg);
      position: absolute;
      bottom: 0;
      right: 1px;
    }
    .popOver {
      position: absolute;
      background-color: $modal-background;
      right: 40px;
      border-radius: 8px;
      padding-top: 12px;
      padding-bottom: 12px;
      width: max-content;
      font-family: "roboto";
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      color: $text-black;
      top: 45px;
    }
    .sections {
      display: flex;
      font-size: 14px;
      margin-bottom: 8px;
      padding-left: 10px;
      cursor: pointer;
      .img-sec {
        height: 20px;
        align-self: center;
        margin-right: 18px;
        width: 20px;
      }
    }
    .sections:hover {
      background-color: $editor-background;
    }
  }
  .c-gradient {
    background: -webkit-linear-gradient(
      left,
      $app-header-green,
      $app-header-yellow
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }
  .a-gradient {
    background: -webkit-linear-gradient(
      left,
      $app-header-yellow,
      $primary-blue
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }

  .left-border {
    width: 2px;
    background-color: $light-blue-border;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 8px;
    height: 19px;
    align-self: center;
  }
}
// @media screen and (min-width: 1441px) {
//   .__LeftNavbarWrapper {
//     width: 75%;
//   }
// }
// @media screen and (min-width: 1695px) {
//   .__LeftNavbarWrapper {
//     width: 65%;
//   }
// }
// @media screen and (min-width: 1921px) {
//   .__LeftNavbarWrapper {
//     width: 55%;
//   }
// }
