@import '../../../GlobalVariables/colors.scss';
$defultTextFont: "SF Pro Display", sans-serif;

.signupMainWrapper {
  display: flex;
}

.renderWrapper {
  flex: 1 1;
}

.SignupInnercontainerRight {
  flex: 1;
  height: 100vh;
  background-color: #0d2a4c;
  .wrapper2 {
    margin: auto;
    padding: 20%;
  }
}

._SignupContainer {
  height: 100vh;
  background-color: $white;
  display: flex;
  flex: 1;
  .SignupInnercontainerLeft {
    flex: 1;
    height: 100vh;
    .wrapper {
      background: $white;
      margin: auto;
      padding:14% 20%;
      .bixHeader {
        font-family: $defultTextFont;
        font-size: 36px;
        margin: 8px auto;
        font-weight: bold;
        text-align: center;
        color: $buttn-blue;
      }
      .regLabel {
        font-family: $defultTextFont;
        font-size: 28px;
        margin: 30px auto;
        font-weight: bold;
        text-align: left;
        color: $black;
      }
      .input-label {
        pointer-events: none;
        font-size: 14px;
        color: $dark-gray;
        font-family: $defultTextFont;
      }
      .username-margin-top-login {
        margin-top: 3vh;
        margin-bottom: 4vh;
      }
      .inputdiv {
        border: 1px;
        border-bottom: $login-underline-gray 1px solid;

        .input-container {
          display: flex;
          flex: 1;
          padding-bottom: 3px;
          .login-input-box {
            background-color: $white;
            border: none;
            font-size: 16px;
            flex: 1;
          }
          .fname {
            color: $dark-gray;
            font-size: 16px;
            font-family: $defultTextFont;
            width: max-content;
          }
          .icon {
            float: right;
            height: 16px;
            width: 16px;
            cursor: pointer;
          }
          .icon1 {
            height: 16px;
            width: 16px;
            margin-right: 8px;
          }
        }
      }
      ::placeholder {
        color: $dark-gray;
        font-family: $defultTextFont;
        font-size: 16px;
      }

      .danger-image {
        height: 14px;
        width: 14px;
        margin-right: 6px;
      }
      .danger-image2 {
        height: 11px;
        width: 11px;
        margin-right: 6px;
        align-self: center;
      }
      .available {
        font-size: 13px;
        .right-text {
          color: $dark-gray;
          font-family: $defultTextFont;
          font-weight: 500;
        }
      }
    }
    .multi-container-div {
      display: flex;
      flex: 1;
      margin-top: 3.8vh;
    }
    .firstName-conatiner {
      flex: 1;
      margin-right: 8px;
    }
    .lastName-container {
      flex: 1;
      margin-left: 8px;
    }
    .form-success {
      font-size: 11px;
      font-weight: normal;
      color: $form-success;
      font-family: $defultTextFont;
      margin-top: 5px;
    }
    .form-input2 {
      -webkit-appearance: none;
      margin-bottom: 5px;
    }
    .form-input {
      -webkit-appearance: none;
    }
    .form-fail {
      font-size: 11px;
      font-weight: normal;
      color: $form-fail;
      font-family: $defultTextFont;
      align-items: center;
    }
    .form-caption {
      text-align: left;
      color: $dark-gray;
      font-size: 12px;
      margin-top: 26px;
    }
    .footerText {
      text-align: center;
      font-size: 16px;
      margin-top: 4vh;
      color: $small-text-black;
      font-family: $defultTextFont;
      font-weight: 500;
      display: flex;
      justify-content: center;
    }
    .footerTextLink {
      color: $buttn-blue;
      margin-left: 5px;
      cursor: pointer;
    }
    .submit-button {
      padding: 6% 2%;
      width: 80%;
      border-radius: 8px;
      background-color: $buttn-blue;
      color: $white;
      text-align: center;
      cursor: pointer;
      font-weight: bold;
      font-family: $defultTextFont;
      letter-spacing: normal;
      line-height: 0.94;
      margin-top: 6%;
      font-size: 18px;
      border-width: 0px;
      height: 50px;
      margin-left: 10%;
      margin-right: 10%;
    }

    .multiple-input-wrapper {
      display: flex;
      justify-content: space-around;
      margin-left: -10px;
      margin-top: -40px;
      margin-right: -8px;
    }
    .input-wrapper-e {
      margin-left: -1px;
      margin-right: -1px;
    }
  }
  .SignupInnercontainerRight {
    flex: 1;
    height: 100vh;
    background-color: #0d2a4c;
    .wrapper2 {
      margin: auto;
      padding: 20%;
    }
  }

  .cropper {
    height: 90%;
    position: relative;
    margin-top: 0px;
  }
  .slider {
    height: 10%;
    display: flex;
    align-items: center;
    margin: auto;
    width: 50%;
  }
  .container-cropper {
    height: 90%;
    padding: 5px;


  }
  .MuiSlider-rail {
    width: 255px;
    height: 2px;
    display: block;
    opacity: 0.38;
    position: absolute;
    background-color: currentColor;
  }
  .MuiSlider-root {
    color: #0d2a4c;
    width: 58%;
    cursor: pointer;
    height: 2px;
    display: inline-block;
    padding: 13px 0;
    position: relative;
    box-sizing: content-box;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    margin-top: 22%;
  }
}


.profile-pic-label {
  color: $dark-gray;
  font-family: $defultTextFont;
  font-size: 16px;
}

.lable-style {
  color: $dark-gray;
  font-family: $defultTextFont;
  font-size: 11px;
}

.gender-select {
  font-size: 16px;
  border-color: transparent;
  width: 95%;
  align-items: right;
  padding-top: 1px;
}

.date-select {
  border: transparent;
  font-size: 16px;
  width: 98%;
}
.danger-complete-your-profile {
  height: 12px;
  width: 12px;
  margin-right: 6px;
}
.popup-div1 {
  height: 80px;
  background-color: $primary-blue;
  display: flex;
  flex: 1;
  align-items: center;
  .popup-div1-h1 {
    color: $white;
    font-size: 20px;
    font-family: "roboto";
    font-weight: bold;
    flex: 2;
    margin-left: 20px;
  }

  .popup-div1-p {
    float: right;
    color: $white;
    font-size: 13px;
    cursor: pointer;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
  .close-icon {
    height: 12px;
    margin-right: 10px;
    align-items: center;
    cursor: pointer;
  }
}

.popup-div2 {
  width: 89%;
  height: 390px;
  position: absolute;
  background: grey;
}

.reactEasyCrop_Container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 63vh;
}
.reactEasyCrop_Contain {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: -10px;
  bottom: 0;
  left: 0;
  right: 0;
}

.reactEasyCrop_CropArea {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  box-shadow: 0 0 0 9999em;
  color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.add-photo-button {
  background-color: $buttn-blue;
  border: none;
  border-radius: 5px;
  width: 19%;
  height: 4.5vh;
  margin-top: -7.5%;
  margin-right: 10%;
  color: $white;
  float: right;
}

.popup-image-after {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.upload-div{
  width: 35px;
  height: 35px;

}

.add-photo-div {
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 50%;
  align-self: center;
  margin-bottom: -85px;
}

.add-photo-after-div {
  width: 44.4px;
  height: 44.4px;
  margin-top: 10%;
  margin-left: 75%;
}

.add-photo-after {
  width: 44.4px;
  height: 44.4px;
  border-radius: 50%;
  box-shadow: 2px 2px 3px rgb(153, 150, 150);
}

.modal-content {
  box-shadow: 2px 6px 8px 0px rgba(0, 0, 0, 0.75) !important;
  margin-top: 5%;
  width: 500px;
}
.modal-card-body {
  height: max-content;
  margin: 0px;
  padding: 20px;
  width: 100%;
}
.reactEasyCrop_CropArea {
  position: absolute;
  left: 50%;
  top: 48.8%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  box-shadow: 0 0 0 9999em;
  color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

// .cropper {
//   height: 90%;
//   position: relative;
//   margin-top: 0px;
// }
// .slider {
//   height: 10%;
//   display: flex;
//   align-items: center;
//   margin: auto;
//   width: 50%;
// }
// .container-cropper {
//   height: 90%;
//   padding: 5px;
// }
// .MuiSlider-rail {
//   width: 100%;
//   height: 2px;
//   display: block;
//   opacity: 0.38;
//   position: absolute;
//   background-color: currentColor;
// }
// .MuiSlider-root {
//   color: #0d2a4c;
//   width: 58%;
//   cursor: pointer;
//   height: 2px;
//   display: inline-block;
//   padding: 13px 0;
//   position: relative;
//   box-sizing: content-box;
//   touch-action: none;
//   -webkit-tap-highlight-color: transparent;
//   margin-top: 22%;
// }

.back-button {
  padding: 6% 2%;
  width: 80%;
  border-radius: 28px;
  background-color: $white;
  color: $buttn-blue;
  border-color: $buttn-blue solid 1px !important;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-family: $defultTextFont;
  letter-spacing: normal;
  line-height: 0.94;
  margin-top: 6%;
  font-size: 18px;
  border-width: 0px;
  height: 50px;
  margin-left: 10%;
  margin-right: 10%;
}

.success-background {
  width: 100%;
  height: 100%;
  background-color: $buttn-blue;
  margin-top: 0%;
  padding-top: 5%;
  padding-bottom: 10%;
}

.header-bix {
  font-family: $defultTextFont;
  font-size: 36px;
  margin: 8px auto;
  font-weight: bold;
  text-align: center;
  color: $buttn-blue;
  margin-bottom: 70px;
}

.sub-head {
  // margin: 8px auto;
  margin-top: 20px;
  font-family: $defultTextFont;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: $login-header;
}

.anime-container {
  height: 200px;
  margin-top: -100px;
}

.desc {
  font-family: $defultTextFont;
  font-size: 18px;
  color: $text-black;
}

.gt-started {
  padding: 4%;
  width: 80%;
  border-radius: 28px;
  background-color: $get-started;
  color: $white;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-family: $defultTextFont;
  letter-spacing: normal;
  line-height: 0.94;
  font-size: 18px;
  border-width: 0px;
  height: 50px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: -10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  // .signupMainWrapper {
  //   display: block !important;
  // }

  // .renderWrapper {
  //   flex: none;
  // }

  ._SignupContainer {
    z-index: 1;
    margin-top: -30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

    .SignupInnercontainerLeft {
      height: auto;

      .submit-button {
        padding: 0% 2%;
      }

      .wrapper {
        padding: 0% 5%;
        height: 55vh;
        overflow-y: auto;
        padding-bottom: 100px;


        .bixHeader {
          display: none;
          margin-bottom: -20px;
        }
      }

      .multi-container-div {
        display: block;
      }

      .firstName-conatiner {
        flex: 1;
        margin-right: 0px;
      }
      .lastName-container {
        flex: 1;
        margin-left: 0px;
      }
    }
  }

  .modal-content {
    width: 96%;
  }

  .MuiSlider-root {
    width: 56% !important;
  }

  .add-photo-button {
    background-color: $buttn-blue;
    border: none;
    border-radius: 5px;
    width: 19%;
    height: 4.5vh;
    margin-top: -12.5%;
    margin-right: 10%;
    color: $white;
    float: right;
  }

  .popup-image-after {
    width: 120px;
  }

  .add-photo-after-div {
    margin-left: 60%;
    margin-top: 18%;
  }
  .success-background{
    height: 100vh;
    .__SuccessPage{
      .wrapper{
        height: 92vh;
      }
    }
    
  }
}
