.AttchmentBubble {
  display: flex;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 7px;
  color: black;

  .bubbleContainer {
    background: #ffffff;
    width: max-content;
    border-radius: 5px;
    display: flex;
    margin-top: 6px;
    height: 22px;
    .leftBubble {
      padding-left: 8px;
      padding-right: 8px;
      border: 1px solid #dcdcdc;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px 0px 0px 5px;
      text-transform: uppercase;
    }

    .rightBubble {
      width: 31px;
      border-radius: 0px 5px 5px 0px;
      height: 22px;
      .line-1 {
        height: 1px;
        background-color: white;
        width: 21px;
        margin-top: 5px;
        margin-left: 5px;
      }
      .line-2 {
        height: 1px;
        background-color: white;
        width: 16px;
        margin-top: 2.5px;
        margin-left: 10px;
      }
      .line-3 {
        height: 1px;
        background-color: white;
        width: 10px;
        margin-top: 2.5px;
        margin-left: 16px;
      }
      .line-4 {
        height: 1px;
        background-color: white;
        width: 5px;
        margin-top: 2.5px;
        margin-left: 21px;
      }
    }
  }
  .moreContainer {
    background-color: #dcdcdc;
    height: max-content;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    height: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 13px;
    margin-left: 8px;
    
  }
}
