@import '../../../GlobalVariables/colors.scss';

//////////////////////////////////  Variables //////////////////////////////////////

$defultTextFont: "Roboto";
$userNamePlaceHolder: "../../Assets/Icons/vuesax-linear-profile.svg";

::placeholder {
  font-weight: normal;
}

.mobileUI {
  display: none;
}
.AdminBetaContainer {
  height: 100vh;
  background-color: $white;
  display: flex;
  flex: 1;
  font-family: $defultTextFont;
  font-family: "Roboto";
  .AdminBetaInnerContainerLeft {
    flex: 3;
    height: 100vh;
    .username-margin-top-passwordLink {
      margin-top: 3vh;
      margin-bottom: 5vh;
    }
    ::placeholder {
      color: $dark-gray;
      font-family: $defultTextFont;
      font-size: 16px;
    }

    .submit-button {
      padding: 6% 2%;
      width: 80%;
      border-radius: 8px;
      background-color: $buttn-blue;
      color: $white;
      text-align: center;
      cursor: pointer;
      font-weight: bold;
      font-family: $defultTextFont;
      letter-spacing: normal;
      line-height: 0.94;
      margin-top: 6%;
      font-size: 22px;
      border-width: 0px;
      height: 50px;
      margin-left: 10%;
      margin-right: 10%;
    }
    .terms {
      font-size: 12px;
      color: $black;
      font-family: "Roboto";
      margin-top: 20px;
    }
    .terms-beta {
      font-size: 14px;
      color: $black;
      font-family: "Roboto";
      margin-bottom: 10px;
      color: #585858;
      .check-box {
        height: 14px;
      }
    }
    .agreement-div {
      height: 55vh;
      width: 100%;
      border-radius: 8px;
      overflow-y: auto;
      padding: 18px 12px 10px 18px;
      color: #707070;
      font-size: 0.75rem;
      margin-top: 10%;
      margin-bottom: 5vh;
    }
    .welcome-div-1 {
      height: 55vh;
      width: 100%;
      overflow-y: auto;
      margin-top: 10%;
      .hed {
        font-size: 1.5rem;
        text-align: center;
        font-weight: bold;
        color: #2699fb;
      }
      img {
        margin-top: 10%;
        display: flex;
        justify-content: center;
        width: 100%;
        margin: auto;
      }
      .thanks {
        width: 80%;
        margin: auto;
        font-size: 69px;
        text-align: center;
        font-family: "Roboto";
        font-weight: bold;
        color: #234e6e;
        padding-top: 10%;
      }
      .sb {
        color: #707070;
        font-size: 21px;
        text-align: center;
        font-family: "Roboto";
      }
    }
    .snd-button {
      text-align: center;
      color: #234e6e;
      font-family: "Roboto";
      font-size: 22px;
      font-weight: 500;
      padding: 10px 100px 9px 99px;
      border-radius: 8px;
      border: solid 1px #234e6e;
      width: max-content;
      margin: auto;
      cursor: pointer;
    }
    .checkboxWrapper {
      margin-top: 20px;
      display: flex;
      .check-box {
        height: 18px;
        width: 18px;
        margin-right: 10px;
      }
      .checkboxLabel {
        font-family: $defultTextFont;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $text-black;
        align-items: center;
        align-self: center;
        align-content: center;
        display: flex;
      }
    }
    input.my-input:-internal-autofill-selected {
      background-color: rgb(255, 255, 255) !important;
      background-image: none !important;
      color: rgb(0, 0, 0) !important;
    }
    .wrapper {
      background: $white;
      margin: auto;
      padding: 8% 20%;
      .content-header {
        .content-Header {
          font-family: $defultTextFont;
          font-size: 30px;
          font-weight: bold;
          color: $black;
        }

        .content-header-bottom-text {
          color: #707070;
          font-family: $defultTextFont;
          font-size: 12px;
          margin-top: -0.5rem;
        }
      }
    }
    .admin-form-cont {
      height: 50vh;
      overflow-y: auto;
      padding-right: 10px;
      margin-top: 9vh;
      .alert-error {
        font-size: 11px;
        font-weight: normal;
        color: $form-fail;
        align-items: center;
        margin-top: 5px;
        margin-bottom: -10px;
        .danger-ic {
          height: 14px;
          width: 14px;
          margin-top: -2px;
          margin-right: 5px;
        }
      }
    }
    .right-btn-container {
      display: flex;
      justify-content: flex-end;
      font-family: "Roboto";
      .scnd-btn {
        color: #234e6e;
        font-size: 22px;
        font-weight: bold;
        align-self: center;
        margin-right: 45px;
        cursor: pointer;
      }
      .prim-btn {
        border-radius: 8px;
        background-color: #234e6e;
        color: $white;
        font-size: 22px;
        font-weight: bold;
        padding: 8px 45px;
        cursor: pointer;
        width: 155px;
        height: 50px;
        text-align: center;
      }
      .prim-loading {
        border: 1.5px solid $white;
        border-top: 1.5px solid $white;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        animation: spin 1s linear infinite;
        margin: auto;
      }
      .prim-load-l {
        border: 1.5px solid #a0a0a0;
        border-top: 1.5px solid $white;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        animation: spin 1s linear infinite;
        margin: auto;
        margin-top: 6px;
      }
    }
    .otp-btn-container {
      display: flex;
      justify-content: flex-end;
      font-family: "Roboto";
      .prim-btn {
        border-radius: 8px;
        background-color: #234e6e;
        color: $white;
        font-size: 22px;
        font-weight: bold;
        padding: 8px 45px;
        cursor: pointer;
        width: 205px;
        height: 50px;
        text-align: center;
      }
      .prim-loading {
        border: 1.5px solid $white;
        border-top: 1.5px solid $white;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        animation: spin 1s linear infinite;
        margin: auto;
      }
      .prim-load-l {
        border: 1.5px solid #a0a0a0;
        border-top: 1.5px solid $white;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        animation: spin 1s linear infinite;
        margin: auto;
        margin-top: 6px;
      }
    }
    .padding-25px {
      padding: 25px;
    }
    .margin-top-5vh {
      margin-top: -5vh;
    }
    .margin-top-20 {
      margin-top: 3rem;
    }
    .margin-top-style {
      margin-top: 2rem;
    }
    .resend-otp-time {
      font-family: "Roboto";
      color: #9d9d9d;
      font-size: 14px;
      margin-top: 2px;
    }
    .resend-otp {
      font-family: "Roboto";
      color: #2699fb;
      font-size: 14px;
      font-weight: 500;
      margin-top: 5px;
      cursor: pointer;
    }
    .margin-top-style-1 {
      margin-top: 1rem;
    }
    .disabled {
      background-color: #707070;
    }
    .alert {
      color: #2699fb;
      font-family: "Roboto";
      font-size: 10px;
    }
    .email-div {
      display: flex;
      width: 100%;
      flex: 1;
      .int {
        width: 78%;
      }
      .prim-btn {
        background-color: #234e6e;
        color: $white;
        font-size: 15px;
        cursor: pointer;
        height: max-content;
        align-self: center;
        padding: 7px 23px;
        border-radius: 4px;
        margin-left: 20px;
        width: 140px;
        text-align: center;
        .prim-load {
          border: 1.5px solid #a0a0a0;
          border-top: 1.5px solid $white;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          animation: spin 1s linear infinite;
          margin: auto;
        }
      }
    }
    .no-div {
      display: flex;
      width: 100%;
      flex: 1;
      .div-left {
        flex: 1;
        width: 100%;
        margin-right: 10px;
        .phone-error {
          font-size: 11px;
          font-weight: normal;
          color: $form-fail;
          align-items: center;
          margin-bottom: -10px;
          margin-top: 5px;
        }
      }
      .margin-top-style-div {
        margin-top: 1rem;
        display: none;
      }
      .div-right {
        flex: 1;
        width: 100%;
        margin-left: 10px;
        .phone-error {
          font-size: 11px;
          font-weight: normal;
          color: $form-fail;
          align-items: center;
          margin-bottom: -10px;
          margin-top: 5px;
        }
      }
    }
    .passwordLinkLabel {
      font-family: $defultTextFont;
      font-size: 28px;
      margin: 30px auto;
      font-weight: bold;
      text-align: left;
      color: $black;
    }
    .bullet {
      height: 0.4rem;
      min-width: 0.4rem;
      background: $buttn-blue;
      border-radius: 50%;
      margin-top: 0.5rem;
      margin-right: 8px;
    }
    .bullet-point {
      color: $buttn-blue;
      font-family: $defultTextFont;
      font-size: 1rem;
      display: flex;
      margin-bottom: 8.4rem;
      margin-left: -8px;
    }

    .form-container {
      width: 100%;
      background-color: $white;
    }

    .passwordLink-form {
      position: relative;
      // display: flex;
      flex-direction: column;
    }

    .passwordLink-input-box {
      background-color: $white;
      border: none;
      font-size: 16px;
      flex: 1;
      // outline: none;
    }
    .input-container {
      display: flex;
      flex: 1;
      padding-bottom: 3px;
    }
    .fname {
      color: $dark-gray;
      font-size: 16px;
      font-family: $defultTextFont;
      width: max-content;
    }
    .icon {
      float: right;
      height: 16px;
      width: 16px;
      cursor: pointer;
    }
    .icon1 {
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }
    .danger-passwordLink {
      height: 12px;
      width: 12px;
      margin-right: 6px;
    }
    .inputdiv {
      border: 1px;
      border-bottom: $login-underline-gray 1px solid;
      input {
        width: 100%;
        border: none;
      }
    }

    .input-label {
      pointer-events: none;
      font-size: 14px;
      color: $dark-gray;
      font-family: $defultTextFont;
    }

    .input-label :focus-within label {
      transform: translate(0, 12px) scale(0.8);
      color: #0a53e4;
    }
  }
  .AdminBetaInnerContainerRight {
    flex: 1;
    height: 100vh;
    background-color: $primary-blue;
    .wrapper2 {
      margin: auto;
      padding-left: 6vh;
      padding-right: 6vh;
      .onboard-header {
        font-family: "Roboto";
        color: $white;
        font-size: 1.5rem;
        font-weight: bold;
        width: max-content;
        margin-top: 4vh;
        margin-bottom: 2vh;
        .bixHeader {
          font-family: $defultTextFont;
          font-size: 34px;
          font-weight: bold;
          color: $white;
        }

        .header-bottom-text {
          color: $white;
          font-family: $defultTextFont;
          font-size: 9px;
          margin-top: -0.7rem;
          font-weight: 400;
        }
      }
      .onboard-header-thanks {
        font-family: "Roboto";
        color: $white;
        font-size: 1.5rem;
        font-weight: bold;
        width: max-content;
        margin-top: 4vh;
        margin-bottom: 2vh;
        .bixHeader {
          font-family: $defultTextFont;
          font-size: 34px;
          font-weight: bold;
          color: $white;
        }

        .header-bottom-text {
          color: $white;
          font-family: $defultTextFont;
          font-size: 9px;
          margin-top: -0.7rem;
          font-weight: 400;
        }
      }
      .step-header {
        font-family: "Roboto";
        color: $white;
        font-size: 1.5rem;
        font-weight: bold;
        width: max-content;
        margin-top: 5vh;
        margin-bottom: 2vh;
        .step-hdr {
          font-family: $defultTextFont;
          font-size: 29px;
          font-weight: bold;
          color: $white;
        }

        .step-header-bottom-text {
          color: $white;
          font-family: $defultTextFont;
          font-size: 12px;
          font-weight: normal;
          width: 180px;
          margin-top: -5px;
        }
      }
      .hr-l {
        height: 1px;
        width: 100%;
        background-color: $white;
      }
      .stp {
        display: flex;
        padding-top: 8vh;
      }
      .stp-mobile {
        display: none;
      }
    }
  }
  .danger-image {
    height: 14px;
    width: 14px;
    margin-right: 6px;
    align-self: center;
  }
}
.condition-strength-2 {
  font-family: "Roboto";
  font-size: 11px;
  position: relative;
  animation-name: slide-in;
  animation-duration: 0.5s;
  margin: 5px;
  .item2 {
    align-self: center;
    color: $dark-gray;
    display: flex;
  }
  .item {
    align-self: center;
    display: flex;
    color: $dark-gray;
  }
  .danger-image2 {
    height: 11px;
    width: 11px;
    margin-right: 6px;
    align-self: center;
  }
}

@keyframes slide-in {
  0% {
    top: -10px;
  }
  100% {
    top: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .AdminBetaContainer {
    display: block;

    .AdminBetaInnerContainerRight {
      height: max-content;
      flex: 0;
      padding-bottom: 1px;
      .wrapper2 {
        padding-top: 21px;
        padding-left: 0;
        padding-right: 0;
        .onboard-header {
          display: none;
        }
        .onboard-header-thanks {
          text-align: center;
          margin-top: 10px;
          margin: auto;
          margin-bottom: 21px;
        }
        .step-header {
          margin-top: 0;
          margin: auto;
          text-align: center;
          margin-bottom: 21px;
        }
        .stp {
          display: none;
        }
        .stp-mobile {
          display: block;
        }
      }
    }

    .AdminBetaInnerContainerLeft {
      height: 75vh;
      overflow-y: auto;

      .wrapper {
        padding: 0% 9%;
        .content-header {
          margin-top: 50px;
        }
        .agreement-div {
          background-color: #fff;
          margin-bottom: 40px;
          height: 40vh;
          padding: 0;
        }
        .welcome-div-1 {
          padding: 0;
          height: 40vh;
          .thanks {
            font-size: 50px;
            padding-top: 135px;
          }
          .sb {
            font-size: 18px;
          }
        }
        .snd-button {
          padding: 10px 66px 9px 65px;
          font-size: 18px;
        }
        .admin-form-cont {
          margin-top: 50px;
          padding-right: 0;
          height: max-content;
          padding-bottom: 50px;
          margin-bottom: 90px;
          .margin-top-style {
            margin-top: 1rem;
          }
          .email-div {
            display: block;
            .int {
              width: 100%;
            }
            .prim-btn {
              margin-left: 0;
              margin-top: 22px;
            }
          }
          .no-div {
            display: block;
            .div-left {
              margin-right: 0;
            }
            .margin-top-style-div {
              display: block;
            }
            .div-right {
              margin-left: 0;
            }
          }
        }
        .right-btn-container {
          display: block;
          justify-content: center;
          text-align: center;
          .scnd-btn {
            text-align: center;
            margin-right: 0;
            margin-bottom: 10px;
          }
          .prim-btn{
            width: 100%;
          }
        }
        .otp-btn-container{
          .prim-btn{
            width: 100%;
          }

        }
        .padding-25px {
          padding: 0;
        }
        .margin-top-5vh {
          margin-top: 0;
        }
      }
    }
  }
}

