@import '../../../../GlobalVariables/colors.scss';
$defultTextFont: "SF Pro Display", sans-serif;
$bixFont:"Roboto", sans-serif;
$folder-title:"proxima-nova", sans-serif;

.block-confirm-wrapper{
    align-self: center;
    background-color: rgba(0, 0, 0, 0.45) ;
}

.confirm-body{
    background-color: $white;
    width: 420px;
    // height:160px;
    border-radius: 15px;
    padding: 30px;
}

.confirm-desc{
    font-family: $defultTextFont;
    font-size: 18px;
    font-weight: 700;
    word-spacing: 2px;
    word-break: break-all;
}

.btn-wrapper{
   display: flex;
   justify-content: end;
   padding-top: 10px;
}

.block-btn1{
    font-family: $defultTextFont;
    font-size: 14px;
    padding: 10px 20px;
    margin-right: 15px;
    color: $white;
    border-radius: 5px;
    background-color: red;
    cursor: pointer;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;

}

.cancel-btn1{
    font-family: $defultTextFont;
    font-size: 14px;
    padding: 10px 20px;
    margin-right: 10px;
    color: $primary-blue;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    border: 1px $primary-blue solid;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;



}


@media screen and (max-width: 600px) {


    .confirm-body{
        width: 80%;
        
    }

    .confirm-desc{
        font-size: 15px;
    font-weight: 700;
    }

}