@import '../../../GlobalVariables/colors.scss';
._UploadDPModalStyles {
  .modal-1 {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 50; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    // background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
    align-content: center;
  }

  /* Modal Content */
  .modal-content-new {
    background-color: $white;
    margin-left: auto;
    margin-right: auto;
    width: 450px;
    box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px;
    margin-top: 13%;
    width: 557px;
  }
  .modal-content-1 {
    background: $primary-blue;
    margin: auto;
    padding: 20px;
    margin-top: -15px;
    border-radius: 10px;
    display: flex;
    color: $white;
    align-content: center;
    font-size: 20px;
  }
  .modal-content-2 {
    background: $white;
    margin: auto;
    padding-left: 20px;
    padding-bottom: 15px;
    margin-top: -15px;
    display: flex;
    color: $primary-blue;
    font-weight: 500;
    align-content: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 18px;
    border-bottom: 0.5px solid $primary-blue;
    font-family: "Roboto";
    flex: 1;
    .left {
      display: flex;
      flex: 3;
      .camera {
        background-color: $primary-blue;
        width: 43px;
        height: 40px;
        margin-left: 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        .img {
          width: 21px;
          height: 21px;
          margin: auto;
        }
      }
      .popup-div1-h1 {
        padding-left: 30px;
        padding-top: 10px;
      }
    }

    .right {
      display: flex;
      flex: 1;
      margin-right: 5%;

      .reupload {
        background-color: $primary-blue;
        color: $white;
        height: max-content;
        font-size: 11px;
        padding: 3px 14px;
        border-radius: 20px;
        margin-top: 20px;
        display: flex;
        cursor: pointer;
        margin-right: 15%;
        .img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
          align-self: center;
        }
      }
      .close {
        margin-top: 20px;
        width: 15px;
        align-self: center;
        cursor: pointer;
      }
    }
  }
  .circle {
    background-color: $primary-blue;
    margin: auto;
    padding: 20px;
    width: 55px;
    float: right;
    margin-top: -28px;
    height: 55px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -2px;
    cursor: pointer;
  }
  .inner-container {
    display: flex;
    justify-content: center;
  }
  .inner-container-2 {
    display: flex;
    height: 250px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .upload-grp-pic {
    background-color: $light-gray;
    height: 80px;
    width: 80px;
    border-radius: 40px;
    justify-content: center;
    display: flex;
    margin: auto;
    margin-top: 25px;
  }

  .search-bar-wrapper {
    background-color: $light-gray;
    width: 442px;
    border-radius: 24px;
    margin-bottom: 30px;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 16px;

    .selected {
      background-color: $gray;
      border-radius: 16px;
      padding-right: 21px;
      padding-left: 21px;
      margin-right: 16px;
      text-align: left;
      font: normal normal normal 16px/33px Roboto;
      letter-spacing: 0px;
      color: $text-black;
      margin-bottom: 10px;
    }
    input {
      outline: none;
      border: none;
      background-color: $light-gray;
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
    }
    ::placeholder {
      /* Most modern browsers support this now. */
      color: $dark-gray;
    }
  }
  .modal-content {
    box-shadow: 2px 6px 8px 0px rgba(0, 0, 0, 0.75) !important;
    margin-top: 5%;
    width: 500px;
  }
  .inner-container-3 {
    height: 45vh;
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
  .popup-div1 {
    height: 80px;
    background-color: $primary-blue;
    display: flex;
    flex: 1;
    align-items: center;
    .popup-div1-h1 {
      color: $white;
      font-size: 20px;
      font-family: "roboto";
      font-weight: bold;
      flex: 2;
      margin-left: 20px;
    }

    .popup-div1-p {
      float: right;
      color: $white;
      font-size: 13px;
      cursor: pointer;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
    }
    .close-icon {
      height: 12px;
      margin-right: 10px;
      align-items: center;
      cursor: pointer;
    }
  }

  .reactEasyCrop_Container {
    position: absolute;
    top: 7%;
    left: 10%;
    bottom: 0;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    touch-action: none;
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 80%;
  }
  .reactEasyCrop_Contain {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    position: absolute;
    top: -10px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .reactEasyCrop_CropArea {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0 0 0 9999em;
    color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
  .add-photo-button {
    background-color: $buttn-blue;
    border: none;
    border-radius: 5px;
    width: 19%;
    height: 4.5vh;
    margin-top: -7.5%;
    margin-right: 10%;
    color: $white;
    float: right;
  }
  .MuiSlider-rail {
    width: 445px;
    height: 1.5px;
    display: block;
    opacity: 0.38;
    position: absolute;
    background-color: $dark-gray;
  }
  .MuiSlider-track {
    height: 1.5px;
    width: 47.5%;
    display: block;
    position: absolute;
    border-radius: 1px;
  }
  .MuiSlider-root {
    color: $buttn-blue;
    width: 445px;
    cursor: pointer;
    height: 1.5px;
    display: inline-block;
    padding: 13px 0;
    position: absolute;
    box-sizing: content-box;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    margin-top: -35px;
  }
  .MuiSlider-thumb {
    width: 6px;
    height: 6px;
    outline: 0;
    position: absolute;
    box-sizing: border-box;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    margin-top: -2.5px;
    margin-left: -3px;
  }
  .reactEasyCrop_CropArea {
    position: absolute;
    left: 50%;
    top: 48.8%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0 0 0 9999em;
    color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }

  .cropper {
    height: 90%;
    position: relative;
    margin-top: 0px;
  }
  .slider {
    height: 10%;
    display: flex;
    align-items: center;
    margin: auto;
    width: 50%;
  }
  .add-photo-div:hover {
    border: $primary-blue 0.5px;
    box-shadow: 0 0 7px $primary-blue;
    outline: none;
  }

  .add-photo-div {
    width: 100px;
    height: 100px;
    margin: auto;
    border-radius: 50%;
    cursor: pointer;
  }
  .popup-image-after {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .add-photo-after-div {
    width: 36px;
    height: 36px;
    margin-top: -45%;
    margin-left: 75%;
  }

  .add-photo-after {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    box-shadow: 2px 2px 3px rgb(153, 150, 150);
    background-color: $white;
  }
  .add-photo {
    background-repeat: no-repeat;
    object-fit: cover;
    margin: auto;
    margin-top: 2.5vh;
    margin-left: 18%;
    border-radius: 50%;
    width: 65%;
  }
  .minus-container {
    margin-top: -35px;
    margin-right: 10px;
    .img {
      width: 8px;
    }
  }
  .plus-container {
    margin-top: -35px;
    margin-left: 87.5%;
    .img {
      width: 8px;
    }
  }
}
.sideBarSingleMessage {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  background: none;
  max-width: 110px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sideBarSingleMessageProPicWrapper {
  padding: 8px;
  width: 75px;
  text-align: center;
}

.sideBarSingleMessageLeft {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.sideBarSingleMessageProPic {
  // height: 48px;
  min-width: 48px;
  height: 48px !important;
  // width: 62px !important;
  border-radius: 50%;
  object-fit: cover;
}

.sideBarSingleMessageAndSender {
  padding: 8px;
  width: 220px;
}
.sideBarSingleMessageSender {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
}
.sideBarSingleMessage {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  background: none;
  max-width: 110px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 600px) {
  ._UploadDPModalStyles {
    .modal-content-new {
      width: 96%;
    }

    .modal-content-top {
      width: 96%;
    }

    .modal-content-2 {
      padding-left: 0px;
      .right {
        .close {
          margin-top: 0;
        }
      }
    }
  }
}
