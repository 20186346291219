@import "../../../GlobalVariables/colors.scss";
@import "../../../GlobalVariables/font.scss";

@mixin sidebarSingleMessages {
  cursor: pointer;
  height: 103.84px;
  border-bottom: 1px solid $border-gray-line;
  padding-left: 7.5px;
}

@mixin sideBarSingleMessageLeft {
  display: flex;
  width: 100%;
  padding-top: 13.6px;
  min-width: 75%;
}
.animation {
  animation: blowUpModal 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
@keyframes blowUpModal {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@mixin sideBarSingleMessageAndSender {
  width: 90%;
  margin-top: -10px;
}

@mixin sideBarSingleMessageSender {
  font-family: $lato;
  font-size: 14px;
  font-weight: 800;
  text-align: left;
  color: $primary-blue-dark;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  padding-left: 12px;
  width: max-content;
}
@mixin sideBarSingleMessageSender-selected {
  font-family: $lato;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: $white;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  padding-left: 12px;
  width: max-content;
}
@mixin sideBarSingleMessage {
  font-family: $lato;
  font-size: 12px;
  font-weight: normal;
  background-color: transparent;
  margin-top: -2px;
  color: $darker-gray;
  letter-spacing: normal;
  text-align: left;
  max-width: 100%;
  padding-left: 47px;
}
@mixin sideBarSingleMessage-selected {
  font-family: $lato;
  font-size: 12px;
  font-weight: normal;
  background-color: transparent;
  margin-top: -2px;
  color: $darker-gray;
  letter-spacing: normal;
  text-align: left;
  max-width: 100%;
  padding-left: 47px;
}

@mixin sideBarSingleMessageRight {
  width: 250px;
}

@mixin sideBarSingleMessageDateAndNoti {
  margin-top: 18.72px;
  display: block;
  padding-right: 8.29px;
}

@mixin sideBarSingleMessageDate {
  font-family: $lato;
  font-size: 9px;
  font-weight: normal;
  color: $border-gray-line;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@mixin sideBarSingleMessageNotiWrapper {
  background-color: $primary-blue-light;
  margin: auto;
  text-align: center;
  border-radius: 5px;
  width: 22px;
  height: 14px;
  margin-left: 10px;
}

@mixin sideBarSingleMessageNoti {
  font-family: $lato;
  font-size: 11px;
  color: $white;
  font-weight: normal;
  font-weight: 800;
  margin-top: -1px;
}
// medium
@mixin sidebarSingleMessages-medium {
  cursor: pointer;
  height: 111.84px;
  border-bottom: 1px solid $border-gray-line;
  padding-left: 9.5px;
}

@mixin sideBarSingleMessageLeft-medium {
  display: flex;
  width: 100%;
  padding-top: 12.6px;
  min-width: 75%;
}
@mixin sideBarSingleMessageAndSender-medium {
  width: 90%;
  margin-top: -10px;
}

@mixin sideBarSingleMessageSender-medium {
  font-family: $lato;
  font-size: 16px;
  font-weight: 800;
  text-align: left;
  color: $primary-blue-dark;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  padding-left: 14px;
  width: max-content;
}
@mixin sideBarSingleMessageSender-selected-medium {
  font-family: $lato;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: $white;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  padding-left: 14px;
  width: max-content;
}
@mixin sideBarSingleMessage-medium {
  font-family: $lato;
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  margin-top: -2px;
  color: $darker-gray;
  letter-spacing: normal;
  text-align: left;
  max-width: 100%;
  padding-left: 49px;
}
@mixin sideBarSingleMessage-selected-medium {
  font-family: $lato;
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  margin-top: -2px;
  color: $darker-gray;
  letter-spacing: normal;
  text-align: left;
  max-width: 100%;
  padding-left: 49px;
}

@mixin sideBarSingleMessageRight-medium {
  width: 252px;
}

@mixin sideBarSingleMessageDateAndNoti-medium {
  margin-top: 15.6px;
  display: block;
  padding-right: 10.29px;
}

@mixin sideBarSingleMessageDate-medium {
  font-family: $lato;
  font-size: 11px;
  font-weight: normal;
  color: $border-gray-line;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@mixin sideBarSingleMessageNotiWrapper-medium {
  background-color: $primary-blue-light;
  margin: auto;
  text-align: center;
  border-radius: 5px;
  width: 24px;
  height: 16px;
  margin-left: 12px;
}

@mixin sideBarSingleMessageNoti-medium {
  font-family: $lato;
  font-size: 13px;
  color: $white;
  font-weight: normal;
  font-weight: 800;
  margin-top: -1px;
}

//large
@mixin sidebarSingleMessages-large {
  cursor: pointer;
  height: 118.84px;
  border-bottom: 1px solid $border-gray-line;
  padding-left: 9.5px;
}

@mixin sideBarSingleMessageLeft-large {
  display: flex;
  width: 100%;
  padding-top: 12.6px;
  min-width: 75%;
}
@mixin sideBarSingleMessageAndSender-large {
  width: 90%;
  margin-top: -10px;
}

@mixin sideBarSingleMessageSender-large {
  font-family: $lato;
  font-size: 18px;
  font-weight: 800;
  text-align: left;
  color: $primary-blue-dark;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  padding-left: 14px;
  width: max-content;
}
@mixin sideBarSingleMessageSender-selected-large {
  font-family: $lato;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: $white;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  padding-left: 14px;
  width: max-content;
}
@mixin sideBarSingleMessage-large {
  font-family: $lato;
  font-size: 16px;
  font-weight: normal;
  background-color: transparent;
  margin-top: -2px;
  color: $darker-gray;
  letter-spacing: normal;
  text-align: left;
  max-width: 100%;
  padding-left: 49px;
}
@mixin sideBarSingleMessage-selected-large {
  font-family: $lato;
  font-size: 16px;
  font-weight: normal;
  background-color: transparent;
  margin-top: -2px;
  color: $darker-gray;
  letter-spacing: normal;
  text-align: left;
  max-width: 100%;
  padding-left: 49px;
}

@mixin sideBarSingleMessageRight-large {
  width: 252px;
}

@mixin sideBarSingleMessageDateAndNoti-large {
  margin-top: 15.6px;
  display: block;
  padding-right: 10.29px;
}

@mixin sideBarSingleMessageDate-large {
  font-family: $lato;
  font-size: 13px;
  font-weight: normal;
  color: $border-gray-line;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@mixin sideBarSingleMessageNotiWrapper-large {
  background-color: $primary-blue-light;
  margin: auto;
  text-align: center;
  border-radius: 5px;
  width: 24px;
  height: 18px;
  margin-left: 12px;
}

@mixin sideBarSingleMessageNoti-large {
  font-family: $lato;
  font-size: 15px;
  color: $white;
  font-weight: normal;
  font-weight: 800;
  margin-top: -1px;
}
.__SidebarWrapper {
  min-height: 100vh;
  // border-right: 1px solid $gray;
  background: $white;
  width: 24.6%;
  min-width: 24.6%;
  max-width: 24.6%;
  margin-top: 57px;
  display: flex;
  padding-top: 11.06px;
  .floating-btn {
    display: none;
  }
  .left-option-bar {
    width: 18%;
    height: 100vh;
    border-right: 1px solid #dcdcdc;
    justify-content: center;
    display: flex;
    .header-container {
      cursor: pointer;
      height: 31px;
      width: 46px;
      border: 1px solid $border-gray-line;
      justify-content: center;
      display: flex;
      .profile-img2 {
        height: 26px;
        width: 26px;
        object-fit: cover;
        align-self: center;
      }
    }
    .popOver {
      position: absolute;
      background-color: $modal-background;
      border-radius: 8px;
      padding-top: 12px;
      padding-bottom: 5px;
      width: 200px;
      font-family: "roboto";
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      color: $text-black;
      top: 100px;
      left: 10px;
      z-index: 1;
      .selected {
        display: flex;
        background-color: $white;
        padding: 5px 7px;
        border-radius: 4px;
        cursor: pointer;
        width: 190px;
        margin-bottom: -15px;
      }
      .unSelected {
        display: flex;
        cursor: pointer;
        padding: 5px 7px;
        margin-bottom: -15px;
      }
      .img-cont {
        padding-left: 5px;
        padding-bottom: 10px;
        display: flex;
        padding-right: 8px;
        margin-bottom: 10px;
        width: 100%;

        .profile-img2 {
          height: 33px;
          width: 33px;
          border-radius: 50%;
          object-fit: cover;
          border: 1px solid $sub-topic-gray;
          padding: 2px;
        }
        .data {
          display: block;
          margin-left: 10px;
          max-width: 140px;
          width: 140px;

          .name {
            font-family: "Roboto";
            font-size: 13px;
            color: $text-black;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100px;
          }
          .email {
            font-family: "Roboto";
            font-size: 10px;
            color: $text-black;
            margin-top: -5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 120px;
          }
        }
      }
    }
    .sections {
      display: flex;
      font-size: 13px;
      margin-bottom: 8px;
      padding-left: 20px;
      cursor: pointer;
      color: $text-black;
      font-weight: 500;
      align-self: center;
      border-top: 0.5px solid #707070;
      padding-top: 10px;
      .img-sec {
        height: 20px;
        align-self: center;
        margin-right: 18px;
        width: 20px;
        filter: invert(100%);
      }
    }
  }
  .right-option-bar {
    width: 82%;
    border-right: 1px solid #dcdcdc;
  }
  .contacts-nav {
    // width: 373px;
    height: 100%;
    position: absolute;
    transition: all 0.7s;
    visibility: hidden;
    background-color: $white;
    z-index: 1;
    border-right: 1px solid $gray;

    .contacts-heading {
      .contact-title {
        padding-bottom: 30px;
        background-color: $white;
        font-style: $defultTextFont;
        font-size: 20px;
        font-weight: 700;
        // padding-left: 25px;
      }
    }
  }

  .top-navbar-small {
    border-bottom: $border-gray-line solid 1px;
    .top-div {
      display: flex;
      justify-content: space-between;
      margin-top: -11.06px;
      .icons {
        width: 85%;
        .new-chat-btn {
          cursor: pointer;
          display: flex;
          background-color: $primary-blue-dark;
          height: 28.42px;
          width: 80.72px;
          min-width: 80.72px;
          border-radius: 2px;
          justify-content: center;
          z-index: 0;
          font-family: $lato;
          margin-left: 7.35px;
          .new-Chat {
            font-family: $lato;
            font-size: 12px;
            color: $white;
            font-weight: 600;
            align-self: center;
          }
          .add {
            align-self: center;
            height: 16px;
            width: 16px;
            margin-right: 2px;
          }
        }
        .right-search-wrapper {
          display: flex;
          border-bottom: 1px solid $border-gray-line;
          width: 100%;
          margin-left: 14.78px;
          .more {
            align-self: center;
            height: 12px;
            width: 12px;
          }
          .search-bar-wrapper-new {
            display: flex;
            // padding: 5px 30px 5px 15px;
            align-self: center;
            width: 100%;
            flex: 1;
            margin-bottom: 5px;
            padding-top: 7px;
            .img-icon-style {
              height: 9px;
              width: 9px;
              align-self: center;
            }
            .input-style-new {
              outline: none;
              border: none;
              font-family: $lato;
              font-size: 9px;
              font-weight: normal;
              text-align: left;
              margin-left: 10px;
              flex: 1;
              width: inherit;
            }
            ::placeholder {
              /* Most modern browsers support this now. */
              color: #dcdcdc;
              font-size: 9px;
              font-weight: normal;
              font-family: $lato;
            }
          }
        }
        .mobile-topnavbar {
          display: none;
        }
      }

      .more-icon {
        margin-top: 5px;
        cursor: pointer;
        margin-right: 15px;
      }
    }

    .bottom-div {
      padding-bottom: 10px;
      width: 100%;
      background: lawngreen;

      .second-header {
        margin-top: 10px;
        font-family: "Roboto";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        color: $text-black;
        border-radius: 12px;
        padding: 7px 15px 7px 15px;
        border: solid 1px $primary-blue;
        line-height: 1.44;
        align-self: center;
        width: 55%;
        overflow: hidden !important;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .label {
        margin-top: 10px;
        background-color: $primary-blue;
        border-radius: 16px;
        align-self: center;
        height: auto;
        min-height: 28px;
        width: 33%;
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 2px 2px 2px 2px;
      }
      .text-message {
        font-size: 11px;
        align-self: center;
        font-family: "Roboto";
        font-weight: normal;
        line-height: 1.56;
        color: $white;
        overflow: hidden !important;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .my-dropdown {
        // width: 18px;
        font-family: "Roboto";
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        height: 24px;
        padding: 5px 16px;
        border-radius: 8px;
        background-color: $gray;
      }
    }
  }
  .top-navbar-medium {
    border-bottom: $border-gray-line solid 1px;
    .top-div {
      display: flex;
      justify-content: space-between;
      margin-top: -11.06px;
      .icons {
        width: 85%;
        .mobile-topnavbar {
          display: none;
        }

        .new-chat-btn {
          cursor: pointer;
          display: flex;
          background-color: $primary-blue-dark;
          height: 28.42px;
          width: 80.72px;
          border-radius: 2px;
          justify-content: center;
          z-index: 0;
          font-family: $lato;
          margin-left: 7.35px;
          .new-Chat {
            font-family: $lato;
            font-size: 13px;
            color: $white;
            font-weight: 600;
            align-self: center;
          }
          .add {
            align-self: center;
            height: 16px;
            width: 16px;
            margin-right: 2px;
          }
        }
        .new-chat-btn-large {
          cursor: pointer;
          display: flex;
          background-color: $primary-blue-dark;
          height: 28.42px;
          width: 80.72px;
          border-radius: 2px;
          justify-content: center;
          z-index: 0;
          font-family: $lato;
          margin-left: 7.35px;
          .new-Chat-large {
            font-family: $lato;
            font-size: 14px;
            color: $white;
            font-weight: 600;
            align-self: center;
          }
          .add {
            align-self: center;
            height: 16px;
            width: 16px;
            margin-right: 2px;
          }
        }

        .right-search-wrapper {
          display: flex;
          border-bottom: 1px solid $border-gray-line;
          width: 162.58px;
          margin-left: 14.78px;
          .more {
            align-self: center;
            height: 12px;
            width: 12px;
          }
          .search-bar-wrapper-new {
            display: flex;
            // padding: 5px 30px 5px 15px;
            align-self: center;
            width: 100%;
            flex: 1;
            margin-bottom: 5px;
            padding-top: 7px;
            .img-icon-style {
              height: 9px;
              width: 9px;
              align-self: center;
            }
            .input-style-new {
              outline: none;
              border: none;
              font-family: $lato;
              font-size: 9px;
              font-weight: normal;
              text-align: left;
              margin-left: 10px;
              flex: 1;
            }
            ::placeholder {
              /* Most modern browsers support this now. */
              color: #dcdcdc;
              font-size: 9px;
              font-weight: normal;
              font-family: $lato;
            }
          }
        }
      }
      .more-icon {
        margin-right: 15px;
        margin-top: 5px;
        cursor: pointer;
      }
    }

    .bottom-div {
      padding-bottom: 10px;
      width: 100%;

      .second-header {
        margin-top: 10px;
        font-family: "Roboto";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        color: $text-black;
        border-radius: 12px;
        padding: 7px 15px 7px 15px;
        border: solid 1px $primary-blue;
        line-height: 1.44;
        align-self: center;
        width: 55%;
        overflow: hidden !important;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .label {
        margin-top: 10px;
        background-color: $primary-blue;
        border-radius: 16px;
        align-self: center;
        height: auto;
        min-height: 28px;
        width: 33%;
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 2px 2px 2px 2px;
      }
      .text-message {
        font-size: 11px;
        align-self: center;
        font-family: "Roboto";
        font-weight: normal;
        line-height: 1.56;
        color: $white;
        overflow: hidden !important;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .my-dropdown {
        // width: 18px;
        font-family: "Roboto";
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        height: 24px;
        padding: 5px 16px;
        border-radius: 8px;
        background-color: $gray;
      }
    }
  }
  .top-navbar-large {
    border-bottom: $border-gray-line solid 1px;
    .top-div {
      display: flex;
      justify-content: space-between;
      margin-top: -11.06px;
      .more-icon {
        margin-right: 15px;
        margin-top: 5px;
        cursor: pointer;
      }

      .icons {
        width: 85%;

        .mobile-topnavbar {
          display: none;
        }
        .new-chat-btn {
          cursor: pointer;
          display: flex;
          background-color: $primary-blue-dark;
          height: 28.42px;
          width: 80.72px;
          border-radius: 2px;
          justify-content: center;
          z-index: 0;
          font-family: $lato;
          margin-left: 7.35px;
          .new-Chat {
            font-family: $lato;
            font-size: 14px;
            color: $white;
            font-weight: 600;
            align-self: center;
          }
          .add {
            align-self: center;
            height: 16px;
            width: 16px;
            margin-right: 2px;
          }
        }
        .right-search-wrapper {
          display: flex;
          border-bottom: 1px solid $border-gray-line;
          width: 162.58px;
          margin-left: 14.78px;
          .more {
            align-self: center;
            height: 12px;
            width: 12px;
          }
          .search-bar-wrapper-new {
            display: flex;
            // padding: 5px 30px 5px 15px;
            align-self: center;
            width: 100%;
            flex: 1;
            margin-bottom: 5px;
            padding-top: 7px;
            .img-icon-style {
              height: 9px;
              width: 9px;
              align-self: center;
            }
            .input-style-new {
              outline: none;
              border: none;
              font-family: $lato;
              font-size: 9px;
              font-weight: normal;
              text-align: left;
              margin-left: 10px;
              flex: 1;
            }
            ::placeholder {
              /* Most modern browsers support this now. */
              color: #dcdcdc;
              font-size: 9px;
              font-weight: normal;
              font-family: $lato;
            }
          }
        }
      }
    }

    .bottom-div {
      padding-bottom: 10px;
      width: 100%;

      .second-header {
        margin-top: 10px;
        font-family: "Roboto";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        color: $text-black;
        border-radius: 12px;
        padding: 7px 15px 7px 15px;
        border: solid 1px $primary-blue;
        line-height: 1.44;
        align-self: center;
        width: 55%;
        overflow: hidden !important;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .label {
        margin-top: 10px;
        background-color: $primary-blue;
        border-radius: 16px;
        align-self: center;
        height: auto;
        min-height: 28px;
        width: 33%;
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 2px 2px 2px 2px;
      }
      .text-message {
        font-size: 11px;
        align-self: center;
        font-family: "Roboto";
        font-weight: normal;
        line-height: 1.56;
        color: $white;
        overflow: hidden !important;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .my-dropdown {
        // width: 18px;
        font-family: "Roboto";
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        height: 24px;
        padding: 5px 16px;
        border-radius: 8px;
        background-color: $gray;
      }
    }
  }
  .connection-status {
    height: 107.48px;
    background-color: rgba(255, 196, 0, 0.74);
    font-family: $lato;
    font-size: 12px;
    display: flex;
    padding-top: 12.95px;
    padding-left: 7.49px;
    .network-header {
      font-family: $lato;
      font-size: 14px;
      font-weight: 800;
      margin-left: 12.61px;
      width: 80%;
      .network-error {
        font-weight: normal;
        font-size: 12px;
        color: #5a5a5a;
        margin-top: 6px;
        width: 90%;
        height: 3.4rem;
        overflow-y: hidden;
      }
    }
    .error-icon {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 20px;
        width: 20px;
      }
    }
  }
  .messages-wrapper {
    background: $white;
    overflow-x: hidden;
    overflow-y: auto;
    height: 83vh;

    .message-seconed-header {
      padding: 10px 15px;
      font-size: 20px;
      font-weight: bold;
    }

    .single-message-small {
      height: 107.48px;
      @include sidebarSingleMessages;
      .top-message-cont {
        display: flex;
        .single-message-left {
          @include sideBarSingleMessageLeft;

          .profile-picture-wrapper {
            min-width: 35px;
            height: 35px;
            align-items: center;

            .profile-picture {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background-color: #f5f5f5;
              object-fit: cover;
              animation: skeleton-loading 1s linear infinite alternate;
            }
          }
          .sender-noti {
            display: flex;
            width: 90%;
            align-self: center;
            margin-top: -15px;
            .sender {
              @include sideBarSingleMessageSender;
            }
            .noti-wrapper {
              @include sideBarSingleMessageNotiWrapper;
              .noti {
                @include sideBarSingleMessageNoti;
              }
            }
          }
        }
      }

      .single-message-right {
        @include sideBarSingleMessageRight;
        .date-and-noti {
          @include sideBarSingleMessageDateAndNoti;
          .date {
            @include sideBarSingleMessageDate;
          }
        }
      }
      .message-and-sender {
        @include sideBarSingleMessageAndSender;

        .message {
          @include sideBarSingleMessage;
        }
      }
    }
    //medium
    .single-message-medium {
      height: 111.48px;
      @include sidebarSingleMessages-medium;
      .top-message-cont {
        display: flex;
        .single-message-left {
          @include sideBarSingleMessageLeft-medium;

          .profile-picture-wrapper {
            min-width: 35px;
            height: 35px;
            align-items: center;

            .profile-picture {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background-color: #f5f5f5;
              object-fit: cover;
              animation: skeleton-loading 1s linear infinite alternate;
            }
          }
          .sender-noti {
            display: flex;
            width: 90%;
            align-self: center;
            margin-top: -15px;
            .sender {
              @include sideBarSingleMessageSender-medium;
            }
            .noti-wrapper {
              @include sideBarSingleMessageNotiWrapper-medium;
              .noti {
                @include sideBarSingleMessageNoti-medium;
              }
            }
          }
        }
      }

      .single-message-right {
        @include sideBarSingleMessageRight-medium;
        .date-and-noti {
          @include sideBarSingleMessageDateAndNoti-medium;
          .date {
            @include sideBarSingleMessageDate-medium;
          }
        }
      }
      .message-and-sender {
        @include sideBarSingleMessageAndSender-medium;

        .message {
          @include sideBarSingleMessage-medium;
        }
      }
    }
    //large
    .single-message-large {
      height: 111.48px;
      @include sidebarSingleMessages-large;
      .top-message-cont {
        display: flex;
        .single-message-left {
          @include sideBarSingleMessageLeft-large;

          .profile-picture-wrapper {
            min-width: 35px;
            height: 35px;
            align-items: center;

            .profile-picture {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background-color: #f5f5f5;
              object-fit: cover;
              animation: skeleton-loading 1s linear infinite alternate;
            }
          }
          .sender-noti {
            display: flex;
            width: 90%;
            align-self: center;
            margin-top: -15px;
            .sender {
              @include sideBarSingleMessageSender-large;
            }
            .noti-wrapper {
              @include sideBarSingleMessageNotiWrapper-large;
              .noti {
                @include sideBarSingleMessageNoti-large;
              }
            }
          }
        }
      }

      .single-message-right {
        @include sideBarSingleMessageRight-large;
        .date-and-noti {
          @include sideBarSingleMessageDateAndNoti-large;
          .date {
            @include sideBarSingleMessageDate-large;
          }
        }
      }
      .message-and-sender {
        @include sideBarSingleMessageAndSender-large;

        .message {
          @include sideBarSingleMessage-large;
        }
      }
    }

    .single-message-selected-small {
      background: $primary-blue-dark;
      color: #fff;
      height: 107.48px;
      @include sidebarSingleMessages;
      .top-message-cont {
        display: flex;
        .single-message-left {
          @include sideBarSingleMessageLeft;

          .profile-picture-wrapper {
            min-width: 35px;
            height: 35px;
            align-items: center;

            .profile-picture {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background-color: $white;
              object-fit: cover;
            }
          }
          .sender-noti {
            display: flex;
            width: 90%;
            align-self: center;
            margin-top: -15px;
            .sender {
              @include sideBarSingleMessageSender-selected;
            }
            .noti-wrapper {
              @include sideBarSingleMessageNotiWrapper;
              .noti {
                @include sideBarSingleMessageNoti;
              }
            }
          }
        }
      }

      .single-message-right {
        @include sideBarSingleMessageRight;
        .date-and-noti {
          @include sideBarSingleMessageDateAndNoti;
          .date {
            @include sideBarSingleMessageDate;
          }
        }
      }
      .message-and-sender {
        @include sideBarSingleMessageAndSender;

        .message {
          @include sideBarSingleMessage-selected;
        }
      }
    }
    //medium
    .single-message-selected-medium {
      background: $primary-blue-dark;
      color: #fff;
      height: 107.48px;
      @include sidebarSingleMessages-medium;
      .top-message-cont {
        display: flex;
        .single-message-left {
          @include sideBarSingleMessageLeft-medium;

          .profile-picture-wrapper {
            min-width: 35px;
            height: 35px;
            align-items: center;

            .profile-picture {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background-color: $white;
              object-fit: cover;
            }
          }
          .sender-noti {
            display: flex;
            width: 90%;
            align-self: center;
            margin-top: -15px;
            .sender {
              @include sideBarSingleMessageSender-selected-medium;
            }
            .noti-wrapper {
              @include sideBarSingleMessageNotiWrapper-medium;
              .noti {
                @include sideBarSingleMessageNoti-medium;
              }
            }
          }
        }
      }

      .single-message-right {
        @include sideBarSingleMessageRight-medium;
        .date-and-noti {
          @include sideBarSingleMessageDateAndNoti-medium;
          .date {
            @include sideBarSingleMessageDate-medium;
          }
        }
      }
      .message-and-sender {
        @include sideBarSingleMessageAndSender-medium;

        .message {
          @include sideBarSingleMessage-selected-medium;
        }
      }
    }
    //large
    .single-message-selected-large {
      background: $primary-blue-dark;
      color: #fff;
      height: 107.48px;
      @include sidebarSingleMessages-large;
      .top-message-cont {
        display: flex;
        .single-message-left {
          @include sideBarSingleMessageLeft-large;

          .profile-picture-wrapper {
            min-width: 35px;
            height: 35px;
            align-items: center;

            .profile-picture {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background-color: $white;
              object-fit: cover;
            }
          }
          .sender-noti {
            display: flex;
            width: 90%;
            align-self: center;
            margin-top: -15px;
            .sender {
              @include sideBarSingleMessageSender-selected-large;
            }
            .noti-wrapper {
              @include sideBarSingleMessageNotiWrapper-large;
              .noti {
                @include sideBarSingleMessageNoti-large;
              }
            }
          }
        }
      }

      .single-message-right {
        @include sideBarSingleMessageRight-large;
        .date-and-noti {
          @include sideBarSingleMessageDateAndNoti-large;
          .date {
            @include sideBarSingleMessageDate-large;
          }
        }
      }
      .message-and-sender {
        @include sideBarSingleMessageAndSender-large;

        .message {
          @include sideBarSingleMessage-selected-large;
        }
      }
    }

    // background: greenyellow;
  }

  .typing-text {
    color: $primary-blue;
  }
}
.MuiList-root {
  background-color: $modal-background;
}

.panel-collapse {
  max-height: 300px;
  overflow: hidden;
  transition: max-height 1.5s ease;
  margin-top: 16px;
}
.panel-close {
  max-height: 0;
  transition: max-height 0.5s ease;
}
.photos-div {
  display: flex;
  width: 120px;
  height: 40px;
  background: $light-gray 0% 0% no-repeat padding-box;
  border-radius: 24px;
  opacity: 1;
  margin-left: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  margin-right: 16px;
}
.photos-text {
  text-align: left;
  font: normal normal normal 14px "Roboto";
  letter-spacing: 0px;
  color: $text-black;
  opacity: 1;
}
.attachments-div {
  display: flex;
  width: 166px;
  height: 40px;
  background: $light-gray 0% 0% no-repeat padding-box;
  border-radius: 24px;
  opacity: 1;
  margin-left: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  margin-right: 16px;
}
::-webkit-scrollbar-track {
  background: $editor-background;
}
.border-bottom {
  height: 1px;
  background-color: $bottom-border-background;
  margin-left: 20px;
}
.tool-tip-pre {
  border-radius: 50%;
  margin: auto;
  text-align: center;
  display: table;
  min-width: 18px;
  min-height: 18px;
  font-size: 12px;
  position: relative;
  img {
    width: 18px;
    height: 18px;
  }
  .tooltiptext {
    visibility: hidden;
    width: 130px;
    background-color: $primary-blue;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 130%;
    left: -100%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 65%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $primary-blue transparent transparent transparent;
  }
}
.subject-text-small {
  color: $black;
  font-family: $lato;
  font-size: 12px;
  font-weight: 600;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 47px;
}
//medium
.subject-text-medium {
  color: $black;
  font-family: $lato;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 47px;
}
//large
.subject-text-large {
  color: $black;
  font-family: $lato;
  font-size: 16px;
  font-weight: 600;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 47px;
}

.tool-tip-pre:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
.time-span-style {
  height: 18px;
}
.messages-container-small {
  height: 2.1rem;
  overflow: hidden;
  margin-left: 47px;
  margin-top: -22px;
}
//medium
.messages-container-medium {
  height: 2.4rem;
  overflow: hidden;
  margin-left: 47px;
  margin-top: -22px;
}
//large
.messages-container-large {
  height: 2.9rem;
  overflow: hidden;
  margin-left: 47px;
  margin-top: -22px;
}
.name-bold {
  font-weight: 500;
  width: max-content;
}

.pre-message-small {
  overflow: hidden !important;
  text-overflow: ellipsis;
  color: $text-color;
  font-family: $lato;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
}
//medium
.pre-message-medium {
  overflow: hidden !important;
  text-overflow: ellipsis;
  color: $text-color;
  font-family: $lato;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}
//large
.pre-message-large {
  overflow: hidden !important;
  text-overflow: ellipsis;
  color: $text-color;
  font-family: $lato;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
}

.attachment-style {
  display: flex;
  align-self: center;
  color: $text-color;
  font-family: $lato;
  font-size: 12px;
  font-weight: 400;

  .att-img {
    width: 13px;
    margin-right: 8px;
    align-self: center;
  }
}
.colon {
  padding-right: 5px;
}
.email-profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $primary-blue;
  object-fit: cover;
  text-align: center;
  align-self: center;
  color: $white;
  font-size: 22px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: "Roboto";
}

@media only screen and (max-width: 600px) {
  .__SidebarWrapper {
    width: 100%;
    margin-top: -10px;
    max-width: 100%;
    min-width: 100%;
    .floating-btn {
      position: absolute;
      font-size: 12px;
      font-weight: 500;
      color: $white;
      font-family: $lato;
      height: 44.42px;
      width: 126px;
      max-width: 126px;
      min-width: 126px;
      background-color: $primary-blue-dark;
      display: flex;
      align-items: center;
      right: 31px;
      bottom: 34.58px;
      border-radius: 5px;
      img {
        height: 41px;
        width: 31px;
        margin-left: 11px;
        margin-right: 5px;
      }
    }
    .left-option-bar {
      display: none;
    }
    .right-option-bar {
      width: 100%;
    }

    .top-navbar-small {
      background-color: $primary-blue-dark;
      height: 110px;
      margin-top: -2px;
      width: 100%;
      .top-div {
        margin-top: 0;

        .more-icon {
          display: none;
        }
        .icons {
          width: 100%;
          height: max-content;
          padding-top: 16px;
          .new-chat-btn {
            display: none;
          }
          .right-search-wrapper {
            display: none;
          }
          .mobile-topnavbar {
            display: flex;
            width: 100%;
            justify-content: space-between;
            height: max-content;
            align-items: center;
            .header-bixspace {
              color: $white;
              font-family: $lato;
              font-size: 20px;
              font-weight: 800;
              margin-left: 14px;
              line-height: 20px;
              height: max-content;
              span {
                font-weight: 400;
              }
            }
            .icon-cont {
              display: flex;
              .close {
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  height: 15px;
                  width: 15px;
                }
              }
              .search-content {
                .search-container {
                  position: relative;
                  height: 20px;
                  width: 20px;
                }

                .mglass {
                  display: inline-block;
                  pointer-events: none;
                  img {
                    height: 20px;
                    width: 20px;
                  }
                }

                .searchbutton {
                  position: absolute;
                  font-size: 20px;
                  width: 100%;
                  margin-top: -8px;
                }

                .search:focus + .searchbutton {
                  transition-duration: 0.4s;
                  -moz-transition-duration: 0.4s;
                  -webkit-transition-duration: 0.4s;
                  -o-transition-duration: 0.4s;
                  color: white;
                }

                .search {
                  position: absolute;
                  background-color: $primary-blue-dark;
                  outline: none;
                  border: none;
                  padding: 0;
                  width: 0;
                  height: 100%;
                  z-index: 10;
                  transition-duration: 0.4s;
                  -moz-transition-duration: 0.4s;
                  -webkit-transition-duration: 0.4s;
                  -o-transition-duration: 0.4s;
                  color: $white;
                }
                .search::placeholder {
                  color: $white;
                }

                .search:focus {
                  width: 330px; /* Bar width+1px */
                }

                .expandright {
                  left: auto;
                  right: 0px; /* Button width-1px */
                }

                .expandright:focus {
                  padding: 14px;
                  padding-left: 20px;
                }
                .search-ic {
                  height: 20px;
                  width: 20px;
                  margin-right: 22px;
                  color: $white;
                }
              }
              .more-ic {
                height: 20px;
                width: 20px;
                margin-left: 18px;
                margin-right: 17px;
              }
            }
          }
        }
      }
      .saved-other-wrapper {
        color: $white;
        font-size: 14px;
        font-family: $lato;
        justify-content: space-between;
        margin-top: 38px;
        .filter {
          height: 14px;
          width: 14px;
          margin-right: 25px;
          // filter: brightness(0) invert(1);
          margin-top: -10px;
          cursor: pointer;
        }
        .drawer {
          width: 20px;
          height: 19.96px;
          display: block;
          margin-left: 5px;
          .popOver {
            position: absolute;
            background-color: $modal-background;
            border-radius: 8px;
            padding-top: 12px;
            padding-bottom: 5px;
            width: 200px;
            font-family: "roboto";
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            color: $text-black;
            top: 100px;
            left: 10px;
            z-index: 1;
            .selected {
              display: flex;
              background-color: $white;
              padding: 5px 7px;
              border-radius: 4px;
              cursor: pointer;
              width: 190px;
              margin-bottom: -15px;
            }
            .unSelected {
              display: flex;
              cursor: pointer;
              padding: 5px 7px;
              margin-bottom: -15px;
            }
            .img-cont {
              padding-left: 5px;
              padding-bottom: 10px;
              display: flex;
              padding-right: 8px;
              margin-bottom: 10px;
              width: 100%;

              .profile-img2 {
                height: 33px;
                width: 33px;
                border-radius: 50%;
                object-fit: cover;
                border: 1px solid $sub-topic-gray;
                padding: 2px;
              }
              .data {
                display: block;
                margin-left: 10px;
                max-width: 140px;
                width: 140px;

                .name {
                  font-family: "Roboto";
                  font-size: 13px;
                  color: $text-black;
                  font-weight: 500;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 100px;
                }
                .email {
                  font-family: "Roboto";
                  font-size: 10px;
                  color: $text-black;
                  margin-top: -5px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 120px;
                }
              }
            }
          }
          .sections {
            display: flex;
            font-size: 13px;
            margin-bottom: 8px;
            padding-left: 20px;
            cursor: pointer;
            color: $text-black;
            font-weight: 500;
            align-self: center;
            border-top: 0.5px solid #707070;
            padding-top: 10px;
            .img-sec {
              height: 20px;
              align-self: center;
              margin-right: 18px;
              width: 20px;
              filter: invert(100%);
            }
          }
        }
        .tab-div {
          width: 30%;
          .border-btm {
            border-bottom: 3px solid $white;
          }
          .tab-view {
            justify-content: center;
            .saved {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 16px;
              font-weight: 400;
              line-height: 13px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            //medium
            .saved-medium {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 18px;
              font-weight: 400;
              line-height: 15px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            //large
            .saved-large {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 20px;
              font-weight: 400;
              line-height: 17px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            .other {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 16px;
              font-weight: 400;
              line-height: 13px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            //medium
            .other-medium {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 18px;
              font-weight: 400;
              line-height: 15px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            //large
            .other-large {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 20px;
              font-weight: 400;
              line-height: 17px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
          }
        }
      }
    }
    .top-navbar-medium {
      background-color: $primary-blue-dark;
      height: 110px;
      margin-top: -2px;
      width: 100%;
      .top-div {
        margin-top: 0;

        .more-icon {
          display: none;
        }
        .icons {
          width: 100%;
          height: max-content;
          padding-top: 16px;
          .new-chat-btn {
            display: none;
          }
          .right-search-wrapper {
            display: none;
          }
          .mobile-topnavbar {
            display: flex;
            width: 100%;
            justify-content: space-between;
            height: max-content;
            align-items: center;
            .header-bixspace {
              color: $white;
              font-family: $lato;
              font-size: 20px;
              font-weight: 800;
              margin-left: 14px;
              line-height: 20px;
              height: max-content;
              span {
                font-weight: 400;
              }
            }
            .icon-cont {
              display: flex;
              .close {
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  height: 15px;
                  width: 15px;
                }
              }
              .search-content {
                .search-container {
                  position: relative;
                  height: 20px;
                  width: 20px;
                }

                .mglass {
                  display: inline-block;
                  pointer-events: none;
                  img {
                    height: 20px;
                    width: 20px;
                  }
                }

                .searchbutton {
                  position: absolute;
                  font-size: 20px;
                  width: 100%;
                  margin-top: -8px;
                }

                .search:focus + .searchbutton {
                  transition-duration: 0.4s;
                  -moz-transition-duration: 0.4s;
                  -webkit-transition-duration: 0.4s;
                  -o-transition-duration: 0.4s;
                  color: white;
                }

                .search {
                  position: absolute;
                  background-color: $primary-blue-dark;
                  outline: none;
                  border: none;
                  padding: 0;
                  width: 0;
                  height: 100%;
                  z-index: 10;
                  transition-duration: 0.4s;
                  -moz-transition-duration: 0.4s;
                  -webkit-transition-duration: 0.4s;
                  -o-transition-duration: 0.4s;
                  color: $white;
                }
                .search::placeholder {
                  color: $white;
                }

                .search:focus {
                  width: 330px; /* Bar width+1px */
                }

                .expandright {
                  left: auto;
                  right: 0px; /* Button width-1px */
                }

                .expandright:focus {
                  padding: 14px;
                  padding-left: 20px;
                }
                .search-ic {
                  height: 20px;
                  width: 20px;
                  margin-right: 22px;
                  color: $white;
                }
              }
              .more-ic {
                height: 20px;
                width: 20px;
                margin-left: 18px;
                margin-right: 17px;
              }
            }
          }
        }
      }
      .saved-other-wrapper {
        color: $white;
        font-size: 14px;
        font-family: $lato;
        justify-content: space-between;
        margin-top: 38px;
        .filter {
          height: 14px;
          width: 14px;
          margin-right: 25px;
          // filter: brightness(0) invert(1);
          margin-top: -10px;
          cursor: pointer;
        }
        .drawer {
          width: 20px;
          height: 19.96px;
          display: block;
          margin-left: 5px;
          .popOver {
            position: absolute;
            background-color: $modal-background;
            border-radius: 8px;
            padding-top: 12px;
            padding-bottom: 5px;
            width: 200px;
            font-family: "roboto";
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            color: $text-black;
            top: 100px;
            left: 10px;
            z-index: 1;
            .selected {
              display: flex;
              background-color: $white;
              padding: 5px 7px;
              border-radius: 4px;
              cursor: pointer;
              width: 190px;
              margin-bottom: -15px;
            }
            .unSelected {
              display: flex;
              cursor: pointer;
              padding: 5px 7px;
              margin-bottom: -15px;
            }
            .img-cont {
              padding-left: 5px;
              padding-bottom: 10px;
              display: flex;
              padding-right: 8px;
              margin-bottom: 10px;
              width: 100%;

              .profile-img2 {
                height: 33px;
                width: 33px;
                border-radius: 50%;
                object-fit: cover;
                border: 1px solid $sub-topic-gray;
                padding: 2px;
              }
              .data {
                display: block;
                margin-left: 10px;
                max-width: 140px;
                width: 140px;

                .name {
                  font-family: "Roboto";
                  font-size: 13px;
                  color: $text-black;
                  font-weight: 500;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 100px;
                }
                .email {
                  font-family: "Roboto";
                  font-size: 10px;
                  color: $text-black;
                  margin-top: -5px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 120px;
                }
              }
            }
          }
          .sections {
            display: flex;
            font-size: 13px;
            margin-bottom: 8px;
            padding-left: 20px;
            cursor: pointer;
            color: $text-black;
            font-weight: 500;
            align-self: center;
            border-top: 0.5px solid #707070;
            padding-top: 10px;
            .img-sec {
              height: 20px;
              align-self: center;
              margin-right: 18px;
              width: 20px;
              filter: invert(100%);
            }
          }
        }
        .tab-div {
          width: 30%;
          .border-btm {
            border-bottom: 3px solid $white;
          }
          .tab-view {
            justify-content: center;
            .saved {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 16px;
              font-weight: 400;
              line-height: 13px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            //medium
            .saved-medium {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 18px;
              font-weight: 400;
              line-height: 15px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            //large
            .saved-large {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 20px;
              font-weight: 400;
              line-height: 17px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            .other {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 16px;
              font-weight: 400;
              line-height: 13px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            //medium
            .other-medium {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 18px;
              font-weight: 400;
              line-height: 15px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            //large
            .other-large {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 20px;
              font-weight: 400;
              line-height: 17px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
          }
        }
      }
    }
    .top-navbar-large {
      background-color: $primary-blue-dark;
      height: 110px;
      margin-top: -2px;
      width: 100%;
      .top-div {
        margin-top: 0;

        .more-icon {
          display: none;
        }
        .icons {
          width: 100%;
          height: max-content;
          padding-top: 16px;
          .new-chat-btn {
            display: none;
          }
          .right-search-wrapper {
            display: none;
          }
          .mobile-topnavbar {
            display: flex;
            width: 100%;
            justify-content: space-between;
            height: max-content;
            align-items: center;
            .header-bixspace {
              color: $white;
              font-family: $lato;
              font-size: 20px;
              font-weight: 800;
              margin-left: 14px;
              line-height: 20px;
              height: max-content;
              span {
                font-weight: 400;
              }
            }
            .icon-cont {
              display: flex;
              .close {
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  height: 15px;
                  width: 15px;
                }
              }
              .search-content {
                .search-container {
                  position: relative;
                  height: 20px;
                  width: 20px;
                }

                .mglass {
                  display: inline-block;
                  pointer-events: none;
                  img {
                    height: 20px;
                    width: 20px;
                  }
                }

                .searchbutton {
                  position: absolute;
                  font-size: 20px;
                  width: 100%;
                  margin-top: -8px;
                }

                .search:focus + .searchbutton {
                  transition-duration: 0.4s;
                  -moz-transition-duration: 0.4s;
                  -webkit-transition-duration: 0.4s;
                  -o-transition-duration: 0.4s;
                  color: white;
                }

                .search {
                  position: absolute;
                  background-color: $primary-blue-dark;
                  outline: none;
                  border: none;
                  padding: 0;
                  width: 0;
                  height: 100%;
                  z-index: 10;
                  transition-duration: 0.4s;
                  -moz-transition-duration: 0.4s;
                  -webkit-transition-duration: 0.4s;
                  -o-transition-duration: 0.4s;
                  color: $white;
                }
                .search::placeholder {
                  color: $white;
                }

                .search:focus {
                  width: 330px; /* Bar width+1px */
                }

                .expandright {
                  left: auto;
                  right: 0px; /* Button width-1px */
                }

                .expandright:focus {
                  padding: 14px;
                  padding-left: 20px;
                }
                .search-ic {
                  height: 20px;
                  width: 20px;
                  margin-right: 22px;
                  color: $white;
                }
              }
              .more-ic {
                height: 20px;
                width: 20px;
                margin-left: 18px;
                margin-right: 17px;
              }
            }
          }
        }
      }
      .saved-other-wrapper {
        color: $white;
        font-size: 14px;
        font-family: $lato;
        justify-content: space-between;
        margin-top: 38px;
        .filter {
          height: 14px;
          width: 14px;
          margin-right: 25px;
          // filter: brightness(0) invert(1);
          margin-top: -10px;
          cursor: pointer;
        }
        .drawer {
          width: 20px;
          height: 19.96px;
          display: block;
          margin-left: 5px;
          .popOver {
            position: absolute;
            background-color: $modal-background;
            border-radius: 8px;
            padding-top: 12px;
            padding-bottom: 5px;
            width: 200px;
            font-family: "roboto";
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            color: $text-black;
            top: 100px;
            left: 10px;
            z-index: 1;
            .selected {
              display: flex;
              background-color: $white;
              padding: 5px 7px;
              border-radius: 4px;
              cursor: pointer;
              width: 190px;
              margin-bottom: -15px;
            }
            .unSelected {
              display: flex;
              cursor: pointer;
              padding: 5px 7px;
              margin-bottom: -15px;
            }
            .img-cont {
              padding-left: 5px;
              padding-bottom: 10px;
              display: flex;
              padding-right: 8px;
              margin-bottom: 10px;
              width: 100%;

              .profile-img2 {
                height: 33px;
                width: 33px;
                border-radius: 50%;
                object-fit: cover;
                border: 1px solid $sub-topic-gray;
                padding: 2px;
              }
              .data {
                display: block;
                margin-left: 10px;
                max-width: 140px;
                width: 140px;

                .name {
                  font-family: "Roboto";
                  font-size: 13px;
                  color: $text-black;
                  font-weight: 500;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 100px;
                }
                .email {
                  font-family: "Roboto";
                  font-size: 10px;
                  color: $text-black;
                  margin-top: -5px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 120px;
                }
              }
            }
          }
          .sections {
            display: flex;
            font-size: 13px;
            margin-bottom: 8px;
            padding-left: 20px;
            cursor: pointer;
            color: $text-black;
            font-weight: 500;
            align-self: center;
            border-top: 0.5px solid #707070;
            padding-top: 10px;
            .img-sec {
              height: 20px;
              align-self: center;
              margin-right: 18px;
              width: 20px;
              filter: invert(100%);
            }
          }
        }
        .tab-div {
          width: 30%;
          .border-btm {
            border-bottom: 3px solid $white;
          }
          .tab-view {
            justify-content: center;
            .saved {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 16px;
              font-weight: 400;
              line-height: 13px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            //medium
            .saved-medium {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 18px;
              font-weight: 400;
              line-height: 15px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            //large
            .saved-large {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 20px;
              font-weight: 400;
              line-height: 17px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            .other {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 16px;
              font-weight: 400;
              line-height: 13px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            //medium
            .other-medium {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 18px;
              font-weight: 400;
              line-height: 15px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
            //large
            .other-large {
              width: 112px;
              text-align: center;
              justify-content: center;
              font-family: $lato;
              font-size: 20px;
              font-weight: 400;
              line-height: 17px;
              padding-bottom: 15px;
              padding-top: 5px;
            }
          }
        }
      }
    }
    .connection-status {
      height: 109.26px;
      padding-left: 14.1px;
      padding-top: 21.44px;
      .error-icon {
        height: 45px;
        width: 45px;
        img {
          height: 25px;
          width: 25px;
        }
      }
    }
    .messages-wrapper {
      padding-bottom: 60px;

      .single-message-small {
        height: 109.26px;
        padding-left: 14.01px;
        .top-message-cont {
          .single-message-left {
            padding-top: 21.44px;
            .profile-picture-wrapper {
              height: 45px;
              width: 45px;

              .profile-picture {
                height: 45px;
                width: 45px;
              }
            }
            .sender-noti {
              margin-top: -25px;
            }
          }
        }
        .message-and-sender {
          padding-left: 8px;
          margin-top: -20px;
        }
      }
      .single-message-selected-small {
        height: 109.26px;
        padding-left: 14.01px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .__SidebarWrapper {
    width: 40%;
    max-width: 40%;
    min-width: 40%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .__SidebarWrapper {
    width: 30%;
    max-width: 30%;
    min-width: 30%;
  }
}
// @media only screen and (max-width: 400px) {
//   .__SidebarWrapper {
//     .top-navbar-small {
//       .top-div {
//         .icons {
//           .mobile-topnavbar {
//             .icon-cont {
//               .search-content {
//                 .search:focus {
//                   width: 300px; /* Bar width+1px */
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

.single-contact-left {
  display: flex;
  width: 100%;
  padding-top: 10px;
  min-width: 75%;
  padding-left: 10px;
}
.single-contact-right {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  width: 250px;
  padding-right: 10px;
}

.contact-avatar-wrapper {
  min-width: 50px;
  height: 50px;
  align-items: center;
  margin-bottom: 10px;
}

.contact-details {
  padding-top: 5px;
  padding-left: 17px;
  width: 82%;
}

.contact-name {
  font-family: "Roboto";
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: $text-black;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120%;
}
.popOver-2 {
  position: absolute;
  background-color: $modal-background;
  border-radius: 3px;
  padding-top: 12px;
  padding-bottom: 0px;
  width: 180px;
  font-family: "roboto";
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  color: $text-black;
  top: 85px;
  padding-right: 12px;
  z-index: 1;
  margin-left: 10.5rem;
}
.sections-2 {
  display: flex;
  font-size: 14px;
  margin-bottom: 8px;
  padding-left: 10px;
  cursor: pointer;
  .img-sec {
    height: 20px;
    align-self: center;
    margin-right: 18px;
    width: 20px;
  }
}
.popOver-mobile {
  position: absolute;
  background-color: $modal-background;
  border-radius: 3px;
  padding-top: 12px;
  padding-bottom: 0px;
  width: 180px;
  font-family: "Lato";
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  color: $text-black;
  top: 40px;
  padding-right: 12px;
  z-index: 1;
  right: 20px;
}
.sections-mobile {
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 10px;
  cursor: pointer;
  .img-sec {
    height: 20px;
    align-self: center;
    margin-right: 18px;
    width: 20px;
  }
}

.saved-other-wrapper {
  display: flex;
  color: $primary-blue-dark;
  font-size: 12px;
  margin-top: 6px;
  width: 100%;
  justify-content: space-around;
  .filter {
    width: 12px;
    height: 12px;
    align-self: center;
    // margin-right: -35px;
    cursor: pointer;
  }
  .drawer {
    display: none;
  }
  .tab-div {
    width: 35%;

    .border-btm {
      border-bottom: 3px solid $primary-blue-dark;
    }
    .tab-view {
      justify-content: center;
      .saved {
        font-family: $lato;
        opacity: 1;
        display: flex;
        cursor: pointer;
        // width: 75px;
        justify-content: center;
        .number-block {
          margin-left: 8px;
          width: 25px;
          height: 14px;
          background-color: $primary-blue-light;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          padding: 0;
          font-size: 11px;
          font-family: $lato;
          font-weight: 800;
        }
      }
      .other {
        font-family: $lato;
        opacity: 1;
        display: flex;
        cursor: pointer;
        // width: 70px;
        justify-content: center;
        text-align: center;
        justify-self: center;
        .number-block {
          margin-left: 8px;
          width: 25px;
          height: 14px;
          background-color: $primary-blue-light;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          padding: 0;
          font-size: 11px;
          font-family: $lato;
          font-weight: 800;
        }
      }
    }
  }
}

.chat-dropdown {
  position: absolute;
  // align-self: right;
  margin-left: 38px;
  z-index: 0;
}

.arrow-dropdown {
  height: 11px;
  transform: rotate(90deg);
}

.arrow-dropdown :hover {
  color: $primary-blue;
}

.dropdown-div {
  display: flex;
  justify-content: right;
  flex: 1;
  padding-right: 2px;
  align-items: center;
}

.pop-up1 {
  background-color: $white;
  position: fixed;
  display: block;
  z-index: 1;
  font-size: 12px;
  padding: 8px;
  // border-bottom-left-radius: 5px;
  // border-bottom-right-radius: 5px;
  // border-top-left-radius: 5px;
  // border-top-right-radius: 5px;
  width: 140px;
  margin-top: 60px;
  margin-left: -115px;
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.16);
  .box-style1 {
    background-color: $white;
    height: 18px;
  }

  .item1 {
    display: flex;
    align-self: center;
    font-family: Roboto;
    color: $text-black;
    font-size: 14px;
    // margin-bottom: 3px;
    padding: 5px;
    cursor: pointer;

    .img1 {
      width: 17px;
      height: 17px;
      align-self: center;
      margin-right: 8px;
      margin-left: -2px;
    }
  }
  .item1:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.unread-btn-wrapper {
  height: 40px;
  padding-top: 5px;
  margin-left: 25%;
  display: flex;
  justify-items: center;
  align-items: center;
  margin-bottom: -10px;
  font-family: $lato;
  font-size: 11px;
}

.unread-btn {
  font-family: $lato;
  font-size: 11px;
  display: flex;
  width: 70px;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 10px;
  cursor: pointer;
  z-index: 10;
}

.unread-btn-unselected {
  border: 1px solid $dark-gray;
  border-radius: 20px;
  color: $dark-gray;
  width: fit-content;
  font-size: 11px;
}

.unread-btn-selected {
  font-family: $lato;
  color: $white;
  font-size: 11px;
  display: flex;

  justify-content: center;
  align-items: center;
  border: 1px solid $dark-gray;
  background-color: $dark-gray;
  border-radius: 20px;
  padding: 0px 10px 0px 10px;
  cursor: pointer;
  width: fit-content;
}

.empty-unread {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  font-family: $lato;
  font-size: 11px;
  color: $dark-gray;
  font-style: italic;
}

.label-div {
  // padding-left: 30px;
  justify-content: space-evenly;
  overflow-x: auto;
  overflow-y: hidden;
}

.label-div::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.label-wrapper {
  height: 40px;
  padding-top: 5px;
  padding-right: 20px;
  display: -webkit-box;
  justify-items: center;
  align-content: center;
  margin-bottom: -10px;
  margin-top: 7px;
}

.back-arrow {
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  min-width: 25px;
  transform: rotate(180deg);
  height: 16px;
  margin-top: 15.5px;
}

.forward-arrow {
  min-width: 25px;
  // position: absolute;
  height: 16px;
  z-index: 10;
  margin-top: 15.5px;
}

.back-arrow-div {
  width: 40px;
  background-color: white;
  box-shadow: 5px 1px 5px 3px rgba(255, 255, 255, 1);
  // box-shadow: 5px 1px 5px 3px rgb(255 255 255 1);

  z-index: 10;
}

.forward-arrow-div {
  width: 40px;
  background-color: white;
  box-shadow: -5px 1px 5px 3px rgba(255, 255, 255, 1);
}
