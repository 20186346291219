@import "../../../GlobalVariables/colors.scss";
@import "../../../GlobalVariables/font.scss";

.__MessageBoxWrapper {
  width: 56.5%;
  min-width: 56.5%;
  max-width: 56.5%;
  height: 96vh;
  overflow-x: hidden;
  background-color: $white;
  overflow-y: auto;
  position: relative;
  margin-top: 55px;
  z-index: 0;

  .cc-name {
    padding-bottom: 8px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #8e8b8b;
    display: flex;
  }
  a {
    color: #3799f4;
    font-family: "Lato";
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
  .navbar-wrapper-small {
    position: static;
    top: 0px;
    overflow: hidden;
    z-index: 3;
    padding-left: 18.43px;
    height: 45.78px;
    border-bottom: 1px solid $border-gray-line;
    background-color: white;
    .navbar-mess {
      height: 45.78px;
      display: flex;
      overflow: hidden;
      background-color: $white;
      cursor: pointer;
      .arrow-back {
        display: flex;
        width: 20px;
        display: none;
        align-self: center;
        margin-right: 20px;
        height: 20px;
        padding-top: 6px;
        img {
          height: 20px;
          width: 20px;
          filter: invert(49%) sepia(75%) saturate(1848%) hue-rotate(187deg)
            brightness(99%) contrast(93%);
          transform: rotate(-180deg);
          align-self: center;
        }
      }
      .profile {
        display: flex;
        cursor: pointer;
        width: 90%;

        .profile-pic-wrapper {
          position: relative;
          padding-top: 9px;
          .profile-picture {
            background-color: $white;
            border-radius: 50%;
            height: 30px;
            min-width: 30px;
            object-fit: cover;
          }
          .online-status {
            background-color: $online-green;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            z-index: 2;
            position: absolute;
            bottom: 5px;
            right: 1px;
            border: 1px solid $white;
          }
        }

        .name-and-org {
          margin-left: 16px;
          padding-top: 6px;

          .org-name {
            font-family: $lato;
            font-size: 14px;
            font-weight: 800;
            font-stretch: normal;
            color: $black;
            overflow: hidden !important;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .org {
            font-family: $lato;
            color: $black;
            font-size: 11px;
            font-weight: 400;
            margin-top: -4px;
            overflow: hidden !important;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .org-members {
            font-family: $lato;
            color: $black;
            font-size: 11px;
            font-weight: 400;
            margin-top: -2px;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
          }
        }
      }

      // .right-sidebar-toggler {
      //   min-width: 50px;
      //   height: 50px;
      //   margin: 2px 0 0 1px;
      //   background-color: $gray;
      //   border-radius: 50%;
      //   cursor: pointer;
      // }
    }
  }
  //medium
  .navbar-wrapper-medium {
    position: static;
    top: 0px;
    overflow: hidden;
    z-index: 3;
    padding-left: 18.43px;
    height: 45.78px;
    border-bottom: 1px solid $border-gray-line;
    background-color: white;
    .navbar-mess {
      height: 45.78px;
      display: flex;
      overflow: hidden;
      background-color: $white;
      cursor: pointer;
      .arrow-back {
        display: flex;
        width: 20px;
        display: none;
        align-self: center;
        margin-right: 20px;
        height: 20px;
        padding-top: 6px;
        img {
          height: 20px;
          width: 20px;
          filter: invert(49%) sepia(75%) saturate(1848%) hue-rotate(187deg)
            brightness(99%) contrast(93%);
          transform: rotate(-180deg);
          align-self: center;
        }
      }
      .profile {
        display: flex;
        cursor: pointer;
        width: 90%;

        .profile-pic-wrapper {
          position: relative;
          padding-top: 9px;
          .profile-picture {
            background-color: $white;
            border-radius: 50%;
            height: 30px;
            min-width: 30px;
            object-fit: cover;
          }
          .online-status {
            background-color: $online-green;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            z-index: 2;
            position: absolute;
            bottom: 5px;
            right: 1px;
            border: 1px solid $white;
          }
        }

        .name-and-org {
          margin-left: 16px;
          padding-top: 6px;

          .org-name {
            font-family: $lato;
            font-size: 14px;
            font-weight: 800;
            font-stretch: normal;
            color: $black;
            overflow: hidden !important;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .org {
            font-family: $lato;
            color: $black;
            font-size: 11px;
            font-weight: 400;
            margin-top: -4px;
            overflow: hidden !important;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .org-members {
            font-family: $lato;
            color: $black;
            font-size: 11px;
            font-weight: 400;
            margin-top: -2px;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
          }
        }
      }

      // .right-sidebar-toggler {
      //   min-width: 50px;
      //   height: 50px;
      //   margin: 2px 0 0 1px;
      //   background-color: $gray;
      //   border-radius: 50%;
      //   cursor: pointer;
      // }
    }
  }
  //large
  .navbar-wrapper-large {
    position: static;
    top: 0px;
    overflow: hidden;
    z-index: 3;
    padding-left: 18.43px;
    height: 53.78px;
    border-bottom: 1px solid $border-gray-line;
    background-color: white;
    .navbar-mess {
      height: 53.78px;
      display: flex;
      overflow: hidden;
      background-color: $white;
      cursor: pointer;
      .arrow-back {
        display: flex;
        width: 20px;
        display: none;
        align-self: center;
        margin-right: 20px;
        height: 20px;
        padding-top: 6px;
        img {
          height: 20px;
          width: 20px;
          filter: invert(49%) sepia(75%) saturate(1848%) hue-rotate(187deg)
            brightness(99%) contrast(93%);
          transform: rotate(-180deg);
          align-self: center;
        }
      }
      .profile {
        display: flex;
        cursor: pointer;
        width: 90%;

        .profile-pic-wrapper {
          position: relative;
          padding-top: 9px;
          .profile-picture {
            background-color: $white;
            border-radius: 50%;
            height: 30px;
            min-width: 30px;
            object-fit: cover;
          }
          .online-status {
            background-color: $online-green;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            z-index: 2;
            position: absolute;
            bottom: 5px;
            right: 1px;
            border: 1px solid $white;
          }
        }

        .name-and-org {
          margin-left: 16px;
          padding-top: 6px;

          .org-name {
            font-family: $lato;
            font-size: 18px;
            font-weight: 800;
            font-stretch: normal;
            color: $black;
            overflow: hidden !important;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .org {
            font-family: $lato;
            color: $black;
            font-size: 15px;
            font-weight: 400;
            margin-top: -4px;
            overflow: hidden !important;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .org-members {
            font-family: $lato;
            color: $black;
            font-size: 11px;
            font-weight: 400;
            margin-top: -2px;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
          }
        }
      }

      // .right-sidebar-toggler {
      //   min-width: 50px;
      //   height: 50px;
      //   margin: 2px 0 0 1px;
      //   background-color: $gray;
      //   border-radius: 50%;
      //   cursor: pointer;
      // }
    }
  }
  .attachment-navbar {
    width: 100%;
    height: 40.36px;
    border-bottom: 1px solid $border-gray-line;
    z-index: 1;
    position: sticky;
    background-color: $white;
    .attachmentnavbar-content {
      display: flex;
      height: 40.36px;
      color: #8e8b8b;
      font-family: $lato;
      font-size: 10px;
      font-weight: 600;
      padding-top: 17px;
      .content-item {
        padding-left: 31px;
        font-size: 10px;
        padding: 42px;
        margin-top: -53px;
      }
    }
  }
  .email-convertations-wrapper {
    display: flex;
    flex-direction: column;
    // position: fixed;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    max-height: 85%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $white;
    mask-image: linear-gradient(
      to top,
      black calc(100% - 58px),
      transparent 100%
    );
    .message-time {
      font-family: $lato;
      font-size: 9px;
      color: $primary-blue-dark;
      text-align: center;
      margin-bottom: 10px;
    }

    .new-message-label {
      background-color: #dcdcdc;
      margin-top: 10px;
      margin-bottom: 15px;
      height: 1px;
      display: flex;
      justify-content: center;

      .new-message {
        font-size: 11px;
        font-family: "Lato";
        font-weight: 600;
        color: #9d9d9d;
        background-color: $white;
        padding: 3px 8px;
        border-radius: 8px;
        align-self: center;
        text-align: center;
        align-items: center;
      }
    }
    @keyframes hideAnimation {
      to {
        visibility: hidden;
        width: 0;
        height: 0;
      }
    }
    .income-message-wrapper {
      background-color: $white;
      overflow-wrap: break-word;
      align-self: flex-start;
      font-size: 14px;
      max-width: 96%;
      position: relative;
      margin-left: 12px;

      .ql-editor {
        max-width: 700px;
        color: black;
        font-family: $lato;
        font-size: 12px;
        font-weight: 400;
      }
      .income-container {
        max-width: 98%;
        position: relative;
        min-width: 98%;
        display: block;
        padding-bottom: 10px;

        .down-arrow-div {
          width: 100%;
          height: 7px;
          align-self: center;
          display: flex;
          z-index: 1;
          justify-content: flex-end;
        }

        .message-details {
          font-size: 12px;
          width: 100%;
          height: max-content;
          background-color: rgb(183, 183, 203);
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
          font-weight: bold;
          padding-bottom: 10px;
          margin-bottom: 10px;
          padding-left: 10px;
          padding-top: 10px;
        }

        .close-details {
          position: absolute;
          right: 0;
          margin-right: 4%;
          width: 21px;
          cursor: pointer;
        }

        .details {
          width: 98%;
          font-weight: 750;
        }

        .details-name {
          width: 100%;
          font-weight: 400;
        }
        .popup-arrow {
          cursor: pointer;
          width: 14px;
          height: 14px;
          align-self: center;
          position: absolute;
          margin-right: 12px;
          margin-top: 12px;
          display: none;
          z-index: 1;
          float: right;
        }

        .reply-message {
          align-self: center;
          position: absolute;
          align-items: center;
          align-self: center;

          display: flex;

          .more-image-div {
            height: 22px;
            align-items: center;
            width: 22px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            margin-top: -5px;
          }
          .more-image {
            height: 17px;
            width: 17px;
            cursor: pointer;
            display: none;
            justify-content: center;
          }
        }
        .body {
          position: relative;
          .audio-styles {
            background-color: $incoming-message-gray;
            width: max-content;
            height: max-content;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 6px;
            margin-left: -10px;
          }
          .attachment-container-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            .attachement-container-1 {
              width: max-content;
              height: max-content;
              margin-left: 10px;
              border-radius: 5px;
              margin-bottom: 10px;
              border: 1px solid #dcdcdc;
              .attachment-image {
                height: 240px;
                width: auto;
                border-radius: 5px;
                object-fit: cover;
                max-width: 240px;
              }
              .file-styles {
                max-width: 300px;
                width: 300px;
                display: flex;
                padding: 6px;
                .file-type-container {
                  height: 45px;
                  width: 45px;
                  background-color: $white;
                  border-radius: 5px;
                  margin-right: 10px;
                  img {
                    height: 45px;
                    width: 45px;
                    border-radius: 5px;
                    object-fit: contain;
                  }
                }
                .file-text {
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 13px;
                  font-family: $lato;
                  max-width: 80%;
                  color: $black;
                  font-weight: 500;
                  padding-top: 3px;
                  .file-type-text {
                    font-weight: 400;
                    color: #5a5a5a;
                  }
                }
              }
              .name {
                position: absolute;
                background-color: rgba(255, 255, 255, 0.89);
                bottom: 0px;
                font-size: 10px;
                width: 30px;
                height: 30px;
                display: flex;
                flex: 1;
                overflow: hidden !important;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 5px;
                margin-bottom: 5px;
                border-radius: 4px;

                .right {
                  align-self: center;
                  margin-left: 3px;
                  font-family: "Roboto";
                  font-weight: 500;
                  align-items: center;
                  flex: 5;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  .img {
                    height: 10px;
                    width: 10px;
                    margin-right: 5px;
                    align-items: center;
                  }
                }
                .left {
                  flex: 1;
                  cursor: pointer;
                  padding-left: 8px;
                  padding-top: 6px;

                  .img {
                    height: 15px;
                    width: 15px;
                  }
                }
              }
              .more {
                position: absolute;
                background-color: rgba(0, 0, 0, 0.6);
                top: -1px;
                width: 120px;
                height: 61px;
                font-family: "Roboto";
                color: $white;
                font-size: 13px;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 119px;
              }
            }
          }
          .body-message-warpper {
            .pre-small {
              -webkit-overflow-scrolling: touch;
              color: $black;
              font-family: $lato;
              font-size: 12px;
              padding-left: 15px;
              margin-top: -5px;
            }
            //medium
            .pre-medium {
              -webkit-overflow-scrolling: touch;
              color: $black;
              font-family: $lato;
              font-size: 14px;
              padding-left: 15px;
              margin-top: -3px;
            }
            //large
            .pre-large {
              -webkit-overflow-scrolling: touch;
              color: $black;
              font-family: $lato;
              font-size: 16px;
              padding-left: 15px;
              margin-top: -3px;
            }
          }
        }
      }
      .border {
        border: 1px solid #000000;
        border-radius: 5px 5px 5px 5px;
        margin-top: 30px;
      }
      .reaction-div {
        display: flex;
        background-color: #f4f4f4;
        align-items: center;
        justify-content: center;
        border-radius: 22px;
        width: max-content;
        position: absolute;
        left: 38%;

        z-index: 3;
        .reaction-list {
          width: 140px;
          display: flex;
          cursor: pointer;
          padding: 5px 0px;
          .reaction-option {
            width: 100%;
            font-size: 15px;
            align-items: center;
            justify-content: center;
            text-align: center;
            cursor: pointer;
            .emoji:hover {
              background-color: #dddcdc;
              border-radius: 50%;
              width: 25px;
              height: 25px;
              padding-top: 2.1px;
              margin-left: 5px;
              padding: 2px 2px 2px 2px;
              position: relative;
            }
          }
        }
      }
      .header-cont {
        .top-co {
          width: 100%;
          padding-top: 15px;
          padding-left: 15px;
          .subject-without-border {
            font-size: 17px;
            font-weight: 500;
            color: black;
            padding-bottom: 25px;
          }

          .subject {
            font-weight: 500;
            font-size: 17px;
            padding-bottom: 10px;
            color: black;
          }
          .name-date-container {
            display: flex;
            .inline-sender-name {
              width: max-content;
            }

            .sender-name-main-small {
              color: black;
              font-family: $lato;
              font-size: 13px;
              font-weight: 800;
              margin-bottom: -6px;
              width: 75%;
              display: flex;
            }
            //medium
            .sender-name-main-medium {
              color: black;
              font-family: $lato;
              font-size: 15px;
              font-weight: 800;
              margin-bottom: -6px;
              width: 75%;
              display: flex;
            }
            //large
            .sender-name-main-large {
              color: black;
              font-family: $lato;
              font-size: 17px;
              font-weight: 800;
              margin-bottom: -6px;
              width: 75%;
              display: flex;
            }

            .main-sender-div {
              display: flex;
            }

            .time-small {
              display: flex;
              font-family: $lato;
              font-size: 9px;
              color: $primary-blue-dark;
              width: 52%;
              height: max-content;
              justify-content: flex-end;
              margin-right: 4.2px;
              align-self: center;
              align-items: center;
              .time-stamp {
                width: max-content;
                letter-spacing: 0.3px;
                font-family: $lato;
                text-align: center;
                margin-right: 10px;
                margin-top: -3px;
              }
              .reply-svg-div {
                cursor: pointer;

                .reply-btn {
                  width: 27.11px;
                  height: 27px;
                  margin-top: -4px;
                  padding: 6px 6px 6px 6px;
                }
                .reply-btn:hover {
                  background-color: #f2f1f1;
                  border-radius: 50%;
                }
              }

              .more-svg-div {
                cursor: pointer;
                margin-top: -3px;
                margin-right: 12px;
                margin-left: 5.75px;
                .more-icon {
                  width: 27.11px;
                  height: 27px;
                  margin-top: -0px;
                  padding: 8.5px 8.5px 8.5px 8.5px;
                }
                .more-icon:hover {
                  background-color: #f2f1f1;
                  border-radius: 50%;
                }
              }
            }
            //medium
            .time-medium {
              display: flex;
              font-family: $lato;
              font-size: 11px;
              color: $primary-blue-dark;
              width: 52%;
              height: max-content;
              justify-content: flex-end;
              margin-right: 4.2px;
              align-self: center;
              align-items: center;
              .time-stamp {
                width: max-content;
                letter-spacing: 0.3px;
                font-family: $lato;
                text-align: center;
                margin-right: 10px;
                margin-top: -3px;
              }
              .reply-svg-div {
                cursor: pointer;

                .reply-btn {
                  width: 27.11px;
                  height: 27px;
                  margin-top: -4px;
                  padding: 6px 6px 6px 6px;
                }
                .reply-btn:hover {
                  background-color: #f2f1f1;
                  border-radius: 50%;
                }
              }

              .more-svg-div {
                cursor: pointer;
                margin-top: -3px;
                margin-right: 12px;
                margin-left: 5.75px;
                .more-icon {
                  width: 27.11px;
                  height: 27px;
                  margin-top: -0px;
                  padding: 8.5px 8.5px 8.5px 8.5px;
                }
                .more-icon:hover {
                  background-color: #f2f1f1;
                  border-radius: 50%;
                }
              }
            }
            //large
            .time-large {
              display: flex;
              font-family: $lato;
              font-size: 13px;
              color: $primary-blue-dark;
              width: 52%;
              height: max-content;
              justify-content: flex-end;
              margin-right: 4.2px;
              align-self: center;
              align-items: center;
              .time-stamp {
                width: max-content;
                letter-spacing: 0.3px;
                font-family: $lato;
                text-align: center;
                margin-right: 10px;
                margin-top: -3px;
              }
              .reply-svg-div {
                cursor: pointer;

                .reply-btn {
                  width: 27.11px;
                  height: 27px;
                  margin-top: -4px;
                  padding: 6px 6px 6px 6px;
                }
                .reply-btn:hover {
                  background-color: #f2f1f1;
                  border-radius: 50%;
                }
              }

              .more-svg-div {
                cursor: pointer;
                margin-top: -3px;
                margin-right: 12px;
                margin-left: 5.75px;
                .more-icon {
                  width: 27.11px;
                  height: 27px;
                  margin-top: -0px;
                  padding: 8.5px 8.5px 8.5px 8.5px;
                }
                .more-icon:hover {
                  background-color: #f2f1f1;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }

      .message-box-footer {
        margin-right: 22px;
        .img {
          height: 12px;
          width: 12px;
        }
      }
    }

    .income-message-wrapper:hover .income-container .reply-message .more-image {
      display: block;
    }
    .income-message-wrapper:hover .income-container .reply-message .more-image {
      display: block;
    }

    .income-message-wrapper:hover .income-container .popup-arrow {
      display: block;
      cursor: pointer;
    }
  }
  @keyframes mymove {
    from {
      bottom: -50%;
    }
    to {
      bottom: 0%;
    }
  }
  .main-message-box-footer {
    position: fixed;
    // width: 56%;
    z-index: 1;
    width: inherit;
    padding-left: 12px;
    padding-right: 12px;

    .subject-input {
      width: 100%;
      height: 38px;
      margin-bottom: 4px;
      padding: 8px;
      border-radius: 16px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: $light-gray;
      display: flex;

      input {
        width: 100%;
        background: $light-gray;
        border: none;
        padding: 7px;
        align-self: center;
        font-family: "Roboto";
        font-size: 16px;
        font-weight: normal;
        outline: none;
      }
      input::placeholder {
        color: $dark-gray;
      }

      img {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
    }
    .attachement-container {
      position: relative;
    }
    .temp-attachement-container {
      position: relative;
    }
  }
  .type-div {
    bottom: 0%;
    position: fixed;
    background-color: aqua;
    margin-left: 13px;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    font-size: 14px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.5);
    font-family: $lato;
    padding: 10px 10px 10px 10px;
    color: #8e8b8b;
    cursor: pointer;
  }
  .body strong {
    color: $black;
  }
  .loader {
    border: 10px solid $loader-white;
    border-radius: 50%;
    border-top: 6px solid $loader-gray;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin: auto;
    position: relative;
  }
  .reply-message-box-main {
    width: 100%;
    max-height: 100%;
    margin-left: 45px;
    color: black;
    border-left: solid 1px #dcdcdc;
    // margin-top: 6px;
  }

  .reply-messages-inline {
    width: 100%;

    .reply-inline-message {
      .reply-head {
        display: flex;
        color: black;
        font-family: "Lato";
        font-size: 13px;
        font-weight: 800;
        padding-left: 10px;
        margin-bottom: -6px;
        height: 4vh;
        width: 94.5%;
        justify-content: space-between;
        .sender-name-inline-reply {
          color: black;
          font-family: "Lato";
          font-size: 13px;
          font-weight: 800;
          width: 60%;
        }

        .sender-time-inline-reply {
          display: flex;
          justify-content: flex-end;
          width: 40%;
          height: max-content;
          align-items: center;
          align-self: center;
          padding-top: 5px;
          .reply-time {
            font-weight: 400;
            font-family: "Lato";
            font-size: 9px;
            color: #0d2a4c;
            width: 122.83px;
            letter-spacing: 0.3px;
            text-align: center;
            margin-top: 4px;
          }
          .reply-icons-main {
            display: flex;
            .reply-btn-inline-div {
              .reply-btn-inline {
                width: 27.11px;
                height: 27px;
                margin-top: 0px;
                padding: 6px 6px 6px 6px;
              }
              .reply-btn-inline:hover {
                background-color: #f2f1f1;
                border-radius: 50%;
              }
            }

            .more-icon-div-inline {
              margin-top: 0px;
              margin-right: 14px;
              margin-left: 5.75px;
              .reply-more-icon-inline {
                width: 27.11px;
                height: 27px;
                margin-top: -0px;
                padding: 8.5px 8.5px 8.5px 8.5px;
                cursor: pointer;
              }
              .reply-more-icon-inline:hover {
                background-color: #f2f1f1;
                border-radius: 50%;
              }
            }
          }
        }

        .reply-inline-icon {
          width: 20.11px;
          height: 20px;
        }
      }
      .message-inline-reply {
        color: black;
        font-family: "Lato";
        font-size: 12px;
        padding-top: 0px;
        font-weight: 400;
        margin-top: 0.8%;
        padding-left: 10px;
        width: 93%;
        margin-top: -1%;

        .attachment-container-wrapper {
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;
          .attachement-container-1 {
            width: max-content;
            height: max-content;
            margin-left: 10px;
            border-radius: 5px;
            margin-bottom: 10px;
            border: 1px solid #dcdcdc;
            .attachment-image {
              height: 240px;
              width: auto;
              border-radius: 5px;
              object-fit: cover;
              max-width: 240px;
            }
            .file-styles {
              max-width: 300px;
              width: 300px;
              display: flex;
              padding: 6px;
              .file-type-container {
                height: 45px;
                width: 45px;
                background-color: $white;
                border-radius: 5px;
                margin-right: 10px;
                img {
                  height: 45px;
                  width: 45px;
                  border-radius: 5px;
                  object-fit: contain;
                }
              }
              .file-text {
                overflow: hidden !important;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 13px;
                font-family: $lato;
                max-width: 80%;
                color: $black;
                font-weight: 500;
                padding-top: 3px;
                .file-type-text {
                  font-weight: 400;
                  color: #5a5a5a;
                }
              }
            }
            .name {
              position: absolute;
              background-color: rgba(255, 255, 255, 0.89);
              bottom: 0px;
              font-size: 10px;
              width: 30px;
              height: 30px;
              display: flex;
              flex: 1;
              overflow: hidden !important;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 5px;
              margin-bottom: 5px;
              border-radius: 4px;

              .right {
                align-self: center;
                margin-left: 3px;
                font-family: "Roboto";
                font-weight: 500;
                align-items: center;
                flex: 5;
                overflow: hidden !important;
                text-overflow: ellipsis;
                white-space: nowrap;
                .img {
                  height: 10px;
                  width: 10px;
                  margin-right: 5px;
                  align-items: center;
                }
              }
              .left {
                flex: 1;
                cursor: pointer;
                padding-left: 8px;
                padding-top: 6px;

                .img {
                  height: 15px;
                  width: 15px;
                }
              }
            }
            .more {
              position: absolute;
              background-color: rgba(0, 0, 0, 0.6);
              top: -1px;
              width: 120px;
              height: 61px;
              font-family: "Roboto";
              color: $white;
              font-size: 13px;
              font-weight: 500;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 119px;
            }
          }
        }
      }
    }
  }

  .reply-count {
    z-index: 1;
    font-size: 12px;
    font-weight: 500;
    color: #3188ff;
    font-family: "Roboto";
    /* right: 0; */
    cursor: pointer;
    margin-left: 11px;
    margin-top: -1px;
  }

  .time-stamp-mob {
    display: none;
  }

  .time-stamp-mob-reply {
    display: none;
  }

  .reply-footer {
    height: 3vh;
    width: 100%;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .deleted-div {
    background-color: #0d2a4c;
    margin-left: -30px;
    margin-right: -32px;
    height: 113px;
    font-family: "roboto";
    font-size: 14px;
    color: #fff;
    text-align: center;
    align-self: center;
    font-style: italic;
    padding: 15px;
    position: absolute;
    z-index: 10;
    justify-content: center;
    align-items: center;
    margin-left: -12px;
    display: flex;
    height: 25vh;
  }
  .bottom-editor {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #dcdcdc;
    height: 50px;
    display: flex;
    align-self: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    background-color: white;
    width: 55.2%;
    margin-left: 10px;
    margin-right: 10px;
    .attach-container {
      display: flex;
      margin-left: 12px;

      .single-opt {
        .attach-touch {
          min-height: 30px;
          min-width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          .attach {
            height: 15px;
            width: 15px;
            cursor: pointer;
          }
        }
      }
    }

    .plus {
      height: 18px;
      width: 18px;
      margin-left: 10px;
      cursor: pointer;
      margin-right: 10px;
    }

    .plus-button {
      margin-top: -10px;
    }
    input {
      border: none;
      outline: none;
      width: 95%;
    }
    input::placeholder {
      color: #9d9d9d;
    }
  }
}
.link-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(250, 250, 250, 0.4);
  color: $black; /* Black w/ opacity */
}

/* Modal Content */
.link-modal-content {
  background-color: $link-modal-background;
  margin-right: 20%;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.4) !important;
  margin-left: 20%;
  flex-wrap: wrap;
  margin-top: 10%;
}

/* The Close Button */
.close {
  color: $slight-gray;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: $black;
  text-decoration: none;
  cursor: pointer;
}

.download-img {
  height: 22px;
  width: 22px;
  position: relative;
  visibility: hidden;
  margin-left: -30px;
}
.download-img-a {
  height: 10px;
  width: 10px;
  flex: 1;
}

.welcome-page {
  margin-top: 20%;
}
.tick-img {
  height: 15px;
  align-self: center;
  margin-left: 5px;
  margin-bottom: -5px;
}
.progress-bar {
  display: flex;
}

.prog-bar {
  width: 12px;
  height: 12px;
  margin-left: 3px;
}

.status {
  margin-right: -8px;
}

@media only screen and (max-width: 600px) {
  .__MessageBoxWrapper {
    width: 100%;
    margin-top: 5px;
    min-width: 100%;
    max-width: 100%;

    .navbar-wrapper-small {
      margin-left: 0;
      padding-left: 0;
      .navbar-mess {
        .arrow-back {
          height: 14px;
          width: 14px;
          margin-top: -25px;
          margin-left: 15px;
          img {
            height: 14px;
            width: 14px;
          }
        }
      }
    }

    .email-convertations-wrapper {
      width: 100% !important;
      .outgoing-message-wrapper {
        margin: 10px 10px 0px;
      }
    }

    .main-message-box-footer {
      position: fixed;
      bottom: 0%;
      width: 100%;
      margin-left: 0px;
      z-index: 1;
      padding-left: 0;
      padding-right: 0;
    }
    .bottom-editor {
      margin-left: 0;
      width: 100%;
      .attach-container {
        display: flex;
        margin-left: 12px;

        .single-opt {
          .attach-touch {
            min-height: 30px;
            min-width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            .attach {
              height: 15px;
              width: 15px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

// @media only screen and (min-width: 768px) {
// }

// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {
// }

/* Extra large devices (large laptops and desktops, 1200px and up) */

// @media screen and (min-width: 1441px) {
//   .main-message-box-footer {
//     max-width: 47%;
//     .deleted-div {
//       background-color: $bottom-border-background;
//       margin-left: -30px;
//       margin-right: -38px;
//     }
//   }
//   .email-convertations-wrapper {
//     max-width: 51%;
//   }
// }
// @media screen and (min-width: 1695px) {
//   .main-message-box-footer {
//     max-width: 40.5%;
//     .deleted-div {
//       background-color: $bottom-border-background;
//       margin-left: -30px;
//       margin-right: -40px;
//     }
//   }
//   .email-convertations-wrapper {
//     max-width: 44.2%;
//   }
// }
// @media screen and (min-width: 1921px) {
//   .main-message-box-footer {
//     max-width: 33.5%;
//     .deleted-div {
//       background-color: $bottom-border-background;
//       margin-left: -30px;
//       margin-right: -90px;
//     }
//   }
//   .email-convertations-wrapper {
//     max-width: 37.8%;
//   }
// }
.typing-message {
  color: $typing-text-gray;
  background: rgba($color: $selected-gray, $alpha: 0.2);
  width: fit-content;
  padding: 3px 8px;
  font-size: 12px;
  border-radius: 16px;
}
.typing-container {
  background-color: $white;
  width: fit-content;
  margin-left: 50px;
  border-radius: 16px;
  margin-bottom: 10px;
}

.grid-view {
  display: grid;
  grid-template-columns: auto auto;
}
.pop {
  background-color: $modal-background;
  position: absolute;
  z-index: 3;
  font-size: 12px;
  padding: 8px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  // margin-top: -30px;

  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.16);
  .box-style {
    background-color: $white;
    height: 18px;
    width: 9px;
    margin-top: -9px;
  }

  .item {
    display: flex;
    align-self: center;
    font-family: Roboto;
    color: $text-black;
    font-size: 12px;
    margin-bottom: 3px;
    padding: 3px;
    cursor: pointer;
    .img-2 {
      width: 13px;
      height: 13px;
      align-self: center;
      margin-right: 10px;
      margin-left: -2px;
    }
    .img {
      width: 17px;
      height: 17px;
      align-self: center;
      margin-right: 8px;
      margin-left: -2px;
    }
    .img-3 {
      width: 12px;
      height: 12px;
      align-self: center;
      margin-right: 10px;
    }
  }
  .item:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.reply-count {
  z-index: 1;
  font-size: 12px;
  font-weight: 500;
  color: #3188ff;
  font-family: "Roboto";
  /* right: 0; */
  cursor: pointer;
  margin-left: 11px;
  margin-top: -1px;
}

.cc-or-not {
  position: absolute;
  z-index: 1;
  background-color: $primary-blue;
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 500;
  color: $white;
  border-radius: 20px;
  font-family: "Roboto";
  right: 0;
  bottom: -15px;
  margin-top: 20px;
  cursor: pointer;
}

.reaction-count {
  text-align: center;
  width: 35px;
  height: 35px;
  z-index: 1;
  background-color: $incoming-message-gray;
  font-size: 12px;
  font-weight: 500;
  color: $primary-blue;
  border-radius: 50%;
  font-family: "Roboto";
  bottom: -15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: 2%;
  margin-top: 1%;
  .img {
    height: 15px;
    width: 15px;
    align-self: center;
    margin-right: 5px;
  }
}
.reply-container {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  overflow-y: hidden;
  .inner-reply {
    height: 80vh;
    position: absolute;
    left: 12px;
    bottom: 0;
    .top-tab {
      background-color: transparent;
      width: max-content;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      display: flex;
      position: relative;
      .rep-tab {
        background-color: $primary-blue;
        height: 37px;
        flex: 1;
        width: 7vh;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        z-index: 2;
        .img {
          height: 16px;
          width: 16px;
          align-self: center;
        }
      }
      .rep-tab-2 {
        background-color: $light-gray;
        flex: 1;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        height: 37px;
        justify-content: center;
        display: flex;
        width: 7vh;
        position: absolute;
        z-index: 2;
        border-right: 0.5px solid $bottom-border-gray;
        .img {
          height: 16px;
          width: 16px;
          align-self: center;
          filter: grayscale(100%) brightness(0%);
          -webkit-filter: grayscale(100%) brightness(0%);
          -moz-filter: grayscale(100%) brightness(0%);
        }
      }
      .att-tab {
        background-color: $primary-blue;
        height: 37px;
        flex: 1;
        width: 14vh;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        padding-right: 20px;
        position: absolute;

        .image {
          height: 18px;
          width: 18px;
          align-self: center;
          filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(138deg)
            brightness(112%) contrast(101%);
        }
      }
      .att-tab-2 {
        background-color: $light-gray;
        flex: 1;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        height: 37px;
        justify-content: flex-end;
        display: flex;
        width: 14vh;
        padding-right: 20px;
        position: absolute;
        border-right: 0.5px solid $bottom-border-gray;

        .image {
          height: 18px;
          width: 18px;
          align-self: center;
        }
      }
      .react-tab {
        background-color: $primary-blue;
        height: 37px;
        flex: 1;
        width: 21vh;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        padding-right: 20px;

        .image {
          height: 18px;
          width: 18px;
          align-self: center;
          filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(138deg)
            brightness(112%) contrast(101%);
        }
      }
      .react-tab-2 {
        background-color: $light-gray;
        flex: 1;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        height: 37px;
        justify-content: flex-end;
        display: flex;
        width: 21vh;
        padding-right: 20px;

        .image {
          height: 18px;
          width: 18px;
          align-self: center;
        }
      }
    }
    .span {
      background-color: $primary-blue;
      height: 20px;
      width: 20px;
      top: 20px;
    }
    .span-2 {
      background-color: $light-gray;
      height: 20px;
      width: 20px;
      top: 20px;
    }
    .inner-container {
      background-color: $white;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.534);
      border-radius: 3px;
      height: 69vh;
      display: block;
      margin-top: -20px;
      .header {
        border-bottom: $primary-blue 0.5px solid;
        padding: 15px 20px;
        flex: 1;
        display: flex;
        .right {
          flex: 4;
          width: 90%;
          .topic {
            color: $primary-blue;
            font-family: "Roboto";
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.04;
            letter-spacing: normal;
            text-align: left;
            .body_text {
              overflow: hidden !important;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: $text-black;
              font-weight: 400;
              font-size: 16px;

              .ql-editor {
                box-sizing: border-box;
                line-height: 1.42;
                height: 6vh;
                outline: none;
                overflow-y: hidden;
                padding: 12px 15px;
                tab-size: 4;
                -moz-tab-size: 4;
                text-align: left;
                white-space: pre-wrap;
                word-wrap: break-word;
              }
              .ql-editor ol,
              .ql-editor ul {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .pre {
                overflow: hidden !important;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
          .sub-topic {
            font-family: "Roboto";
            font-size: 12px;
            color: $sub-topic-gray;
            margin-top: 3px;
          }

          .cc-list {
            font-family: "Roboto";
            font-size: 12px;
            color: $sub-topic-gray;
            margin-top: 3px;
          }
          .to-list {
            font-family: "Roboto";
            font-size: 12px;
            color: $sub-topic-gray;
            margin-top: 3px;
          }
        }
        .left {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          .img {
            height: 15px;
            width: 15px;
            align-self: center;
            cursor: pointer;
          }
        }
      }
      .message-container {
        overflow-y: auto;
        height: 46vh;
        overflow-x: hidden;
        padding-bottom: 30px;
        .incoming-message-container {
          padding-top: 15px;
          position: relative;
          padding-bottom: 20px;

          .income-group {
            margin-bottom: -16px;
            font-weight: 500;
            color: $primary-blue;
            padding-top: 5px;
            position: relative;
            width: max-content;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            font-size: 13px;

            .ql-editor {
              height: 70vh;
            }
            .span-div {
              height: 16px;
              border-top-left-radius: 16px;
            }
            .group-memeber-name {
              padding-left: 10px;
              padding-right: 8px;
            }
          }

          .incoming-message {
            font-family: $lato;
            font-size: 12px;
            position: relative;
            padding-left: 10px;

            .attachment-container-wrapper {
              display: flex;
              flex-wrap: wrap;
              margin-top: 10px;
              .attachement-container-1 {
                width: max-content;
                height: max-content;
                margin-left: 18px;
                border-radius: 5px;
                border: 1px solid #dcdcdc;
                margin-bottom: 10px;
                .attachment-image {
                  height: 240px;
                  width: auto;
                  border-radius: 5px;
                  object-fit: cover;
                  max-width: 240px;
                }
                .file-styles {
                  max-width: 300px;
                  width: 300px;
                  display: flex;
                  padding: 6px;
                  .file-type-container {
                    height: 45px;
                    width: 45px;
                    background-color: $white;
                    border-radius: 5px;
                    margin-right: 10px;
                    img {
                      height: 45px;
                      width: 45px;
                      border-radius: 5px;
                      object-fit: contain;
                    }
                  }
                  .file-text {
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 13px;
                    font-family: $lato;
                    max-width: 80%;
                    color: $black;
                    font-weight: 500;
                    padding-top: 3px;
                    .file-type-text {
                      font-weight: 400;
                      color: #5a5a5a;
                    }
                  }
                }
                .name {
                  position: absolute;
                  background-color: rgba(255, 255, 255, 0.89);
                  bottom: 0px;
                  font-size: 10px;
                  width: 30px;
                  height: 30px;
                  display: flex;
                  flex: 1;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  margin-left: 5px;
                  margin-bottom: 5px;
                  border-radius: 4px;

                  .right {
                    align-self: center;
                    margin-left: 3px;
                    font-family: "Roboto";
                    font-weight: 500;
                    align-items: center;
                    flex: 5;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    .img {
                      height: 10px;
                      width: 10px;
                      margin-right: 5px;
                      align-items: center;
                    }
                  }
                  .left {
                    flex: 1;
                    cursor: pointer;
                    padding-left: 8px;
                    padding-top: 6px;

                    .img {
                      height: 15px;
                      width: 15px;
                    }
                  }
                }
                .more {
                  position: absolute;
                  background-color: rgba(0, 0, 0, 0.6);
                  top: -1px;
                  width: 120px;
                  height: 61px;
                  font-family: "Roboto";
                  color: $white;
                  font-size: 13px;
                  font-weight: 500;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 119px;
                }
              }
            }
            .subject {
              font-weight: bold;
              border-bottom: 0.5px solid $bottom-border-gray;
              margin-bottom: 5px;
              padding-left: 10px;
              padding-top: 10px;
            }

            .message-reply {
              color: black;
              font-family: $lato;
              font-size: 12px;
              padding-top: 10px;
              padding-left: 10px;
              width: 97.5%;
            }

            .ql-editor {
              box-sizing: border-box;
              line-height: 1.42;
              height: 100%;
              outline: none;
              overflow-y: auto;
              padding: 6px 11px;
              tab-size: 4;
              -moz-tab-size: 4;
              text-align: left;
              white-space: pre-wrap;
              word-wrap: break-word;
              font-size: 12px;
            }

            .reply-main-head {
              display: flex;
              .reply-name-sender {
                color: black;
                font-family: "Lato";
                font-size: 13px;
                font-weight: 800;
                padding-left: 10px;
                width: 50%;
              }

              .reply-time-messsages {
                margin-top: 2px;
                font-size: 10px;
                width: 50%;
                text-align: right;
                padding-right: 20px;
              }
            }

            .empty-message-attachment {
              display: grid;
              grid-template-columns: auto auto;
              .attachment-cont {
                margin-left: 10px;
                position: relative;
                .attachment {
                  height: 60px;
                  width: 120px;
                  object-fit: contain;
                }
                .name {
                  position: absolute;
                  background-color: rgba(255, 255, 255, 0.89);
                  top: 45px;
                  font-size: 10px;
                  width: 120px;
                  display: flex;
                  flex: 1;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                  white-space: nowrap;

                  .right {
                    align-self: center;
                    margin-left: 3px;
                    font-family: "Roboto";
                    font-weight: 500;
                    align-items: center;
                    flex: 5;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    .img {
                      height: 10px;
                      width: 10px;
                      margin-right: 5px;
                      align-items: center;
                    }
                  }
                  .left {
                    flex: 1;
                    cursor: pointer;
                    .img {
                      height: 10px;
                      width: 10px;
                    }
                  }
                }
                .more {
                  position: absolute;
                  background-color: rgba(0, 0, 0, 0.6);
                  top: 0px;
                  width: 120px;
                  height: 61px;
                  font-family: "Roboto";
                  color: $white;
                  font-size: 13px;
                  font-weight: 500;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
            .attachement-container {
              position: absolute;
              right: 0;
              margin-top: 4px;
              display: flex;
              .attachment-div {
                background-color: $incoming-message-gray;
                padding: 7px;
                border-radius: 10px;
                height: 74px;
                width: 134px;
                margin-left: 10px;
                .attachment {
                  height: 60px;
                  width: 120px;
                  object-fit: contain;
                }
                .name {
                  position: absolute;
                  background-color: rgba(255, 255, 255, 0.89);

                  font-size: 10px;
                  width: 120px;
                  display: flex;
                  flex: 1;
                  bottom: 7px;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                  white-space: nowrap;

                  .right {
                    align-self: center;
                    margin-left: 3px;
                    font-family: "Roboto";
                    font-weight: 500;
                    align-items: center;
                    flex: 5;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    .img {
                      height: 10px;
                      width: 10px;
                      margin-right: 5px;
                      align-items: center;
                    }
                  }
                  .left {
                    flex: 1;
                    cursor: pointer;
                    .img {
                      height: 10px;
                      width: 10px;
                    }
                  }
                }
                .more {
                  position: absolute;
                  background-color: rgba(0, 0, 0, 0.6);
                  top: 7px;
                  width: 120px;
                  height: 60px;
                  font-family: "Roboto";
                  color: $white;
                  font-size: 13px;
                  font-weight: 500;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
          .time-div {
            font-family: $lato;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $sub-topic-gray;
          }
        }
        .outgoing-message-container {
          padding: 10px 50px;
          .inner-out-going {
            display: flex;
            justify-content: flex-end;
            .outgoing-message {
              border-top-left-radius: 16px;
              border-top-right-radius: 16px;
              border-bottom-left-radius: 16px;
              background-color: $sending-blue;
              font-family: "Roboto";
              font-size: 12px;
              max-width: 350px;
              position: relative;
              overflow-wrap: break-word;
              .subject {
                font-weight: bold;
                border-bottom: $bottom-border-gray solid 1px;
                margin-bottom: 5px;
                padding-left: 10px;
                padding-top: 10px;
              }
              .empty-message-attachment {
                display: grid;
                grid-template-columns: auto auto;
                .attachment-cont {
                  margin-left: 10px;
                  position: relative;
                  .attachment {
                    height: 60px;
                    width: 120px;
                    object-fit: contain;
                  }
                  .name {
                    position: absolute;
                    background-color: rgba(255, 255, 255, 0.89);
                    bottom: 0;
                    font-size: 10px;
                    width: 120px;
                    display: flex;
                    flex: 1;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    .right {
                      align-self: center;
                      margin-left: 3px;
                      font-family: "Roboto";
                      font-weight: 500;
                      align-items: center;
                      flex: 5;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      .img {
                        height: 10px;
                        width: 10px;
                        margin-right: 5px;
                        align-items: center;
                      }
                    }
                    .left {
                      flex: 1;
                      cursor: pointer;
                      .img {
                        height: 10px;
                        width: 10px;
                      }
                    }
                  }
                  .more {
                    position: absolute;
                    background-color: rgba(0, 0, 0, 0.6);
                    top: 0px;
                    width: 120px;
                    height: 67px;
                    font-family: "Roboto";
                    color: $white;
                    font-size: 13px;
                    font-weight: 500;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }
              }
              .attachement-container {
                position: absolute;
                right: 0;
                margin-top: 4px;
                display: flex;
                .attachment-div {
                  background-color: $sending-blue;
                  padding: 7px;
                  border-radius: 10px;
                  height: 74px;
                  width: 134px;
                  margin-left: 10px;
                }
                .attachment {
                  height: 60px;
                  width: 120px;
                  object-fit: contain;
                }
                .name {
                  position: absolute;
                  background-color: rgba(255, 255, 255, 0.89);
                  bottom: 7px;
                  font-size: 10px;
                  width: 120px;
                  display: flex;
                  flex: 1;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  bottom: 0;

                  .right {
                    align-self: center;
                    margin-left: 3px;
                    font-family: "Roboto";
                    font-weight: 500;
                    align-items: center;
                    flex: 5;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    .img {
                      height: 10px;
                      width: 10px;
                      margin-right: 5px;
                      align-items: center;
                    }
                  }
                  .left {
                    flex: 1;
                    cursor: pointer;
                    .img {
                      height: 10px;
                      width: 10px;
                    }
                  }
                }
                .more {
                  position: absolute;
                  background-color: rgba(0, 0, 0, 0.6);
                  bottom: 7px;
                  width: 120px;
                  height: 60px;
                  font-family: "Roboto";
                  color: $white;
                  font-size: 13px;
                  font-weight: 500;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }

          .time-div {
            font-family: "Roboto";
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $sub-topic-gray;
            justify-content: flex-end;
            display: flex;
            align-self: center;
          }
        }
      }
      .reactions-container {
        overflow-y: auto;
        height: 70vh;
        .tab-view {
          display: flex;
          padding: 1% 1% 1% 20%;
          .tabs {
            font-size: 16px;
            color: $sub-topic-gray;
            display: flex;
            margin-left: 60px;
            height: 32px;
            padding: 3px;
            cursor: pointer;
            .react {
              margin-left: 10px;
            }
          }
        }

        .all-reaction {
          text-align: center;
          margin-left: 8px;
        }
        .reactions {
          padding-left: 63px;
          .reaction-list {
            display: flex;
            margin-top: 5px;
            border-bottom: 1px solid #7070702d;
            width: 91%;
            padding-bottom: 13px;
            .reaction-ic {
              position: relative;
              width: 8%;

              .profile-pic {
                height: 37px;
                width: 37px;
                border-radius: 50%;
                position: relative;
                margin-top: 14%;
              }
              .emoji {
                position: absolute;
                font-size: 13px;
                bottom: 3px;
                left: 35px;
                background-color: $white;
                border-radius: 50%;
                padding: 2px;
              }
            }

            .emoji-preview {
              display: flex;
              position: absolute;
              margin-left: 73%;
            }

            .name {
              width: 100%;
              display: flex;
              margin-left: 10px;
              font-size: 14px;
              font-family: "Roboto";
              margin-top: 10px;
              overflow: hidden !important;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding-top: 1%;
            }
          }
        }
      }
      .attchment-preview-container {
        overflow-y: auto;
        height: 81vh;
        background-color: rgb(241, 241, 241);
        .outgoing-attachment-conatiner {
          background-color: $incoming-message-gray;
          padding: 10px 50px;
          .inner-out-going {
            display: flex;
            justify-content: flex-end;
            .outgoing-div {
              background-color: $sending-blue;
              max-width: 525px;
              width: max-content;
              padding: 10px;
              border-bottom-left-radius: 16px;
              border-top-right-radius: 16px;
              border-top-left-radius: 16px;
              font-size: 12px;
              font-family: "Roboto";
              .head {
                padding-bottom: 5px;
                border-bottom: 1px solid $bottom-border-gray;
                display: flex;
                flex: 1;
                .right {
                  flex: 3;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  .body_text {
                    .pre-small {
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }
                }
                .left {
                  flex: 1;
                  .img {
                    height: 14px;
                    width: 14px;
                    float: right;
                    filter: invert(46%) sepia(98%) saturate(969%)
                      hue-rotate(186deg) brightness(97%) contrast(97%);
                    cursor: pointer;
                  }
                }
              }
              .attachments {
                display: grid;
                grid-gap: 10px;
                margin-top: 5px;
                .att-div {
                  width: 150px;
                  height: 100px;
                  position: relative;
                  cursor: pointer;

                  .image {
                    width: 150px;
                    height: 100px;
                    margin-right: 10px;
                    object-fit: contain;
                    background-color: #f3f3f3;
                  }
                  .name {
                    position: absolute;
                    background-color: rgba(255, 255, 255, 0.89);
                    bottom: 0;
                    font-size: 10px;
                    width: 100%;
                    display: flex;
                    flex: 1;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    .right {
                      align-self: center;
                      margin-left: 3px;
                      font-family: "Roboto";
                      font-weight: 500;
                      align-items: center;
                      flex: 5;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      .img {
                        height: 10px;
                        width: 10px;
                        margin-right: 5px;
                        align-items: center;
                      }
                    }
                    .left {
                      flex: 1;
                      cursor: pointer;
                      .img {
                        height: 10px;
                        width: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .time-div {
          margin-top: 15px;
          right: 0;
          font-family: "Roboto";
          font-size: 10px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: $sub-topic-gray;
          display: flex;
          justify-content: flex-end;
        }

        .incoming-attachment-conatiner {
          background-color: $incoming-message-background;
          padding: 10px 50px;
          padding-bottom: 20px;
          .incoming-div {
            background-color: $incoming-message-gray;
            max-width: 525px;
            width: max-content;
            padding: 10px;
            border-bottom-right-radius: 16px;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
            font-size: 12px;
            font-family: "Roboto";
            position: relative;
            .head {
              padding-bottom: 5px;
              border-bottom: 1px solid $bottom-border-gray;
              display: flex;
              flex: 1;
              .right {
                flex: 3;
                overflow: hidden !important;
                text-overflow: ellipsis;
                white-space: nowrap;
                .body_text {
                  .pre-small {
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
              .left {
                flex: 1;
                .img {
                  height: 14px;
                  width: 14px;
                  float: right;
                  filter: invert(46%) sepia(98%) saturate(969%)
                    hue-rotate(186deg) brightness(97%) contrast(97%);
                  cursor: pointer;
                }
              }
            }
            .attachments {
              display: grid;
              margin-top: 5px;
              .att-div {
                width: 150px;
                height: 100px;
                margin-right: 10px;
                margin-bottom: 10px;
                position: relative;
                cursor: pointer;
                .image {
                  width: 150px;
                  height: 100px;
                  margin-right: 10px;
                  object-fit: contain;
                }
                .name {
                  position: absolute;
                  background-color: rgba(255, 255, 255, 0.89);
                  bottom: 0;
                  font-size: 10px;
                  width: 100%;
                  display: flex;
                  flex: 1;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                  white-space: nowrap;

                  .right {
                    align-self: center;
                    margin-left: 3px;
                    font-family: "Roboto";
                    font-weight: 500;
                    align-items: center;
                    flex: 5;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    .img {
                      height: 10px;
                      width: 10px;
                      margin-right: 5px;
                      align-items: center;
                    }
                  }
                  .left {
                    flex: 1;
                    cursor: pointer;
                    .img {
                      height: 10px;
                      width: 10px;
                    }
                  }
                }
              }
            }
            .time-div {
              position: absolute;
              margin-top: 15px;
              right: 0;
              font-family: "Roboto";
              font-size: 10px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: $sub-topic-gray;
            }
          }
        }
      }
    }
  }
}
.attachment {
  height: 100%;
  border-radius: 5px;
  background-color: $attachment-color;
  justify-content: center;
  display: flex;
  .img-mic {
    height: 30px;
    width: 30px;
    align-self: center;
  }
}
.attachment3 {
  height: 60px;
  width: 120px;
  border-radius: 5px;
  background-color: $attachment-color;
  justify-content: center;
  margin-top: 1px;
  margin-bottom: 4px;
  display: flex;
  .img-mic {
    height: 30px;
    width: 30px;
    align-self: center;
  }
}
.blink {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.4;
  }
}
.italics {
  font-style: italic;
}
.email-profile-picture-dp {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ff2c2cab;
  object-fit: cover;
  text-align: center;
  align-self: center;
  color: $white;
  font-size: 13px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: $lato;
  font-weight: 500;
  text-transform: uppercase;
}

.offline-Status {
  font-size: 16px;
  text-align: center;
}
audio {
  color: red;
  .media-controls-container,
  .media-controls-container * {
    --inline-controls-inside-margin: 6px;
    --fullscreen-controls-bar-height: 75px;
    --primary-glyph-color: rgba(255, 255, 255, 0.75);
  }
}

.linkMsgBoxReply {
  overflow: hidden !important;
  word-wrap: break-word;
  position: relative;
  font-family: "roboto";
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 11px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;

  .emailText {
    color: $primary-blue;
    font-family: "roboto";
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    padding: 10px;
  }

  span {
    font-style: inherit;
    font-weight: inherit;
    color: $text-black;
  }

  .copiedTooltip {
    background: gray;
    height: 30px;
    width: 120px;
    padding: 5px;
    border-radius: 5px;
  }

  .linkBorder {
    margin-top: 10px;
    display: flex;

    .borderLine {
      height: auto;
      width: 3px;
      border-radius: 6px;
      margin-right: 4px;
      background-color: $primary-blue;
    }

    .linkPreviewComponent {
      flex-direction: column-reverse !important;
      background-color: transparent !important;
      border-color: transparent !important;

      .Image {
        border-radius: 8px;
      }

      .LowerContainer {
        padding: 0 !important;
        padding-bottom: 10px !important;
        .Title {
          color: $primary-blue !important;
          margin-bottom: 0;
        }
        .Secondary {
          margin-top: 0 !important;
        }
      }
    }
  }
}

.linkMsgBox {
  overflow: hidden !important;
  position: relative;
  font-family: $lato;
  padding-left: 15px;
  padding-right: 10px;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  .emailText {
    color: $primary-blue;
    font-family: "roboto";
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    padding: 10px;
  }

  span {
    font-style: inherit;
    font-weight: inherit;
    color: $text-black;
  }

  .copiedTooltip {
    background: gray;
    height: 30px;
    width: 120px;
    padding: 5px;
    border-radius: 5px;
  }

  .linkBorder {
    margin-top: 10px;
    display: flex;

    .borderLine {
      height: auto;
      width: 3px;
      border-radius: 6px;
      margin-right: 4px;
      background-color: $primary-blue;
    }

    .linkPreviewComponent {
      flex-direction: column-reverse !important;
      background-color: transparent !important;
      border-color: transparent !important;

      .Image {
        border-radius: 8px;
      }

      .LowerContainer {
        padding: 0 !important;
        padding-bottom: 10px !important;
        .Title {
          color: $primary-blue !important;
          margin-bottom: 0;
        }
        .Secondary {
          margin-top: 0 !important;
        }
      }
    }
  }
}
.copied {
  background-color: rgba($color: $selected-gray, $alpha: 0.2);
  font-family: "roboto";
  font-size: 11px;
  width: max-content;
  padding: 3px 10px;
  border-radius: 10px;
  position: absolute;
  top: 15%;
  animation: fadeOut ease 2s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .__MessageBoxWrapper {
    .navbar-wrapper-small {
      position: fixed;
      width: 100%;
      .navbar-mess {
        .arrow-back {
          display: block;
        }
        .profile {
          .name-and-org {
            margin-left: 10px;
            width: 80%;
          }
        }
      }
    }
    .navbar-wrapper-medium {
      position: fixed;
      width: 100%;
      .navbar-mess {
        .arrow-back {
          display: block;
        }
        .profile {
          .name-and-org {
            margin-left: 10px;
            width: 80%;
          }
        }
      }
    }
    .navbar-wrapper-large {
      position: fixed;
      width: 100%;
      .navbar-mess {
        .arrow-back {
          display: block;
        }
        .profile {
          .name-and-org {
            margin-left: 10px;
            width: 80%;
          }
        }
      }
    }

    .email-convertations-wrapper {
      padding-top: 35px;
      .new-message-label {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
      }

      .outgoing-message-wrapper {
        .outgoing-container {
          max-width: 55vw;
        }
      }

      .income-message-wrapper {
        .reaction-div {
          display: block;
          margin-left: -8%;
          margin-top: 2%;

          .reaction-list {
          }
        }
        .income-container {
          max-width: 100%;
          min-width: 100%;
          .reply-messages-inline {
            margin-left: 21px;
            .reply-inline-message {
              .message-inline-reply {
                width: 91%;
                margin-top: -8px;
                margin-left: -6%;
              }
            }
          }
        }
      }
    }
    .pop {
      margin-top: 50px;
    }
    .time-stamp-mob {
      display: block;
      margin-top: 4px;
      font-family: "Lato";
      font-size: 10px;
      font-weight: normal;
      color: #dcdcdc;
      margin-left: 5px;
    }

    .time-stamp {
      display: none;
    }

    .reply-time {
      display: none;
    }

    .sender-name-inline-reply {
      padding-left: 1.1%;
    }

    .sender-time-inline-reply {
      margin-right: 4.2px;
      font-size: 11px;
    }

    .sender-name-main-small {
      display: flex;
    }

    .sender-name {
      width: max-content;
    }
    .reply-head {
      padding-left: 0px;
      margin-left: -7%;
      width: 90.2%;
    }
  }

  .reply-container {
    .inner-reply {
      left: 0px;

      .inner-container {
        .reactions-container {
          overflow-x: hidden;

          .reactions {
            padding-left: 0px;

            .profile-pic {
              margin-left: 21%;
            }
          }
          .tab-view {
            .tabs {
              cursor: pointer;
              margin-left: 2%;
            }
          }
        }
        .message-container {
          .incoming-message-container {
            display: none;

            .incoming-message {
              .reply-main-head {
                .reply-time-messsages {
                  padding-right: 15px;
                }
              }
            }
          }
        }
      }

      //     display: flex;
      // flex-wrap: wrap;
    }

    .profile-pic {
      width: 10px;
      height: 10px;
    }
  }
}
ol {
  padding-left: 40px;
}
ul {
  list-style-type: disc;
  padding-left: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .__MessageBoxWrapper {
    width: 60%;
    min-width: 60%;
    max-width: 60%;
    .navbar-wrapper-small {
      width: 100%;
      .navbar-mess {
        .arrow-back {
          display: block;
          width: 15px;
          height: 15px;
          align-self: center;
          padding-top: 0;
          img {
            width: inherit;
            height: inherit;
          }
        }
        .profile {
          .name-and-org {
            margin-left: 10px;
            width: 80%;
          }
        }
      }
    }
    .navbar-wrapper-medium {
      width: 100%;
      .navbar-mess {
        .arrow-back {
          display: block;
          width: 15px;
          height: 15px;
          align-self: center;
          padding-top: 0;
          img {
            width: inherit;
            height: inherit;
          }
        }
        .profile {
          .name-and-org {
            margin-left: 10px;
            width: 80%;
          }
        }
      }
    }
    .navbar-wrapper-large {
      width: 100%;
      .navbar-mess {
        .arrow-back {
          display: block;
          width: 15px;
          height: 15px;
          align-self: center;
          padding-top: 0;
          img {
            width: inherit;
            height: inherit;
          }
        }
        .profile {
          .name-and-org {
            margin-left: 10px;
            width: 80%;
          }
        }
      }
    }

    .bottom-editor {
      margin-left: 0;
      width: 60%;
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .__MessageBoxWrapper {
    width: 70%;
    min-width: 70%;
    max-width: 70%;
    .navbar-wrapper-small {
      width: 100%;
      .navbar-mess {
        .arrow-back {
          display: block;
          width: 15px;
          height: 15px;
          align-self: center;
          padding-top: 0;
          img {
            width: inherit;
            height: inherit;
          }
        }
        .profile {
          .name-and-org {
            margin-left: 10px;
            width: 80%;
          }
        }
      }
    }
    .navbar-wrapper-medium {
      width: 100%;
      .navbar-mess {
        .arrow-back {
          display: block;
          width: 15px;
          height: 15px;
          align-self: center;
          padding-top: 0;
          img {
            width: inherit;
            height: inherit;
          }
        }
        .profile {
          .name-and-org {
            margin-left: 10px;
            width: 80%;
          }
        }
      }
    }
    .navbar-wrapper-large {
      width: 100%;
      .navbar-mess {
        .arrow-back {
          display: block;
          width: 15px;
          height: 15px;
          align-self: center;
          padding-top: 0;
          img {
            width: inherit;
            height: inherit;
          }
        }
        .profile {
          .name-and-org {
            margin-left: 10px;
            width: 80%;
          }
        }
      }
    }

    .bottom-editor {
      margin-left: 0;
      width: 70%;
    }
  }
}
