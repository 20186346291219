@import "../../../GlobalVariables/colors.scss";
$defultTextFont: "SF Pro Display", sans-serif;

.wrapper {
  width: 100%;
  // margin: auto;
  align-self: center;
  background-color: rgba(250, 246, 246, 0.6);
}

.wrapper .is-active {
  background-color: $white;
}

.contact-modal {
  // margin-top: 20px;
  width: 400px;
  height: max-content;
  background-color: $white;
  border-radius: 3px;
  position: relative;
  // overflow: auto;

  .top {
    background-color: $primary-blue;
    height: 50px;
    border-radius: 3px;
    position: relative;
    text-align: center;
  }
  .title {
    color: $white;
    font-size: 13px;
    font-family: $defultTextFont;
    position: absolute;
    margin-top: 18px;
    font-style: normal;
    font-weight: 100;
    padding-left: 50px;
  }

  .logo {
    width: 30px;
    // height: 30px;
    margin-top: 5px;
    padding-right: 5px;
  }

  .workspace-div {
    background-color: $workspace-grey;
    height: 55px;
    display: flex;
  }

  .workspace-div-title {
    color: $workspace-div-title;
    position: absolute;
    margin-top: 15px;
    font-size: 15px;
    padding-left: 25px;
  }

  .workspace-select-div {
    display: flex;
    width: 55%;
    align-content: center;
    justify-content: center;
    background-color: white;
    border: 1px solid $sub-topic-gray;
    cursor: pointer;
    border-radius: 20px;
  }

  .workspace-select {
    height: 35px;
    width: 80%;
    font-family: $defultTextFont;
    font-weight: 700;
    border: none;
    outline: none;
    border-radius: 20px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("../../../Assets/Icons/arrow-right.svg");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 8px;
  }

  .add-photo-title {
    text-align: center;
    font-family: $defultTextFont;
    font-size: 13px;
    margin-top: 10px;
  }
}

.input-style {
  padding-bottom: 30px;
}

.input-box {
  border: 0px transparent;
  padding-left: 15px;
  height: 35px;
  border: 1px solid $form-gray;
  // width:280px;
  border-radius: 5px;
  opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: 500;
}
.input-box::placeholder {
  color: #000;
  font-weight: normal;
}

::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::placeholder {
  color: $black;
  font-weight: normal;
  font-family: $defultTextFont;
  // font-size: 11px;
}

.gallery-add {
  background-color: $gallery-add-back;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add-image-div {
  margin-top: 30px;
}

.icons {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-fields {
  color: $primary-blue;
  font-size: 15px;
  margin-top: 10px;
  margin-left: 82px;
  font-weight: 600;
  font-family: $defultTextFont;
  cursor: pointer;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-btn {
  background-color: $primary-blue;
  width: 90px;
  border-radius: 4px;
  height: 36px;
  font-family: $defultTextFont;
  font-size: 13px;
  color: $white;
  cursor: pointer;
  font-weight: 500;
  cursor: pointer;
}

.save-btn-disable {
  background-color: $primary-blue;
  width: 90px;
  border-radius: 4px;
  height: 36px;
  font-family: $defultTextFont;
  font-size: 13px;
  color: $white;
  cursor: pointer;
  opacity: 0.7;
  font-weight: 500;
}

.cancel-btn {
  background-color: transparent;
  width: 90px;
  height: 36px;
  font-family: $defultTextFont;
  font-size: 13px;
  color: $cancel-grey;
  cursor: pointer;
  font-weight: 500;
}

.btn-grp {
  display: flex;
  // bottom: 0;
  // height: 50px;
  // padding-bottom: 20px;
  flex: 1;
  justify-content: right;
  // padding-right: 35px;
  padding-top: 10px;
  margin-top: 10px;
  padding-bottom: 10px;
  width: 65%;
}
.loader {
  border: 1px solid #42b0d1; /* Light grey */
  border-top: 1px solid $white; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.5s linear infinite;
  align-self: center;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-height: 600px) {
}

.__SaveContactRightWrapper {
  // min-width: 400px;
  // height: 100vh;
  border-left: $light-gray-background solid 1px;
  position: relative;
  overflow-x: hidden;
  background: $white;
  overflow-y: auto;
  // margin-top: 55px;
  z-index: 0;

  .navbar {
    padding: 16px 70px 16px 18px;
    justify-items: center;
    display: flex;
    align-items: center;
    min-height: 3.8rem;
    overflow: hidden;
    background-color: $white;
    cursor: pointer;
    border-bottom: 1px solid $gray;

    .back-arrow {
      width: 25px;
      margin-top: 3px;
      height: 12px;
      rotate: 180deg;
    }

    .save-tite {
      padding-left: 15px;
      font-weight: 700;
      color: $black;
      font-size: 18px;
    }
  }
  .formWrapper {
    padding: 30px 22px 10px 25px;
    border-bottom: 1 solid $black;
    overflow-x: auto;
    height: 86vh;

    .workspace-div {
      height: 55px;
      justify-items: center;
      display: flex;
      align-items: center;
    }
    .workspace-div-title {
      color: $workspace-div-title;
      font-size: 13px;
      width: 20px;
      font-weight: 500;
    }
    .workspace-select-div {
      display: flex;
      width: 59%;
      align-content: center;
      justify-content: center;
      background-color: white;
      border: 1px solid $primary-blue;
      cursor: pointer;
      border-radius: 20px;
    }

    .workspace-select {
      height: 31px;
      width: 80%;
      font-size: 11px;
      font-family: $defultTextFont;
      font-weight: 700;
      border: none;
      outline: none;
      border-radius: 20px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url("../../../Assets/Icons/arrow-right.svg");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 8px;
    }
    .logo {
      width: 30px;
      // height: 30px;
      justify-items: center;
      display: flex;
      align-items: center;
      padding-right: 5px;
      padding-left: -2px;
    }

    .personalinfor {
      color: $black;
      font-size: 13px;
      font-weight: 700;
      // letter-spacing: 1px;
      padding-top: 15px;
    }

    .input-heading {
      color: $dark-gray;
      font-family: $defultTextFont;
      font-size: 11px;
      font-weight: bold;
      padding-bottom: 3px;
    }

    .more-div {
      display: flex;
      justify-content: space-between;
      align-content: center;
      width: 65%;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-top: 15px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  // left: 1px;
  // bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  border: 1px solid $dark-gray;
}

.slider.round:before {
  border: 1px solid $dark-gray;
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  .__SaveContactRightWrapper {
    width: 100%;
    overflow-x: hidden;
    background: $white;
    overflow-y: auto;

    .formWrapper {
      .more-div {
        padding-right: 5%;
        width: 100%;
      }
      .btn-grp {
        margin-right: 0px;
        width: 100%;
        padding-right: 5%;
      }
      .input-style {
        width: 100%;
      }
    }
  }
}
