@import '../../../GlobalVariables/colors.scss';
._SignatureModal {
  .modal-1 {
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 50; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    // background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
    align-content: center;
    .modal-content-top {
      background-color: $white;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.1) !important;
      border-radius: 15px;
      width: 800px;
      height: 52vh;
      margin-top: 12%;
    }

    .report-main {
      width: 100%;
      height: 10vh;
    }

    .report-user-random {
      font-size: 25px;
      margin-top: 40px;
      margin-left: 4.5%;
      font-weight: 500;
    }

    .report-user-random-des {
      margin-left: 4.5%;
      font-weight: 100;
    }

    .report-input {
      margin-left: 4.5%;
      width: 90%;
      font-size: 16px;
      margin-top: 3%;
      padding-bottom: 60px;
      height: 13vh;
      border-radius: 10px;
      border: 1px solid #9d9d9d;

      border-color: rgb(207, 207, 207);
      padding-left: 10px;
    }

    .signature-editor {
      border: 1px solid black;
      border-radius: 10px;
      width: 90%;
      height: 19vh;
      margin-left: 4.5%;
      margin-top: 2%;
    }
    .ql-editor {
      box-sizing: border-box;
      line-height: 1.42;
      height: 11vh;
      outline: none;
      overflow-y: auto;
      padding: 6px 11px;
      tab-size: 4;
      -moz-tab-size: 4;
      text-align: left;
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    .ql-snow .ql-picker.ql-header {
        width: 0px;
    }

    .ql-toolbar.ql-snow {
      border: none;
      box-sizing: border-box;
      font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      /* padding: 8px; */
      margin-left: -42px;
  }
    

    .ql-toolbar.ql-snow .ql-picker-label {
      border: 1px solid transparent;
      display: none;
    }

    .report-input::placeholder {
      color: #9d9d9d;
    }
     
    .report-bottom {
      display: flex;
      width: 100%;
      height: 7vh;
    }
    .report-bottom-div {
      display: flex;
      align-items: flex-end;
      float: right;
      width: 100%;
      margin-left: 78%;
      padding: 7px;
    }

    .report-cancel {
      text-align: left;
      font: normal normal medium 27px/33px SF Pro Display;
      letter-spacing: 0px;
      color: #1e1e1e;
      opacity: 1;
      font-weight: 500;
      cursor: pointer;
    }

    .report-btn {
      text-align: left;
      font: normal normal medium 27px/33px SF Pro Display;
      letter-spacing: 0px;
      color: #0d2a4c;
      opacity: 1;
      margin-left: 17%;
      cursor: pointer;
      font-weight: 400;
    }

    .modal-content-add-admin {
      background: $white;
      margin: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      color: $primary-blue;
      align-content: center;
      font-size: 20px;
      border-bottom: 1px solid $primary-blue;
      font-family: 'Roboto';
      font-weight: 500;
      display: flex;
      padding-bottom: 10px;
      .plus-container-left {
        background-color: $primary-blue;
        width: 40px;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .plus {
          height: 18px;
          width: 18px;
          filter: brightness(0) invert(1);
        }
      }
      .add-header {
        margin-left: 10px;
        margin-top: 10px;
        font-size: 16px;
        font-family: 'Roboto';
        font-weight: 500;
        width: 70%;
      }
      .close {
        height: 14px;
        width: 14px;
        align-self: center;
        cursor: pointer;
        margin-top: 10px;
      }
    }
    .inner-container {
      display: block;
      padding-bottom: 30px;
      .search-container {
        padding: 10px;
        margin: 20px;
        background: $editor-background;
        border-radius: 20px;
        .search-input {
          width: 100%;
          border: none;
          height: 35px;
          padding: 10px;
          background: $editor-background;
          -webkit-appearance: none;
          outline: none !important;
        }
        .search-div {
          .name-container {
            display: flex;
            background-color: $bottom-border-gray;
            width: max-content;
            padding: 3px 10px;
            border-radius: 16px;
            margin-bottom: 5px;
            .name-div {
              font-family: 'roboto';
              font-size: 12px;
              color: $text-black;
              margin-right: 10px;
            }
          }
          .clear {
            height: 8px;
            width: 8px;
            align-self: center;
            cursor: pointer;
          }
        }
      }
      .group-members-container {
        display: block;
        .header-members {
          margin-left: 30px;
          font-family: 'Roboto';
          font-size: 14px;
          color: $primary-blue;
          margin-bottom: 10px;
          font-weight: 500;
        }
        .memebrs-list {
          margin: auto;
          width: 350px;
          padding: 20px;
          max-height: 260px;
          overflow-y: scroll;
          margin-top: -10px;
          height: max-content;
          .members {
            display: flex;
            position: relative;
            cursor: pointer;
            margin-bottom: 10px;

            .circle-add {
              height: 50px;
              width: 50px;
              background-color: $white;
              border-radius: 50%;

              img {
                border-radius: 50%;
              }
            }
            .div-name {
              display: block;
              align-self: center;
              flex: 2;
              text-align: left;
              .name {
                font-family: 'roboto';
                font-size: 14px;
                color: $text-black;
                margin-left: 15px;
                align-self: center;
                text-transform: capitalize;
                font-weight: 600;
              }
              .email {
                font-family: 'roboto';
                font-size: 11px;
                color: $sub-topic-gray;
                margin-left: 15px;
                align-self: center;
              }
            }
          }
        }
      }
    }
    .circle {
      background-color: $primary-blue;
      margin: auto;
      padding: 20px;
      width: 60px;
      float: right;
      margin-top: -28px;
      height: 60px;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -2px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 600px) {
  ._SignatureModal {
    .modal-1 {
      .modal-content-top {
        width: 90%;
        margin: auto;
      }
      .report-bottom {
        .report-bottom-div {
          margin-left: 0;
          justify-content: flex-end;
          width: 93%;
        }
        .report-cancel {
          margin-right: -40px;
        }
      }
    }
  }
}
