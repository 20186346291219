$white: #fff;
$loader-white: #f3f3f3;

$black: #000;
$text-black: #1e1e1e;
$small-text-black: #414141;
$login-header: #313131;
$head-name: #3e3e3e;

$primary-blue: #0d2a4c;
$sending-blue: #030303;
$buttn-blue: #0d2a4c;
$loader-gray: #989ca0;
$temp-blue: #aec8e0;
$light-blue-border: #0d2a4c;
$border-bluline: #0d2a4c;
$get-started: #0d2a4c;
$folder-title-clr: #303575;
$img-type: #0d2a4c;

$light-gray: #f1f1f1;
$gray: #dbdbdb;
$selected-gray: #9f9f9f;
$form-gray: #efefef;
$dark-gray: #9d9d9d;
$white-gray: #dcdcdc;
$incoming-message-gray: #f5f5f5;
$border-gray: #999;
$sub-topic-gray: #707070;
$text-gray: #4a4a4a;
$slight-gray: #aaaaaa;
$typing-text-gray: #838282;
$bottom-border-gray: #c7c7c7;
$darker-gray: #666666;
$header-gray: #a7a7a7;
$box-shadow-gray: #4d4c4c;
$light-border-gray: #b1b1b1;
$box-bakground: #e8e8e8;
$border-light-gray: #f0f0f0;
$attachment-color: #bfbfbf;
$login-underline-gray: #b2b2b2;
$tooltip-title: #e1dfdf;
$cancel-grey: #898989;

$editor-background: #f8f8f8;
$desktop-background: #f0f2f3;
$arrow-background-opacity: #c2c2c22c;
$modal-background: #00000050;
$light-gray-background: #ededed;
$modal-background: #f0f0f6;
$link-modal-background: #fefefe;
$incoming-message-background: #e7eaf8;
$bottom-border-background: #e7e7e7;

$form-success: #00be19;
$form-fail: #ff0000;

$app-header-green: #07a326;
$online-green: #54ff54;

$app-header-yellow: #dea60d;

$primary-blue: #0d2a4c;
$sending-blue: #ebedfa;
$buttn-blue: #0d2a4c;
$loader-gray: #989ca0;
$temp-blue: #aec8e0;
$light-blue-border: #0d2a4c;
$primary-blue-light:#3188FF;
$light-gray: #f1f1f1;
$gray: #dbdbdb;
$selected-gray: #9f9f9f;
$form-gray: #efefef;
$dark-gray: #9d9d9d;
$white-gray: #dcdcdc;
$incoming-message-gray: #f5f5f5;
$border-gray: #999;
$sub-topic-gray: #707070;
$text-gray: #4a4a4a;
$slight-gray: #aaaaaa;
$typing-text-gray: #838282;
$bottom-border-gray: #c7c7c7;
$darker-gray: #666666;
$header-gray: #a7a7a7;
$box-shadow-gray: #4d4c4c;
$light-border-gray: #b1b1b1;
$light-gray-border: #979797;
$deleted-gray: #8e8e8e;
$div-border: #bbbbbb;
$workspace-grey: #dfdfdf;
$workspace-div-title: #535353;
$gallery-add-back: #f2f2f2;
$placeholder-gray: #cccccc;

$editor-background: #f8f8f8;
$desktop-background: #f0f2f3;
$arrow-background-opacity: #c2c2c22c;
$modal-background: #00000050;
$light-gray-background: #ededed;
$modal-background: #f0f0f6;
$link-modal-background: #fefefe;
$incoming-message-background: #e7eaf8;
$bottom-border-background: #e7e7e7;

$form-success: #00be19;
$form-fail: #ff0000;

$app-header-green: #07a326;
$online-green: #54ff54;

$app-header-yellow: #dea60d;

$primary-blue-dark: #0d2a4c;
$border-gray-line: #dcdcdc;
$text-color:#5A5A5A