@import '../../../GlobalVariables/colors.scss';
$defultTextFont: "SF Pro Display", sans-serif;
._ForgotPwdContainer {
  height: 100vh;
  background-color: $white;
  display: flex;
  flex: 1;
  font-family: $defultTextFont;
  .ForgotPwdInnercontainerLeft {
    flex: 1;
    height: 100vh;
    .wrapper {
      background: $white;
      margin: auto;
      padding: 20%;
      .bixHeader {
        font-family: $defultTextFont;
        font-size: 36px;
        margin: 8px auto;
        font-weight: bold;
        text-align: center;
        color: $buttn-blue;
      }
      .header-bottom-text {
        color: #9d9d9d;
        font-family: $defultTextFont;
        font-size: 0.875rem;
        margin-top: -0.5rem;
        text-align: center;
      }
      .headerLabel {
        font-family: $defultTextFont;
        font-size: 28px;
        margin: 30px auto;
        font-weight: bold;
        text-align: left;
        color: $black;
      }
      .input-label {
        pointer-events: none;
        font-size: 14px;
        color: $dark-gray;
        font-family: $defultTextFont;
      }
      .username-margin-top-login {
        margin-top: 3vh;
        margin-bottom: 4vh;
      }

      .inputdiv {
        border: 1px;
        border-bottom: $login-underline-gray 1px solid;

        .input-container {
          display: flex;
          flex: 1;
          padding-bottom: 3px;
          .login-input-box {
            background-color: $white;
            border: none;
            font-size: 16px ;
            flex: 1;
          }
          .fname {
            color: $dark-gray;
            font-size: 16px;
            font-family: $defultTextFont;
            width: max-content;
          }
          .icon {
            float: right;
            height: 16px;
            width: 16px;
            cursor: pointer;
          }
          .icon1 {
            height: 16px;
            width: 16px;
            margin-right: 8px;
          }
        }
      }
      ::placeholder {
        color: $dark-gray;
        font-family: $defultTextFont;
        font-size: 16px;
      }

      .danger-image {
        height: 14px;
        width: 14px;
        margin-right: 6px;
      }
      .danger-image2 {
        height: 11px;
        width: 11px;
        margin-right: 6px;
        align-self: center;
      }
      .available {
        font-size: 13px;
        .right-text {
          color: $dark-gray;
          font-family: $defultTextFont;
          font-weight: 500;
        }
      }
    }
    .multi-container-div {
      display: flex;
      flex: 1;
      margin-top: 3.8vh;
    }
    .firstName-conatiner {
      flex: 1;
      margin-right: 8px;
    }
    .lastName-container {
      flex: 1;
      margin-left: 8px;
    }
    .form-success {
      font-size: 11px;
      font-weight: normal;
      color: $form-success;
      font-family: $defultTextFont;
      margin-top: 5px;
    }
    .form-fail {
      font-size: 11px;
      font-weight: normal;
      color: $form-fail;
      font-family: $defultTextFont;
      align-items: center;
    }
    .form-caption {
      text-align: left;
      color: $dark-gray;
      font-size: 12px;
      margin-top: 26px;
    }
    .footerText {
      text-align: center;
      font-size: 16px;
      margin-top: 4vh;
      color: $small-text-black;
      font-family: $defultTextFont;
      font-weight: 500;
      display: flex;
      justify-content: center;
    }
    .footerTextLink {
      color: $buttn-blue;
      margin-left: 5px;
      cursor: pointer;
    }
    .submit-btn {
      padding: 6% 2%;
      width: 80%;
      border-radius: 8px;
      background-color: $buttn-blue;
      color: $white;
      text-align: center;
      cursor: pointer;
      font-weight: bold;
      font-family: $defultTextFont;
      letter-spacing: normal;
      line-height: 0.94;
      margin-top: 6%;
      font-size: 18px;
      border-width: 0px;
      height: 50px;
      margin-left: 10%;
      margin-right: 10%;
    }
    .multiple-input-wrapper {
      display: flex;
      justify-content: space-around;
    }
  }
  .ForgotPwInnercontainerRight {
    flex: 1;
    height: 100vh;
    background-color: #0d2a4c;
    .wrapper2 {
      margin: auto;
      padding: 20%;
    }
  }
}

.sub-text {
  text-align: left;
  font-size: 14px;
  margin-top: -20px;
  color: $dark-gray;
  font-family: $defultTextFont;
  font-weight: 300;
}

.skip-btn {
  padding: 5% 2%;
  width: 80%;
  border-radius: 8px;
  background-color: $white;
  color: $buttn-blue;
  border: solid 2px $buttn-blue !important ;
  text-align: center !important;
  font-weight: 530;
  font-family: $defultTextFont;
  letter-spacing: normal;
  line-height: 0.94;
  margin-top: 4%;
  font-size: 20px;
  border-width: 0px;
  height: 50px;
  margin-left: 10%;
  margin-right: 10%;
}
.skip-btn:hover {
  color: $buttn-blue;
}

.date-select {
  border: transparent;
  font-size: 16px;
  width: 98%;
}

// ._ForgotPwdContainer

@media only screen and (max-width: 768px) {
  .mobileUI {
    display: block;
  }

  ._ForgotPwdContainer {
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    display: table;
    .ForgotPwInnercontainerRight {
      height: 45vh;
      display: table-header-group;
      .wrapper2 {
        padding: 10%;
      }
    }
    .ForgotPwdInnercontainerLeft {
      .wrapper {
        padding: 0% 5%;
        margin-top: -30px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        height: 55vh;
        overflow-y: auto;
        padding-bottom: 15px;
        position: absolute;

        .bixHeader {
          display: none;
          margin-bottom: -20px;
        }
        .header-bottom-text {
          display: none;
        }
      }
    }
  }
}
