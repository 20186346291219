@import "../../../../GlobalVariables/colors.scss";
.MobileSteps {
  padding-right: 20px;
  padding-left: 20px;
  .step-name {
    font-family: "Roboto";
    font-size: 16px;
    color: #fff;
    text-align: center;
    width: max-content;
  }
  .stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #6e6e6e;
    width: 100%;
    top: 20px;
    left: -30%;
    z-index: 2;
  }

  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid $primary-blue;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }

  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $primary-blue;
    margin-bottom: 6px;
    border: 2px solid #6e6e6e;
    img {
      height: 18px;
      width: 18px;
    }
  }

  .stepper-item.active {
    font-weight: bold;
  }

  .stepper-item.completed .step-counter {
    border: 2px solid #fff;
  }

  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #fff;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }

  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
}
