@import "../../../GlobalVariables/colors.scss";
.__WelcomeWrapper {
  margin: auto;
  background-color: $white;
  font-family: "roboto";

  .anim {
    position: relative;
    animation: mymove 1s;
  }
  @keyframes mymove {
    0% {
      transform: translatex(200px) translatey(200px);
    }
    100% {
      transform: translatex(0px) translatey(0px);
    }
  }
  .welcome-container {
    height: 330px;
    display: flex;
    justify-content: center;
  }
  .text-content {
    margin-top: -20px;
  }
  h1 {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
  }

  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
  }
  .hi-text {
    color: $primary-blue;
    text-align: center;
    font-size: 1.2rem;
  }
}

