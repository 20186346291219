@import "../../../GlobalVariables/colors.scss";
.EmailAvatar {
  color: $white;
  font-family: "Lato";
  font-weight: 600;
  font-size: 15px;
  background-color: $white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  .avatar-img-s {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }

  .avatar-img-m {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    background-color: #ff2c2cab;
  }
  .avatar-img-mn {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    background-color: #ff2c2cab;
  }

  .avatar-img-l {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }

  .avatar-img-xs-border {
    height: 33px;
    width: 33px;
    border-radius: 50%;
    border: 1px solid $sub-topic-gray;
    padding: 2px;
    display: flex;
    justify-content: center;
  }

  .avatar-img-xs {
    height: 33px;
    width: 33px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }

  .avatar-img-xxl {
    border-radius: 50%;
    min-width: 115px;
    height: 115px;
    background-color: $white;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  .avatar-img-xl {
    height: 67px;
    width: 67px;
    position: relative;
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }
  .letters {
    text-align: center;
    align-self: center;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 600px) {
  .EmailAvatar {
    height: 45px;
    width: 45px;
    .avatar-img-m {
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
    }
    .avatar-img-mn {
      border-radius: 50%;
      width: 29px;
      height: 29px;
      display: flex;
      justify-content: center;
      background-color: #ff2c2cab;
    }
  }
}
