._Agreement {
  text-align: justify;
  a{
      color: #0d2a4c;
  }
  .center {
    font-weight: 600;
    font-size: 0.85rem;
    margin-bottom: 20px;
  }
  .head-ag {
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .sub-ag {
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 20px;
  }
  .head-ag-2 {
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .margin-l {
    margin-left: 10px;
  }
  .margin-t {
    height: 10px;
  }
  .margin-t5 {
    height: 5px;
  }
}
@media screen and (max-width: 600px) {
  ._Agreement {
    font-size: 0.7rem;
    .center {
      font-size: 0.8rem;
    }
    .head-ag {
      font-size: 0.8rem;
      margin-bottom: 5px;
      margin-top: 5px;
    }
    .sub-ag {
      font-size: 0.75rem;
    }
    .head-ag-2 {
      font-size: 0.75rem;
    }
    .margin-l {
      margin-left: 10px;
    }
    .margin-t {
      height: 10px;
    }
    .margin-t5 {
      height: 5px;
    }
  }
}