@import '../../GlobalVariables/colors.scss';
@mixin showmoreBtn {
  width: 91px;
  height: 28px;
  margin: 8px 0 0 160px;
  padding: 5px 15px;
  border-radius: 16px;
  background-color: $light-gray;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  color: $primary-blue;
  cursor: pointer;
}

.create-message-pop {
  height: 300px;
  width: 240px;
  background: $white-gray;
  overflow-y: scroll;
  position: relative;

  // padding: 10px;

  .filer-user-input {
    position: sticky;
    top: 0;
  }

  .filterd-users {
    // overflow-y: scroll;

    .single-filtered-users {
      display: flex;
      padding: 10px;

      cursor: pointer;
      // justify-content: space-between;

      img {
        min-width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 10px;
        align-self: center;
      }

      .fullName {
        font-weight: bold;
      }
    }

    .single-filtered-users:hover {
      background: darkgray;
    }
  }
}

.__ChatUIWrapper {
  display: flex;
  max-width: 100%;
  height: 100vh;
  width: 100%;
}

.parent {
  background: linear-gradient(#65b4fe, #65b4fe) #e7e7e7;
background-size: 100% 15%;
background-position: 0% top,
           calc(30% * 100 / (100 - 30)) top;
background-repeat: no-repeat;
overflow: hidden;
}
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $gray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $dark-gray;
}
// @media screen and ( min-width: 1000px ) {
//   .__ChatUIWrapper  {
//   background-color: red ;
//   background-position: center;
//   justify-content: center;
//   background-size: contain;
//   min-width: 900px;
//         }
//       }

// .__ChatUIWrapper::after{
//   background-color: yellow;

// @media screen and (min-width: 1441px) {
//   .__ChatUIWrapper {
//     width: 75%;
//     margin: auto;
//     overflow: hidden;
//   }
// }
// @media screen and (min-width: 1695px) {
//   .__ChatUIWrapper {
//     width: 65%;
//     margin: auto;
//     overflow: hidden;


//   }
// }
// @media screen and (min-width: 1921px) {
//   .__ChatUIWrapper {
//     width: 55%;
//     margin: auto;
//     overflow: hidden;

//   }
// }
