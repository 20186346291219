@import "../../../GlobalVariables/colors.scss";
.admin-steps {
  .step {
    position: relative;
    color: #6e6e6e;
    
  }

  .caption {
    font-size: 1em;
    margin-top: 0.6em;
    font-weight: 400;
    font-family: "Roboto";
    min-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .step + .step {
    margin-top: 2.5em;
  }
  .step > div:first-child {
    position: static;
    height: 0;
  }
  .step > div:not(:first-child) {
    margin-left: 2.5em;
    padding-left: 1em;
  }
  .circle {
    background: $primary-blue;
    position: relative;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    z-index: 2;
    border: 1.5px solid #6e6e6e;
    img{
        height: 1.2em;
        width: 1.2em;
        margin-top: -0.2em;
    }
  }
  .circle:after {
    content: " ";
    position: absolute;
    display: block;
    right: 50%;
    left: 48%;
    height: 100%;
    width: 2.5px;
    transform: scale(1, 0.95);
    transform-origin: 50% -100%;
    background-color: #6e6e6e;
    z-index: 1;
  }
  .step:last-child .circle:after {
    display: none;
  }
  .step.step-active {
    color: #fff;
  }
  .step.step-active .circle {
    border-color: #fff;
  }
  .circle-1 {
    background: $primary-blue;
    position: relative;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    z-index: 2;
    border: 1.5px solid #6e6e6e;
    img{
        height: 1.2em;
        width: 1.2em;
        margin-top: -0.2em;
    }
  }
  .circle-1:after {
    content: " ";
    position: absolute;
    display: block;
    right: 50%;
    left: 48%;
    height: 100%;
    width: 2.5px;
    transform: scale(1, 0.95);
    transform-origin: 50% -100%;
    background-color: #fff;
    z-index: 1;
  }
  .step:last-child .circle-1:after {
    display: none;
  }
  .step.step-active {
    color: #fff;
  }
  .step.step-active .circle-1 {
    border-color: #fff;
  }
  

}
