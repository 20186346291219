$defultTextFont: 'SF Pro Display', sans-serif;

@import '../../../GlobalVariables/colors.scss';
._appIntregationModal {
  .modal-1 {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 50; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    // background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
    align-content: center;
    .modal-content-top {
      background-color: $white;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.1) !important;
      border-radius: 15px;
      width: 400px;
      margin-top: 10%;
      height: 65vh;
    }
    .modal-content-add-admin {
      background: $white;
      margin: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      color: $primary-blue;
      align-content: center;
      font-size: 20px;
      border-bottom: 1px solid $primary-blue;
      font-family: 'Roboto';
      font-weight: 500;
      display: flex;
      padding-bottom: 10px;
      .plus-container-left {
        background-color: $primary-blue;
        width: 40px;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .plus {
          height: 18px;
          width: 18px;
          filter: brightness(0) invert(1);
        }
      }
      .add-header {
        margin-left: 10px;
        margin-top: 10px;
        font-size: 16px;
        font-family: 'Roboto';
        font-weight: 500;
        width: 70%;
      }
      .close {
        height: 14px;
        width: 14px;
        align-self: center;
        cursor: pointer;
        margin-top: 10px;
        margin-left: 12.5%;
      }
    }
    .inner-container {
      display: block;
      height: 9vh;

      padding-bottom: 30px;
      margin-top: 20px;
      .google-calender-img {
        width: 102px;
        height: 55px;
      }
      .appIntegration-arrow {
        width: 15px;
        height: 15px;
        margin-top: 6%;
        margin-left: 35%;
      }

      .div-name {
        display: block;
        margin-top: 8px;

        .name {
          font-size: 15px;
          font-weight: 500;
        }

        .email {
          font-size: 11px;
        }
      }
    }

    .inner-container:hover {
      background-color: #9f9f9f2c;
      cursor: pointer;
    }
  }
}

._goggleCalenderInputs {
  .modal-1 {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 50; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    // background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
    align-content: center;
    .modal-content-top {
      background-color: #fff;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.1) !important;
      border-radius: 15px;
      width: 600px;
      margin-top: 6%;
      height: 86vh;
    }
    .modal-content-add-admin {
      background: $white;
      margin: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      color: $primary-blue;
      align-content: center;
      font-size: 20px;
      border-bottom: 1px solid $primary-blue;
      font-family: 'Roboto';
      font-weight: 500;
      display: flex;
      padding-bottom: 10px;
      .plus-container-left {
        background-color: $primary-blue;
        width: 40px;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .plus {
          height: 18px;
          width: 18px;
          filter: brightness(0) invert(1);
        }
      }
      .add-header {
        margin-left: 28px;
        margin-top: 10px;
        font-size: 16px;
        font-family: 'Roboto';
        font-weight: 500;
        width: 70%;
      }
      .close {
        height: 14px;
        width: 14px;
        align-self: center;
        cursor: pointer;
        margin-top: 10px;
        margin-left: 20.5%;
      }
    }
    .inner-container {
      display: block;
      height: 9vh;

      padding-bottom: 30px;
      margin-top: 20px;
      .google-calender-img {
        width: 102px;
        height: 55px;
      }
      .appIntegration-arrow {
        width: 15px;
        height: 15px;
        margin-top: 6%;
        margin-left: 35%;
      }

      .div-name {
        display: block;
        margin-top: 8px;

        .name {
          font-size: 15px;
          font-weight: 500;
        }

        .email {
          font-size: 11px;
        }
      }
    }
  }

  .input-wrapper-margin-right {
    width: 100%;
    height: 7vh;
    display: grid;
    .event-labels {
      margin-left: 5%;
      font-weight: 600;
      font-size: 14px;
      font-family: 'SF Pro Display', sans-serif;
      margin-top: 3%;
      padding-bottom: 10px;
    }

    .event-inputs {
      margin-left: 5%;
      width: 90%;
      font-size: 15px;
      height: 4vh;
      justify-content: center;
      align-content: center;
      background-color: white;
      border-radius: 5px;
      border: 1px grey solid;
    }

    .event-inputs-des {
      margin-left: 5%;
      width: 90%;
      font-size: 15px;
      height: 14vh;
      justify-content: center;
      align-content: center;
      background-color: white;
      border-radius: 5px;
      padding-bottom: 70px;
      border: 1px grey solid;
    }

    .event-inputs-des::placeholder {
      color: grey;
      font-size: 14px;
      justify-content: center;
      align-content: center;
      font-weight: 400;
    }
    .event-inputs::placeholder {
      color: grey;
      font-size: 14px;
      justify-content: center;
      align-content: center;
      font-weight: 400;
    }

    .date-picker {
      color: grey;
      font-size: 14px;
      justify-content: center;
      align-content: center;
      font-weight: 400;
      margin-left: 5%;
      width: 90%;
      border: 1px grey solid;
      background-color: white;
      border-radius: 5px;
      height: 4vh;
    }

    .time-picker {
      color: grey;
      font-size: 14px;
      justify-content: center;
      align-content: center;
      font-weight: 400;
      margin-left: 5%;
      margin-top: 10px;
      width: 76%;
      border: 1px grey solid;
      background-color: white;
      border-radius: 5px;
      height: 4vh;
    }

    .times-main {
      display: flex;
    }

    .available-times {
      width: 44%;
      margin-left: 2.7%;
      margin-top: 18px;
    }

    .dropbtn {
      margin-top: 2px;
      font-size: 14px;
      border: none;
      width: 100%;
      height: 2.5vh;
      background-color: white;
      text-align: left;
      font-weight: 400;
      color: grey;
    }

    .dropdown {
      position: relative;
      display: inline-block;
      color: grey;
      font-size: 14px;
      justify-content: center;
      align-content: center;
      font-weight: 400;
      margin-left: 5%;
      margin-top: 10px;
      width: 76%;
      border: 1px grey solid;
      background-color: white;
      border-radius: 5px;
      height: 4vh;
    }

    .dropbtn::placeholder{
      font-size: 14px;
      font-weight: 400;
      color: grey;

    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f1f1f1;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }

    .times {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      cursor: pointer;
    }

    .times:hover {
      background-color: #ddd;
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }

    

    .event-button {
      border: solid #e2e2e2 1px;
      border-radius: 5px;
      height: 4.5vh;
      color: black;
      padding: 0 20px;
      background: white;
      float: right;
      /* margin-bottom: 15px; */
      font-size: 12px;
      /* margin-bottom: 30px; */
      width: 30%;
      margin-top: -2%;
      margin-left: 33%;
    }
    .react-datepicker {
      font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
      font-size: 0.8rem;
      background-color: #fff;
      color: #000;
      border: 1px solid #aeaeae;
      border-radius: 5px;
      display: inline-block;
      position: relative;
      margin-left: 135%;
      width: 100%;
    }

    .plus-button-guest{
      width: 15px;
      height: 15px;
      margin-left: 1%;
      margin-top: -0.3%;
    }

    .search-bar-wrapper-1 {
      background-color: white;
      width: 90%;
      border-radius: 5px;
      border: 1px solid grey;
      margin-left: 5%;
      margin-bottom: 30px;
      padding-left: 12px;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-right: 12px;
      max-height: 7vh;

  
      .selected {
        background-color: $gray;
        border-radius: 16px;
        padding-right: 16px;
        padding-left: 16px;
        margin-right: 16px;
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: left;
        font-family: 'Roboto';
        letter-spacing: 0px;
        color: $text-black;
        margin-bottom: 10px;
        font-size: 12px;
        .close-ic {
          height: 8px;
          width: 8px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
      input {
        outline: none;
        border: none;
        background-color: $light-gray;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: left;
      }
      input::placeholder {
        /* Most modern browsers support this now. */
        color: $dark-gray;
      }
    }
    .search-bar-wrapper {
      background-color: $light-gray;
      width: 350px;
      border-radius: 24px;
      margin-bottom: 30px;
      padding-left: 12px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right: 12px;
      align-self: center;
  
      .selected {
        background-color: $gray;
        border-radius: 16px;
        padding-right: 16px;
        padding-left: 16px;
        margin-right: 16px;
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: left;
        font-family: 'Roboto';
        letter-spacing: 0px;
        color: $text-black;
        margin-bottom: 10px;
        font-size: 12px;
        .close-ic {
          height: 8px;
          width: 8px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
      input {
        outline: none;
        border: none;
        background-color: $light-gray;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: left;
      }
      ::placeholder {
        /* Most modern browsers support this now. */
        color: $dark-gray;
      }
    }

    .title-required{
      font-size: 11px;
      font-weight: normal;
      color: #ff0000;
      font-family: "Roboto";
      align-items: center;
      align-items: center;
      display: flex;
      margin-left: 5%;
      margin-top: 1%;
      }

  }
}

._GoogleCalenderOptions {
  .modal-1 {
    .google-login-button {
      background-color: aqua;
    }
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 50; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    // background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
    align-content: center;
    .modal-content-top {
      background-color: $white;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.1) !important;
      border-radius: 15px;
      width: 400px;
      margin-top: 10%;
      height: 25vh;
    }
    .modal-content-add-admin {
      background: $white;
      margin: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      color: $primary-blue;
      align-content: center;
      font-size: 20px;
      border-bottom: 1px solid $primary-blue;
      font-family: 'Roboto';
      font-weight: 500;
      display: flex;
      padding-bottom: 10px;
      .plus-container-left {
        background-color: $primary-blue;
        width: 40px;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .plus {
          height: 18px;
          width: 18px;
          filter: brightness(0) invert(1);
        }
      }
      .add-header {
        margin-left: 10px;
        margin-top: 10px;
        font-size: 16px;
        font-family: 'Roboto';
        font-weight: 500;
        width: 70%;
      }
      .close {
        height: 14px;
        width: 14px;
        align-self: center;
        cursor: pointer;
        margin-top: 10px;
        margin-left: 12.5%;
      }
    }

    .inner-container {
      display: block;
      height: 9vh;

      padding-bottom: 30px;
      margin-top: 20px;
      .google-calender-img {
        width: 102px;
        height: 55px;
      }
      .appIntegration-arrow {
        width: 15px;
        height: 15px;
        margin-top: 6%;
        margin-left: 35%;
      }

      .div-name {
        display: block;
        margin-top: 8px;

        .name {
          font-size: 15px;
          font-weight: 500;
        }

        .email {
          font-size: 11px;
        }
      }
    }

    .inner-container:hover {
      background-color: #9f9f9f2c;
      cursor: pointer;
    }
  }

  .rounded-circle {
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px;
    border-radius: 2px;
    height: 8vh;
    box-shadow: none;
    background-color: #fff;
    margin-top: 5%;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: Roboto, sans-serif;
  }

  .rounded-circle:hover {
    background-color: #9f9f9f2c;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
  ._adminModal {
    .modal-1 {
      .modal-content-top {
        width: 96%;
      }
    }
  }
}
