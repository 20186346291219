@import '../../../GlobalVariables/colors.scss';
$defultTextFont: 'SF Pro Display', sans-serif;
$bixFont: 'Roboto', sans-serif;
$folder-title: 'proxima-nova', sans-serif;

.save-wrapper {
  width: 100%;
  margin: auto;
  // margin-top: 15px;
  align-self: center;
  background-color: rgba(0, 0, 0, 0.45);
}

.save-wrapper .is-active {
  background-color: azure;
}

.modal-content {
  // box-shadow: 2px 6px 8px 0px rgba(0, 0, 0, 0.75) !important;
  margin-top: 5%;
  width: 80%;
  height: 90%;
  background-color: $white;
  border-radius: 13px;
  padding-bottom: 30px;

  .bix-title {
    font-family: $bixFont;
    color: $primary-blue;
    font-size: 15px;
    margin-left: 30px;
    margin-top: 10px;
    font-weight: 500;
    float: left;
  }

  .cloud-icon {
    height: 25px;
    margin-top: 10px;
    color: white;
  }

  .icon-placeholder {
    background-color: $primary-blue !important;
    top: 0;
    height: 45px;
    width: 50px;
    margin-left: 40px;
    position: relative;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .folders-div {
    width: 170px;
    margin-left: 60px;
  }

  // .folder-title{
  //     font-family: $defultTextFont !important;
  //     font-weight: bold !important;
  //     font-size: 20px !important;
  // }

  .folder-display-div {
    width: 95%;
    height: 65%;
    background-color: $light-gray;
    border: black 1px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;

    .float-child {
      float: left;
      padding: 10px;

      .single-folder-div {
        width: 130px;
        height: 150px;
        border: 2px $div-border solid;
        border-radius: 5px;
        cursor: pointer;
        padding: 25px 20px;
        // margin: auto;
        text-align: center;

        .folder-icon {
          text-align: center;
          // position: relative;
          width: 90px;
        }
      }

      .img-single-folder-div {
        width: 130px;
        height: 150px;
        border: 2px $div-border solid;
        border-radius: 5px;
        cursor: pointer;
        padding: 3px 3px;
        // margin: auto;
        position: relative;

        .img-view {
          text-align: center;
          // position: relative;
          width: 150px;
          height: 100px;
          border-radius: 8px;
        }

        .folder-title {
          font-family: $folder-title;
          color: $folder-title-clr;
          font-weight: 550;
          margin-top: 11px;
          margin-left: 8px;
          font-size: 15px;
          text-align: center;
          width: 90%;
        }

        .checkbox {
          margin: 3px 3px;
          top: 0;
          left: 0;
          position: absolute;
          height: 15px;
        }

        .image-type {
          margin: -15px 10px;
          position: absolute;
          padding: 3px 5px;
          background-color: $img-type;
          font-family: $defultTextFont;
          font-size: 11px;
          color: $white;
          font-weight: bold !important;
          border-radius: 5px;
        }
      }
    }
  }

  .btn-div {
    width: 275px;
    float: right;
    display: flex;
    height: 9vh;
    margin-top: -4.5%;
  }

  // .cancel-btn {
  //   display: flex;
  //   border: $primary-blue solid 2px;
  //   border-radius: 6px;

  //   text-align: center;
  //   margin-top: -24px;
  //   color: $primary-blue;
  //   font: $defultTextFont;
  //   font-size: 20px;
  //   cursor: pointer;
  //   margin-right: 5px;
  // }

  // .save-btn {
  //   display: flex;
  //   border: $primary-blue solid 2px;
  //   border-radius: 6px;
  //   color: #fff;

  //   text-align: center;
  //   color: $primary-blue;
  //   font: $defultTextFont;
  //   font-size: 70px;
  //   cursor: pointer;
  //   margin-right: 5px;
  // }

  .bottom-div {
    display: flex;

    .selectedCount {
      margin-left: 5px;
    }
  }

  .CancelBtn {
    border: #0d2a4c solid 2px;
    border-radius: 6px;
    height: 34px;
    width: 88px;
    text-align: center;
    color: #0d2a4c;
    font: 'SF Pro Display', sans-serif;
    font-size: 17px;
    cursor: pointer;
    margin-top: 7%;
    padding-top: 3px;
  }

  .ShareBtn {
    border-radius: 6px;
    height: 34px;
    width: 88px;
    text-align: center;
    background-color: #f1f1f1;
    color: #9d9d9d;
    font: 'SF Pro Display', sans-serif;
    font-size: 17px;
    cursor: pointer;
    margin-top: 7%;
    padding-top: 3px;
    font-weight: 500;
  }

  .SendBtn {
    border: #0d2a4c solid 2px;
    border-radius: 6px;
    height: 34px;
    width: 88px;
    text-align: center;
    background-color: #0d2a4c;
    color: #fff;
    font: 'SF Pro Display', sans-serif;
    font-size: 17px;
    cursor: pointer;
    margin-top: 7%;
    padding-top: 3px;
  }
}

.folder-title {
  font-family: $folder-title;
  color: $folder-title-clr;
  font-weight: 550;
  margin-top: 18px;
  font-size: 17px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 96%;
}

.search-bar {
  display: flex;
  background-color: $light-gray;
  border-radius: 5px;
  width: 300px;
  margin-top: -10px;
  padding: 3px 2px;
  align-self: center;

  .add {
    align-self: center;
    height: 14px;
    width: 16px;
    margin-right: 12px;
  }

  .input-style {
    outline: none;
    border: none;
    background-color: $light-gray;
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    margin-left: 10px;
    flex: 1;
  }
  ::placeholder {
    /* Most modern browsers support this now. */
    color: $dark-gray;
    font-size: 15x;
    font-family: $folder-title;
  }
}

.select-count {
  float: left;
  padding: 5px 30px;
  color: $black;
}

.search-bar-wrapper {
  display: flex;
  background-color: $light-gray;
  padding: 5px 30px 5px 15px;
  border-radius: 24px;
  align-self: center;
  width: 100%;
  flex: 1;
  .img-icon-style {
    height: 16px;
    width: 16px;
    align-self: center;
  }
  .input-style {
    outline: none;
    border: none;
    background-color: $light-gray;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    margin-left: 15px;
    flex: 1;
  }
  ::placeholder {
    /* Most modern browsers support this now. */
    color: $dark-gray;
    font-size: 14px;
  }
}

@media screen and (max-width: 1440px) {
  .save-wrapper {
    // width: 100%;
    margin: auto;
    // margin-top: 15px;
  }

  .modal-content {
    width: 90%;
    height: 60%;
    padding-bottom: 30px;

    .folder-display-div {
      width: 95%;
      height: 65%;
    }
  }
}

@media screen and (max-width: 1695px) {
  .save-wrapper {
    width: 100%;
    margin: auto;
    // margin-top: 15px;
  }
  .modal-content {
    // width: ;
    height: 70%;
    // padding-bottom: 30px;

    .folder-display-div {
      width: 95%;
      height: 65%;
    }
  }
}

@media screen and (max-width: 1921px) {
  .save-wrapper {
    width: 100%;
    margin: auto;
    // margin-top: 15px;
  }
  .modal-content {
    overflow-y: hidden;
    width: 1000px;
    height: 88%;
    top: -6%;

    .folder-display-div {
      width: 95%;
      height: 68%;
    }
  }
}
