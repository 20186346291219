/* Set content for font-families */
@import "../../GlobalVariables/colors.scss";

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 6px 11px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ql-toolbar.ql-snow {
  display: flex;
}

.messagebox-items {
  width: 16px;
  height: 16px;
}

.expand-selected {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 6px 20px 2px rgba(0, 0, 0, 0.15);
}

.typing-area {
  width: 100%;
  height: 97px;
}
.reply-typing-area{
  width: 100%;
  height: 145px;

}

.expand-selected-typingarea {
  width: 100%;
  height: 362px;
}
.text-editor-div {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  font-size: 14px;
  height: 185px;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 2px 8px 0.5px rgba(0, 0, 0, 0.22);
}

.text-editor-div-focus {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  font-size: 14px;
  height: 185px;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 6px 15px 2px rgba(0, 0, 0, 0.22);
}

.ql-container.ql-snow {
  border: none;
}
.ql-toolbar.ql-snow {
  border: none;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
}
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value="courier-new"]::before {
  font-family: "Courier New";
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value="extra-small"]::before {
  font-size: 13px !important;
}
.ql-size-small,
.ql-size span[data-value="small"]::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value="medium"]::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value="large"]::before {
  font-size: 20px !important;
}
.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  float: left;
  height: 100%;
  /* color: aqua; */
  //   filter: invert(90%);
  width: 15px;
}

.expand-selected-text-area {
  max-height: 40vh;
  padding: 3px;
  overflow-y: auto;
}

.editor-style {
  max-height: 95px;
  padding-left: 3px;
  overflow-y: auto;
}

.subject-main-div {
  width: 104.4%;
  display: flex;
  justify-content: space-between;
  margin-right: -20px;
}

.subject-div {
  width: 6.5rem;
  height: 1.5rem;
  background-color: #f1f1f1;
  /* border-top-left-radius: 15px; */
  border-bottom-right-radius: 4px;
  font-family: "roboto";
  color: #7e7e7e;
  margin-top: -0.1rem;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-self: center;
  padding: 10px 0;
}

.subject-selected-div {
  display: none;
}

.reply-subject-selected-div {
  display: none;
}

.subject-t {
  color: #4a4a4a;
  margin-left: 18px;
  align-self: center;
  cursor: pointer;
}
.subject-t-selected {
  display: none;
  // color: $primary-blue;
  // margin-left: 18px;
  // align-self: center;
  // //background-color: #3799f42d;
  // height: 20px;
  // width: 20px;
  // display: flex;
  // justify-content: center;
  // border-radius: 4px;
  // cursor: pointer;
}

.text-formatbtn {
  margin-left: 10px;
  margin-top: 5px;
  border: none;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  background: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 2px 5px;
  width: 28px;
  margin-top: 5px;
  margin-left: 2px;
}

.ql-formats {
  margin-left: 10px;
  margin-top: 5px;
  border: none;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  background: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 3px 5px;
  width: 28px;
}

.text-format {
  margin-left: 5px;
}

.ql-formats-selected {
  display: none;
}

.ql-toolbar {
  margin-left: -5px;
  font-family: "roboto";
  font-size: 14px;
}
.inputText {
  width: 95%;
  position: relative;
}

.add-subject-div {
  display: flex;
  width: 15rem;
}
.inputText-wrapper {
  display: flex;
}
.img-send-new {
  width: 19px;
  height: 19px;
  margin-top: 8px;
  margin-right: 22px;
}

.sendBtn-div {
  display: flex;
  width: 107px;
  height: 34px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 8px;
  margin-top: 5px;
  cursor: pointer;

  background-color: #0d2a4c;
  border-radius: 2px;
}
.send-button-name {
  width: 64.58px;
  left: 1009.53px;
  margin-top: 8px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 7px;
  /* identical to box height, or 52% */

  text-align: center;
  text-indent: 19px;

  color: #ffffff;
}

// @keyframes send-new {
//   0% {
//     min-width: 28px;
//     max-height: 25px;
//     border-radius: 50%;
//     margin-top: 10px;
//     margin-left: 8px;
//   }
//   100% {
//     width: 28px;
//     height: 28px;
//     background-color: $primary-blue;
//     padding: 5px;
//     border-radius: 50%;

//     margin-left: 8px;
//   }
// }

.toolbar {
  display: flex;
  flex: 1;
  margin-top: -15px;
  
  .left-options {
    display: flex;
    flex: 1;
  }
  .right-options {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-right: 18px;
    .editor-bottom-text {
      display: flex;
      margin-top: 10px;

      .text {
        color: $dark-gray;
        font-size: 11px;
        font-family: "Roboto";
        font-weight: normal;
        align-self: center;
        margin-right: 10px;
        width: max-content;
        .left-text {
          font-weight: 600;
        }
      }
    }

    .single-option {
      height: 16px;
      width: 16px;
      margin: 0 10px;
      cursor: pointer;
      align-self: center;
    }
  }
}





.ql-snow .ql-stroke {
  fill: none;
  stroke: #6b6b6b;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5;
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: $primary-blue;
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #9f9f9f;
}

.reply-subject-input-new {
  display: none;
}
.subject-input-new {
  padding-top: 15px;
  padding-bottom: 10px;
  margin-left: 18px;
  margin-right: 30px;
  font-family: "Roboto";
  font-size: 12px;
  display: flex;
  .subject-textinput {
    width: 100%;
    font-family: "roboto";
    color: #555555;
    border: none;
    outline: none !important;
    box-shadow: none;
    font-size: 0.9rem;
    font-weight: 500;
  }
  .subject-selected-textinput {
    padding-bottom: 50px;
  }

  .close-subject {
    width: 24px;
    height: 24px;
  }

  .subject-textinput::placeholder {
    width: 6rem;
    height: 1.5rem;
    font-family: "Lato";
    color: #6b6b6b;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    font-weight: 400;
  }
}

.subject-line {
  background-color: #6b6b6b;
  border: none;
  display: block;
  height: 1px;
  width: 95%;
  margin: 0px;
  margin-left: 1rem;
}

.reply-subject-line{
  display: none;
}



.quill > .ql-container > .ql-editor.ql-blank::before {
  font-size: 12px;
  color: #9f9f9f;
  font-style: normal;
  font-family: "Lato";
  text-transform: capitalize;
}
.popOver-n {
  position: absolute;
  background-color: $modal-background;
  right: 15px;
  bottom: 25%;
  border-radius: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: max-content;
  font-family: "roboto";
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  color: $text-black;
  font-size: 14px;
}
.sections-n {
  display: flex;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 2px 20px;
}
.sections-n:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.expand-btn-subject-selected {
  display: none;
}

.expand-button {
  width: 17px;
  height: 17px;
  cursor: pointer;
  align-self: center;
  display: flex;
  background-color: #fff;
  margin-top: 5px;
  margin-right: 5px;
}

.reply-expand {
  display: none;
}


.attachment-con-new-ed {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  overflow-x: auto;

  .attachement-container-n {
    border: 1px solid #dcdcdc;
    height: max-content;
    border-radius: 5px;
    margin-right: 10px;
    position: relative;
    min-width: 200px;
    padding: 6px;
    max-width: 200px;

    .attachment-container-editor {
      display: flex;
      .attachment-image-n {
        height: 50px;
        width: 50px;
        object-fit: cover;
        border-radius: 5px;
      }
      .attachment-mic {
        height: 50px;
        width: 100px;
        background-color: #c5c5c5;
        padding: 10px;
        border-radius: 10px;
      }
      .file-text {
        overflow: hidden !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        font-family: "Lato";
        max-width: 80%;
        color: $black;
        font-weight: 500;
        padding-top: 3px;
        padding-left: 10px;
        .file-type-text {
          font-weight: 400;
          color: #5a5a5a;
          text-transform: uppercase;
        }
      }
    }
    .close-icon-n {
      height: 16px;
      width: 16px;
      position: absolute;
      top: 0;
      right: 2px;
      cursor: pointer;
      display: none;
    }
    .plus-more {
      background-color: rgba(0, 0, 0, 0.541);
      position: absolute;
      height: 50px;
      width: 100px;
      top: 6px;
      display: flex;
      justify-content: center;
      align-self: center;
      align-items: center;
      color: #fff;
    }
  }
  .attachement-container-n:hover .close-icon-n {
    display: block;
  }
  .add-more {
    height: 62px;
    width: 62px;
    background-color: #c5c5c5;
    border-radius: 10px;
    font-size: 40px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-self: center;
    font-family: "Roboto";
  }
}
/* width */
.attachment-con-new-ed::-webkit-scrollbar {
  height: 5px;
  width: 20px;
}

/* Track */
.attachment-con-new-ed::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.attachment-con-new-ed::-webkit-scrollbar-thumb {
  background: #dcdcdc;
  border-radius: 10px;
}
@media screen and (max-width: 600px) {
  .img-send-new {
    width: 23px;
    height: 23px;
  }
  .send-anim-new {
    width: 28px;
    height: 25px;
    animation: send-new 0.3s;
    animation-fill-mode: forwards;
    transform: rotate(45deg);
    margin-top: 9px;
    margin-right: 5px;
  }
  .left-options {
    margin-left: -10px;
  }
  @keyframes send-new {
    0% {
      min-width: 28px;
      max-height: 25px;
      border-radius: 50%;
      margin-top: 10px;
      margin-left: 8px;
    }
    100% {
      width: 28px;
      height: 28px;
      background-color: $primary-blue;
      padding: 5px;
      border-radius: 50%;

      margin-left: 8px;
    }
  }
  .ql-snow .ql-formats {
    display: flex;
    stroke: #0d2a4c;
  }
}
