.PageNotFoundStyles {
  font-family: "Roboto";
  color: #1e1e1e;
  width: max-content;
  margin: auto;
  margin-top: 10%;
  text-align: center;
  .img-cont {
    width: 400px;
    height: 266px;
  }
  .bix-error {
    font-size: 41px;
    font-weight: 500;
    color: #234e6e;
  }
  .sub-error-text {
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 300;
    color: #b5b5b5;
  }
  .home-link {
    width: 34px;
    height: 34px;
    margin: auto;
    margin-top: 47px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 600px) {
  .PageNotFoundStyles {
    margin-top: 40%;
    .img-cont {
      height: 200px;
      width: 300px;
      margin: auto;
    }
    .bix-error  {
      font-size: 30px;
    }
    .sub-error-text {
      font-size: 16px;
    }
  }
}
