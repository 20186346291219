@import "../../GlobalVariables/colors.scss";

.avatar-img-s {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-img-m-small {
  border-radius: 50%;
  width: 29px;
  height: 29px;
  object-fit: cover;
}
.avatar-img-m-medium {
  border-radius: 50%;
  width: 31px;
  height: 31px;
  object-fit: cover;
}
.avatar-img-m-large {
  border-radius: 50%;
  width: 33px;
  height: 33px;
  object-fit: cover;
}

.avatar-img-l {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $white;
  object-fit: cover;
}

.avatar-img-xs-border {
  height: 33px;
  width: 33px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid $sub-topic-gray;
  padding: 2px;
}

.avatar-img-xs {
  height: 33px;
  width: 33px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-img-xxl {
  border-radius: 50%;
  min-width: 115px;
  height: 115px;
  object-fit: cover;
  background-color: $white;
  position: relative;
  cursor: pointer;
}

.avatar-img-xl {
  height: 67px;
  width: 67px;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
}
.avatar-img-ml {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  object-fit: cover;
}

