//font family
$defultTextFont: "Roboto";
$lato: "Lato";

//px
$font8: 8px;
$font9: 9px;
$font10: 10px;
$font11: 11px;
$font12: 12px;
$font13: 13px;
$font14: 14px;
$font15: 15px;
$font16: 16px;
$font17: 17px;
$font18: 18px;
$font19: 19px;
$font20: 20px;
$font21: 21px;
$font22: 22px;
$font24: 24px;
$font25: 25px;
$font26: 26px;
$font28: 28px;
$font29: 29px;
$font30: 30px;
$font34: 34px;
$font36: 36px;
$font40: 40px;
$font41: 41px;
$font50: 50px;
$font52: 52px;
$font69: 69px;
$font72: 72px;
$font95: 95px;

//rem
$fontrem_0-7: 0.7rem;
$fontrem_0-75: 0.75rem; //0.75rem
$fontrem_0-8: 0.8rem; //0.8rem
$fontrem_0-85: 0.85rem;
$fontrem_0-87: 0.87rem;
$fontrem_0-875: 0.875rem;
$fontrem_0-9: 0.9rem;
$fontrem_1: 1rem;
$fontrem_1-1: 1.1rem;
$fontrem_1-2: 1.2rem;
$fontrem_1-5: 1.5rem;

//em
$font_em_1: 1em;
