.NewModalUi {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  left: 0;
  top: 0;
  display: block;
  align-content: center;
  animation: fadeIn 1s;
  .pop-modal {
    margin-top: 8%;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    border-radius: 13px;
    animation: zoom-in 1s ease;
    height: 60vh;
    width: 40%;
    .close-btn {
      font-family: "Roboto";
      font-size: 16px;
      text-align: right;
      cursor: pointer;
      padding-top: 3%;
      padding-bottom: 3%;
      padding-right: 3%;
    }
    .seperator{
      height: 1px;
      width: 100%;
      background-color: #d9d9d9;
      margin-bottom: 5%;
    }
    .slide-cont {
      display: flex;
      margin-top: 0;
      .arrow-1 {
        margin-left: 20%;
        cursor: pointer;
        font-family: "Roboto";
        font-size: 20px;
      }
      .arrow-2 {
        margin-right: 20%;
        cursor: pointer;
        font-family: "Roboto";
        font-size: 20px;
      }
      .animation-zoom {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.1) !important;
        border-radius: 13px;
      }
      .NewModalUi-top {
        background-color: black;
        border-radius: 13px;
      }
      .fade-in {
        -webkit-animation-name: fade;
        -webkit-animation-duration: 1s;
        animation-name: fade;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
      }
    }
    .text-newui {
      font-family: "Lato";
      font-size: 13px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      color: #333333;
    }
    .text-newui-COMING {
      font-family: "Lato";
      font-size: 23px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      background: linear-gradient(to right, #1565FF 60%, #1567ff5d 50%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 10%;
    }
    .dot-bar {
      text-align: center;
      display: flex;
      height: 19px;
      justify-content: center;
      margin-top: 2%;
      .slide {
        cursor: pointer;
        height: 8px;
        width: 8px;
        margin: 0 5px;
        background-color: #d9d9d9;
        border-radius: 50%;
        display: inline-block;
        align-self: center;
      }

      .active,
      .slide:hover {
        background-color: #1565ff;
        height: 8px;
        width: 8px;
        align-self: center;
      }
    }
    @keyframes fade {
      from {
        opacity: 0.2;
      }
      to {
        opacity: 1;
      }
    }
  }
  @keyframes zoom-in {
    0% {
      transform: scale(0, 0);
    }
    100% {
      transform: scale(1, 1);
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 600px) {
  .NewModalUi {
    .pop-modal {
      margin-top: 35%;
      height:58vh;
      width: 90%;
      .close-btn {
        padding-top: 4%;
        padding-right: 8%;
        padding-bottom: 3%;
      }
      .seperator{
        height: 1px;
        width: 100%;
        background-color: #d9d9d9;
        margin-bottom: 10%;
      }
      .text-newui{
        width: 70%;
        margin: auto;
        text-align: center;
      }
      .slide-cont {
        .arrow-1 {
          margin-left: 5%;
          margin-top: 20%;
        }
        .arrow-2 {
          margin-right: 5%;
          margin-top: 20%;
        }
        .animation-zoom {
          height: max-content;
          width: 80%;
        }
      }
      .dot-bar {
        margin-top: 5%;
      }
    }
  }
}
