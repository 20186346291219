@import '../../../GlobalVariables/colors.scss';

$text-font: "Segoe UI", Roboto, Ubuntu, sans-serif;

.tooltip-background {
  display: block; /* Hidden by default */
  position: absolute; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  // background-color: rgb(0, 0, 0); /* Fallback color */
  // background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  align-content: center;
}

.tooltip {
  visibility: visible;
  background-color: $primary-blue;
  color: $white;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s;
  width: 16.875rem;
  font-family: "roboto";
  font-size: 0.75rem;
  height: max-content;
.header-container{
  display: flex;
  margin-top: 5px;
  .steps {
    flex: 1;
    color: $white;
    font-family: "roboto";
    margin-bottom: 10px;
  font-size: 0.875rem;
   
    font-weight: 600;
  }
  .arrow-container{
    display: flex;
    margin-top: 3px;
    justify-content: flex-end;
    img{
      width: 0.875rem;
      height: 0.875rem;
    }
    .left-arrow{
      transform: rotate(180deg);

    }
  }
}
  .btn-container {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #f1f1f173;
    padding-top: 5px;
    padding-right: 7px;
    margin-top: 1rem;
    margin-bottom: 5px;
    .nextbutton {
      color: $primary-blue;
      background-color: $white;
      font-weight: bold;
      padding: 1px 10px;
      border: none;
      outline: none;
      border-radius: 3px;
      cursor: pointer;
    }
    .backbutton {
      margin-right: 20px;
      font-weight: bold;
      cursor: pointer;
    }
  }
 
}

.top-tooltip {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 20%;
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent $primary-blue transparent;
}
.top3-tooltip {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 20%;
  margin-left: -35px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent $primary-blue transparent;
}
.top2-tooltip {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  right: 10%;
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent $primary-blue transparent;
}
.top4-tooltip {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 10%;
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent $primary-blue transparent;
}
.left-tooltip {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: 10px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent transparent $primary-blue;
}
.bottom-tooltip {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  right: 10%;
  border-width: 5px;
  border-style: solid;
  border-color: $primary-blue transparent transparent transparent;
}
.bottom1-tooltip {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  right: 10%;
  border-width: 5px;
  border-style: solid;
  border-color: $primary-blue transparent transparent transparent;
}
.right-tooltip {
  content: " ";
  position: absolute;
  top: 7%;
  right: 100%; /* To the left of the tooltip */
  border-width: 5px;
  border-style: solid;
  border-color: transparent $primary-blue transparent transparent;
}

@media screen and (max-width: 600px) {
  .top-tooltip {
    content: " ";
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 80%;
    margin-left: -5px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent $primary-blue transparent;
  }

  .bottom1-tooltip {
    right: 80%;
  }
}
