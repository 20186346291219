@import '../../../GlobalVariables/colors.scss';

.secondary-styles {
  padding: 1.3% 3%;
  width: 100px;
  border-radius: 6px;
  background-color: $white;
  color: $primary-blue;
  text-align: center;
  cursor: pointer;
  font-family: "Roboto";
  font-size: 1rem;
  border-width: 0px;
  border: none;
  border:  2px solid $primary-blue;
  height: max-content;
  margin-right: 20px;
}
