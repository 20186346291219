@import "../../../GlobalVariables/colors.scss";
$defultTextFont: "SF Pro Display", sans-serif;

@mixin showmoreBtn {
  width: 91px;
  height: 28px;
  margin: 8px 0 0 190px;
  padding: 5px 15px;
  border-radius: 16px;
  background-color: $light-gray;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  color: $primary-blue;
  cursor: pointer;
}
.__RightSideBarWrapper {
  width: 20%;
  min-width: 20%;
  max-width: 20%;
  height: 100vh;
  border-left: $light-gray-background solid 1px;
  position: relative;
  overflow-x: scroll;
  background: $white;
  margin-top: 55px;
  z-index: 0;

  .top-navbar {
    height: 60px;
    position: sticky;
    top: 0px;
    left: 500px;
    background: $white;
    display: flex;
    // justify-content: center;
    // align-self: center;
    flex: 1;
    z-index: 2;
    padding-left: 10%;
    padding-right: 10%;
    .close {
      height: 16px;
      width: 16px;
      cursor: pointer;
      flex: 1;
    }
    .topic {
      align-self: center;
      font-size: 14px;
      font-weight: 500;
      font-family: "Roboto";
      flex: 1;
      margin-left: 20px;
    }
    .more-icon {
      height: 16px;
      width: 16px;
      cursor: pointer;
      flex: 1;
    }
    .details-header-small {
      font-size: 0.8rem;
      color: $buttn-blue;
      font-weight: 500;
      font-family: "roboto";
      cursor: pointer;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      flex: 1;
    }
    //medium
    .details-header-medium {
      font-size: 15px;
      color: $buttn-blue;
      font-weight: 500;
      font-family: "roboto";
      cursor: pointer;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      flex: 1;
    }
    //large
    .details-header-large {
      font-size: 17px;
      color: $buttn-blue;
      font-weight: 500;
      font-family: "roboto";
      cursor: pointer;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      flex: 1;
    }
    .overview-header {
      font-size: 0.875rem;
      font-weight: 500;
      font-family: "roboto";
      cursor: pointer;
      flex: 1;
    }
  }

  .body-wrapper {
    .body-container {
      width: 90%;
      margin: 80px auto 20px;
      padding: 30px 10px;
      border-radius: 28px;
      // height: 1500px;
      .details-container {
        border-top: 1px solid $light-border-gray;
        margin-top: 10px;
        padding: 5px;
        padding-top: 20px;
        text-align: left;
        .deatils-header {
          font-family: "Roboto";
          font-size: 12px;
          font-weight: 600;
          color: $dark-gray;
          margin-bottom: 5px;
        }

        .seen-list {
          border-top: 1px solid #b1b1b1;

          .seen-main {
            display: flex;
            width: 90%;
            padding-bottom: 10px;
            margin-top: 10px;
          }
          .seen-img {
            display: flex;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            object-fit: cover;
          }
          .seen-name {
            font-family: "roboto";
            font-size: 13px;
            color: #1e1e1e;
            margin-left: 15px;
            align-self: center;
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 10p;
            margin-top: -15p;
            white-space: nowrap;
            margin-top: 8px;
          }
        }
        .details-content {
          font-family: "Roboto";
          font-size: 12px;
          color: $light-blue-border;
          margin-bottom: 5px;
          .em {
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .ed-btn-container {
          margin-top: 14vh;
          .edit-btn {
            color: $primary-blue;
            font-family: "Roboto";
            font-size: 14px;
            text-align: center;
            font-weight: 500;
            cursor: pointer;
            border: 1px solid $primary-blue;
            border-radius: 6px;
            padding: 5px 20px;
            width: max-content;
            margin: auto;
            margin-bottom: 10px;
          }
          .delete-btn {
            color: #eb1515;
            font-family: "Roboto";
            font-size: 14px;
            text-align: center;
            font-weight: 500;
            cursor: pointer;
          }

          .block-btn {
            color: maroon;
            font-family: "Roboto";
            font-size: 14px;
            margin-top: 10px;
            text-align: center;
            font-weight: 500;
            cursor: pointer;
            word-break: break-all;
            .blck-name {
              max-width: 80%;
              background-color: aqua;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .profile-wrapper {
        text-align: center;
        position: relative;
        margin-bottom: 30px;

        .added {
          font-size: 10px;
          color: $border-gray;
          font-style: italic;
          font-family: "roboto";
        }
        .button-container {
          display: flex;
          flex: 1;
          margin-top: 20px;
          padding-bottom: 10px;
          .message-button {
            flex: 1;
            color: $white;
            background-color: $primary-blue;
            border-radius: 8px;
            font-family: "roboto";
            font-size: 12px;
            margin-left: 10px;
            margin-right: 10px;
            height: 30px;
            padding-top: 6px;
            cursor: pointer;
          }
          .call-button {
            flex: 1;
            color: $border-gray;
            border: 1px solid $border-gray;
            border-radius: 8px;
            font-family: "roboto";
            font-size: 12px;
            margin-left: 10px;
            margin-right: 10px;
            height: 30px;
            padding-top: 6px;
          }
        }
        .profile-picture {
          border-radius: 50%;
          margin-top: -115px;
          min-width: 115px;
          height: 115px;
          object-fit: cover;
          background-color: $white;
          position: relative;
          cursor: pointer;
        }
        .profile-picture-email {
          border-radius: 50%;
          margin-top: -100px;
          min-width: 115px;
          height: 115px;
          width: 115px;
          object-fit: cover;
          background-color: #ff2c2cab;
          object-fit: cover;
          text-align: center;
          align-self: center;
          color: $white;
          font-size: 52px;
          align-items: center;
          display: flex;
          justify-content: center;
          font-family: "Roboto";
          position: relative;
          margin-left: 25%;
          text-transform: capitalize;
        }
        .online-status {
          background-color: $online-green;
          position: absolute;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          right: 33%;
          z-index: 1;
          border: 1px solid $white;
        }
        .offline-status {
          position: relative;
          height: 115px;
          width: 115px;
          border-radius: 50%;
          margin-top: -96.5px;
          margin-left: 75px;
        }
        .name {
          font-family: "Lato";
          font-size: 18px;
          font-weight: 600;
          color: $text-black;
          margin-top: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
          margin: auto;
          white-space: nowrap;
        }
        //medium
        .name-medium {
          font-family: "Lato";
          font-size: 20px;
          font-weight: 600;
          color: $text-black;
          margin-top: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
          margin: auto;
          white-space: nowrap;
        }
        //large
        .name-medium {
          font-family: "Lato";
          font-size: 22px;
          font-weight: 600;
          color: $text-black;
          margin-top: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
          margin: auto;
          white-space: nowrap;
        }
        .online-list {
          display: flex;
          font-family: "Roboto";
          font-size: 11px;
          justify-content: center;
          .online {
            color: $primary-blue;
            margin-right: 3px;
          }
          .all-members {
            color: $slight-gray;
          }
        }
        .edit-icon {
          background-color: $white;
          position: absolute;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          right: 33%;
          z-index: 1;
          border: 1px solid $white;
          margin-top: 90px;
          .edit-image {
            filter: brightness(0%);
            height: 14px;
            width: 14px;
            cursor: pointer;
            margin-top: -5px;
          }
        }

        .name-edit {
          margin-top: 20px;

          .group-email-name {
            font-family: "Roboto";
            font-size: 12px;
            color: #0d2a4c;
            text-align: left;
          }
          .header-name {
            text-align: left;
            font-family: "Roboto";
            font-size: 13px;
            color: $selected-gray;
            font-style: italic;
          }
          .edit {
            text-align: left;
            font-family: "Roboto";
            font-size: 18px;
            font-weight: 500;
            color: $text-black;
            display: flex;
            border-bottom: 1px solid rgba(223, 223, 223, 0.753);

            .input {
              text-align: left;
              font-family: "Roboto";
              font-size: 12px;
              font-weight: 500;
              color: $text-black;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              box-shadow: none !important;
              border: transparent;
            }
            .edit-img {
              filter: brightness(0%);
              height: 16px;
              width: 16px;
              align-self: flex-end;
              margin-bottom: 5px;
              cursor: pointer;
              margin-left: 5px;
            }
            .textarea {
              text-align: left;
              font-family: "Roboto";
              font-size: 12px;
              color: $text-black;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              box-shadow: none !important;
              border: transparent;
              height: max-content;
              resize: none;
              width: max-content;
            }
            .tick-container {
              background-color: $primary-blue;
              height: 22px;
              width: 22px;
              border-radius: 50%;
              display: flex;
              align-self: flex-end;
              margin-bottom: 5px;
              box-shadow: 3px 3px 3px $bottom-border-gray;
              .loader {
                border: 1px solid $loader-white; /* Light grey */
                border-top: 1px solid $primary-blue; /* Blue */
                border-radius: 50%;
                width: 15px;
                height: 15px;
                animation: spin 2s linear infinite;
                align-self: center;
                justify-content: center;
                display: flex;
                align-items: center;
                margin: auto;
              }

              @keyframes spin {
                0% {
                  transform: rotate(0deg);
                }
                100% {
                  transform: rotate(360deg);
                }
              }
              .tick {
                height: 10px;
                width: 10px;
                align-self: center;
                justify-content: center;
                display: flex;
                align-items: center;
                margin: auto;
                cursor: pointer;
              }
            }
          }
        }
        .header-name {
          text-align: left;
          font-family: "Roboto";
          font-size: 13px;
          color: $selected-gray;
          font-style: italic;
          margin-top: 20px;
        }
        .add-members {
          margin-top: 20px;
          display: flex;
          margin-left: 10px;
          flex: 1;
          width: 100%;
          cursor: pointer;
          .circle-add {
            height: 50px;
            width: 50px;
            background-color: $primary-blue;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            position: relative;
            cursor: pointer;
            .add {
              height: 15px;
              width: 15px;
              margin-top: 17.5px;
            }
            .text {
              color: $white;
              font-family: "roboto";
              font-size: 16px;
              align-self: center;
            }
          }
          .add-members-text {
            font-family: "roboto";
            font-size: 16px;
            font-style: italic;
            color: $bottom-border-gray;
            margin-left: 15px;
            align-self: center;
          }
        }
        .view-members {
          margin-top: 20px;
          display: flex;
          margin-left: 10px;
          flex: 1;
          width: 100%;
          cursor: pointer;
          .circle-add {
            height: 50px;
            width: 50px;
            background-color: $primary-blue;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            cursor: pointer;
            .add {
              height: 15px;
              width: 15px;
              margin-top: 17.5px;
            }
            .text {
              color: $white;
              font-family: "roboto";
              font-size: 16px;
              align-self: center;
            }
            .text-minus {
              color: $white;
              font-family: "roboto";
              font-size: 20px;
              align-self: center;
              font-weight: 500;
            }
          }
          .more-text {
            font-family: "roboto";
            font-size: 14px;
            color: $text-black;
            margin-left: 15px;
            align-self: center;
            width: 62%;
            text-align: left;
          }
          .arrow-more {
            height: 14px;
            width: 14px;
            align-self: center;
            transform: rotate(90deg);
            cursor: pointer;
          }
          .arrow-top {
            height: 14px;
            width: 14px;
            align-self: center;
            transform: rotate(-90deg);
          }
        }
        .leave-group {
          color: $small-text-black;
          font-family: "roboto";
          font-size: 14px;
          border-top: solid 1px $light-gray-border;
          padding: 15px;
          margin-top: 20px;
          border-bottom: solid 1px $light-gray-border;
          text-align: left;
          cursor: pointer;
        }
        .delete-group {
          color: $form-fail;
          font-family: "roboto";
          font-size: 14px;
          padding: 15px;
          border-bottom: solid 1px $light-gray-border;
          text-align: left;
          font-weight: 500;
          cursor: pointer;
        }
        .members {
          margin-top: 20px;
          display: flex;
          margin-left: 10px;
          position: relative;
          .loader-blue {
            border: 1.5px solid $primary-blue; /* Light grey */
            border-top: 1.5px solid $white; /* Blue */
            border-radius: 50%;
            width: 18px;
            height: 18px;
            animation: spin2 2s linear infinite;
            align-self: center;
            justify-content: center;
            display: flex;
            align-items: center;
            margin: auto;
          }

          @keyframes spin2 {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          .remove-popup {
            background-color: $modal-background;
            position: absolute;
            right: 0;
            z-index: 1;
            margin-top: 35px;
            padding: 8px;
            font-size: 12px;
            font-family: "Roboto";
            border-radius: 8px;
            .popup-item {
              display: flex;
              margin-bottom: 5px;
              cursor: pointer;
              .icon-item {
                height: 13px;
                width: 13px;
                align-self: center;
                margin-right: 10px;
              }
            }
          }

          .circle-add {
            height: 50px;
            width: 50px;
            background-color: $white;
            border-radius: 50%;
            position: relative;
            cursor: pointer;
            .img {
              border-radius: 50%;
              height: inherit;
              width: inherit;
              object-fit: cover;
            }

            .img-seen {
              border-radius: 50%;
              height: inherit;
              width: inherit;
              object-fit: cover;
              width: 20px;
            }
          }
          .online {
            background-color: $online-green;
            position: absolute;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            border: 1px solid $white;
            bottom: 0;
            left: 35px;
          }
          .div-name {
            display: block;
            align-self: center;
            text-align: left;
            cursor: pointer;
            width: 70%;
            .name {
              font-family: "roboto";
              font-size: 14px;
              color: $text-black;
              margin-left: 15px;
              align-self: center;
              width: 90%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .email {
              font-family: "roboto";
              font-size: 11px;
              color: $text-black;
              margin-left: 15px;
              align-self: center;
              width: 90%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .arrow-right {
            min-height: 14px;
            min-width: 14px;
            max-height: 14px;
            max-width: 14px;
            align-self: center;
            cursor: pointer;
          }
        }

        .designation {
          font-family: "Roboto";
          font-size: 12px;
          font-weight: 400;
          color: $text-black;
        }

        .group-description {
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          justify-content: center;
        }

        .organization {
          font-family: "Roboto";
          font-size: 12px;
          font-weight: 450;
          color: $text-black;
        }
      }

      .email-phone-wrapper {
        border-bottom: solid 1px $gray;
        padding: 10px 10px 10px 10px;
        margin-left: 10px;
        margin-right: 10px;
        border-top: solid 1px $gray;

        .label {
          font-family: "Roboto";
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          color: $dark-gray;
          margin: 0;
        }

        .link {
          font-family: "Roboto";
          font-size: 14px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          color: $primary-blue;
          margin-bottom: 10px;
        }

        .show-more-btn {
          @include showmoreBtn;
        }
      }

      .photos-and-media-wrapper {
        padding: 10px;
        border-bottom: solid 1px $gray;
        .flex-container {
          display: flex;

          .header {
            font-family: "Roboto";
            font-size: 14px;
            font-weight: 450;
            color: $text-black;
            flex: 2;
            cursor: pointer;
            margin-left: 10px;
          }

          .icon {
            height: 14px;
            width: 14px;
            align-self: center;
          }
        }
        .loader-container {
          justify-content: center;
          display: flex;
        }
        .photos-wrapper {
          display: grid;
          grid-template-columns: auto auto auto;
          padding: 10px;
          grid-row-gap: 5px;
          grid-column-gap: 25px;
          position: relative;
          animation-name: example;
          animation-duration: 0.5s;
          .empty-media {
            font-family: "Lato";
            font-size: 12px;
            color: $light-gray-border;
            width: 95%;
            overflow: hidden !important;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          img {
            min-width: 50px;
            height: 50px;
            border-radius: 3px;
            margin: 5px 0;
            border: 1px solid #dcdcdc;
            padding: 5px;
          }
        }
        @keyframes example {
          0% {
            top: -10px;
          }
          100% {
            top: 0px;
          }
        }

        .show-more-btn {
          @include showmoreBtn;
        }
      }
      .subject-message {
        display: flex;
        border-top: solid 1px $gray;
        padding: 10px;
        margin-top: 20px;
        cursor: pointer;
        .header {
          font-family: "Roboto";
          font-size: 14px;
          font-weight: 450;
          color: $text-black;
          flex: 2;
          cursor: pointer;
          margin-left: 10px;
        }
        .icon {
          height: 14px;
          width: 14px;
          align-self: center;
        }
      }
      .attachments-wrapper {
        padding: 10px;
        border-bottom: solid 1px $gray;
        border-top: solid 1px $gray;
        .flex-container {
          display: flex;

          .header {
            font-family: "Roboto";
            font-size: 14px;
            font-weight: 450;
            color: $text-black;
            flex: 2;
            cursor: pointer;
            margin-left: 10px;
          }

          .icon {
            height: 14px;
            width: 14px;
            align-self: center;
          }
        }
        .loader-container {
          justify-content: center;
          display: flex;
        }
        .view-more {
          font-family: "Lato";
          font-size: 11px;
          display: flex;
          justify-content: right;
          padding-right: 25px;
          color: $primary-blue-light;
          font-weight: 600;
          cursor: pointer;
        }
        .attachment-wrapper {
          display: grid;
          grid-template-columns: auto auto auto;
          grid-row-gap: 5px;
          grid-column-gap: 5px;
          position: relative;
          animation-name: example;
          animation-duration: 0.5s;
          overflow-y: auto;
          overflow-x: hidden;
          .empty-attachment {
            font-family: "Lato";
            font-size: 12px;
            color: $light-gray-border;
            width: 95%;
            overflow: hidden !important;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .attach-prev {
            position: relative;
            .attachment-name-hov {
              position: absolute;
              top: 20px;
              font-family: "Lato";
              font-size: 10px;
              background-color: #dcdcdcd2;
              padding: 2px 10px;
              width: 100px;
              word-wrap: break-word;
              display: none;
              z-index: 2;
            }
            .img {
              min-width: 50px;
              height: 50px;
              border-radius: 3px;
              margin: 5px 0;
              border: 1px solid #dcdcdc;
              padding: 5px;
              cursor: pointer;
            }
            .img:hover + .attachment-name-hov {
              display: block;
            }
          }

          .attachment {
            min-width: 50px;
            height: 50px;
            border-radius: 3px;
            margin: 5px 0;
            background-color: $attachment-color;
            justify-content: center;
            display: flex;
            .img-mic {
              height: 16px;
              width: 16px;
              align-self: center;
            }
          }
        }
        @keyframes example {
          0% {
            top: -10px;
          }
          100% {
            top: 0px;
          }
        }
      }
      .group-members-wrapper {
        border-bottom: solid 1px $gray;
        padding: 10px 10px 10px 10px;
        margin-left: 10px;
        margin-right: 10px;

        .photos-wrapper {
          display: grid;
          grid-template-columns: auto auto auto;

          img {
            min-width: 70px;
            height: 70px;
            border-radius: 50%;
            margin-bottom: 10px;
          }
        }

        .show-more-btn {
          @include showmoreBtn;
        }
      }
      .labeled-message-wrapper {
        border-bottom: solid 1px $gray;
        padding: 15px;
        display: flex;
        justify-content: space-between;

        .header {
          font-family: "Roboto";
          font-size: 14px;
          font-weight: 450;
          color: $text-black;
          flex: 2;
        }
        .icon {
          height: 14px;
          width: 14px;
          align-self: center;
        }
      }
      .reminded-message-wrapper {
        border-bottom: solid 1px $gray;
        padding: 15px;
        display: flex;
        justify-content: space-between;

        .header {
          font-family: "Roboto";
          font-size: 14px;
          font-weight: 450;
          color: $text-black;
          flex: 2;
        }
        .icon {
          height: 14px;
          width: 14px;
          align-self: center;
        }
      }

      .attachment-wrapper {
        margin-left: 10px;
        margin-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;

        .header {
          font-family: "Roboto";
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          color: $primary-blue;
        }

        .attachments {
          margin-top: 10px;

          .single-attach {
            display: flex;
            margin: 10px 0;

            .logo {
              min-width: 30px;
              height: 30px;
              background: green;
              border-radius: 8px;
            }

            .name {
              font-family: "Roboto";
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              margin-left: 10px;
              align-self: center;
            }
          }
        }

        .show-more-btn {
          @include showmoreBtn;
        }
      }

      .subject-wrapper {
        padding: 10px;

        .sub-wrapper {
          display: block;
          grid-template-columns: auto auto auto;
          grid-row-gap: 5px;
          grid-column-gap: 5px;
          position: relative;
          animation-name: example;
          animation-duration: 0.5s;
          overflow-y: auto;
          overflow-x: hidden;
          width: 121%;
          height: 35vh;
          overflow-y: auto;
          margin-left: 8%;
        }

        .subject-mes {
          width: 80%;
        }

        .rightside-subject {
          border: 1px solid #979797;
          border-radius: 5px 5px 5px 5px;
          margin-top: 8px;
          text-align: left;
          color: #979797;
          padding: 1px 10px;
          font-family: "Lato";
          font-size: 13px;
          font-weight: 500;
          width: max-content;
          overflow: hidden !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 28%
          
        }

        .subject-messages-main {
          width: 100%;
          display: grid;
        }
        .flex-container {
          display: flex;

          .header {
            font-family: "Roboto";
            font-size: 14px;
            font-weight: 450;
            color: $text-black;
            flex: 2;
            cursor: pointer;
            margin-left: 10px;
          }

          .icon {
            height: 14px;
            width: 14px;
            align-self: center;
          }
        }
        .loader-container {
          justify-content: center;
          display: flex;
        }

        .attachment-wrapper {
          display: block;
          grid-template-columns: auto auto auto;
          grid-row-gap: 5px;
          grid-column-gap: 5px;
          position: relative;
          animation-name: example;
          animation-duration: 0.5s;
          overflow-y: auto;
          overflow-x: hidden;
          width: max-content;
          .empty-attachment {
            font-family: "Lato";
            font-size: 12px;
            color: $light-gray-border;
            width: 95%;
            overflow: hidden !important;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .attach-prev {
            position: relative;
            .attachment-name-hov {
              position: absolute;
              top: 20px;
              font-family: "Lato";
              font-size: 10px;
              background-color: #dcdcdcd2;
              padding: 2px 10px;
              width: 100px;
              word-wrap: break-word;
              display: none;
              z-index: 2;
            }
            .img {
              min-width: 50px;
              height: 50px;
              border-radius: 3px;
              margin: 5px 0;
              border: 1px solid #dcdcdc;
              padding: 5px;
              cursor: pointer;
            }
            .img:hover + .attachment-name-hov {
              display: block;
            }
          }

          .attachment {
            min-width: 50px;
            height: 50px;
            border-radius: 3px;
            margin: 5px 0;
            background-color: $attachment-color;
            justify-content: center;
            display: flex;
            .img-mic {
              height: 16px;
              width: 16px;
              align-self: center;
            }
          }
        }
        @keyframes example {
          0% {
            top: -10px;
          }
          100% {
            top: 0px;
          }
        }
      }
    }
  }
}
.textarea {
  max-width: 92%;
  min-width: 92%;
}
.member-text {
  text-align: left;
  font-family: "Roboto";
  font-size: 13px;
  color: $selected-gray;
  font-style: italic;
  margin-top: 10px;
  margin-left: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .__RightSideBarWrapper {
    margin-top: 10px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;

    .top-navbar {
      height: 40px;
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
    }

    .body-wrapper {
      .body-container {
        width: 100%;
        .profile-wrapper {
          text-align: center;

          .profile-picture-email {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
          }
          .name {
            margin-left: auto;
            margin-right: auto;
            font-size: 18px;
            max-width: 300px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .__RightSideBarWrapper {
    width: 40%;
    min-width: 40%;
    max-width: 40%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .__RightSideBarWrapper {
    width: 30%;
    max-width: 30%;
    min-width: 30%;
  }
}
.save-contact-border {
  margin-top: 10px;
  width: 40%;
  height: 40px;
  padding: 3px;
  z-index: 1;
  cursor: pointer;
}

.save-contact-btn {
  background-color: $primary-blue;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: 13px;
  font-family: $defultTextFont;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 500;
}

.report-spam {
  font-family: $defultTextFont;
  font-weight: 600;
  color: red;
  margin-top: 10px;
  cursor: pointer;
}

.block-btn {
  color: maroon;
  font-family: "Roboto";
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  .blck-name {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 5px;
  }
}
