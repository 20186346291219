@import "../../GlobalVariables//colors.scss";
.MatesComponents {
  height: 200px;
  margin-top: 30px;
  .contacts-list {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 50%;
    justify-content: center;
    column-gap: 5px;
    row-gap: 10px;
    margin: auto;
    margin-bottom: 20px;
  }
  .viewButton {
    width: max-content;
    margin: auto;
    background-color: $primary-blue;
    color: $white;
    font-family: "Lato";
    font-size: 11px;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
}
