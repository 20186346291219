@import '../../../GlobalVariables/colors.scss';
$defultTextFont: "SF Pro Display", sans-serif;
$bixFont:"Roboto", sans-serif;
$folder-title:"proxima-nova", sans-serif;

.block-nav{

    display: flex;
    justify-content: space-between;
    width: 70%;
    height: 55px;
    padding-top: 20px;
    border-bottom: 1px solid #e7e7e7;

  }

  .list-wrapper{
    padding-top:15px ;
    padding-bottom: 15px;

  }

  .single-block{
    display: flex;
    // background-color: aqua;
    justify-content: space-between;
    width: 70%;

  }

  .block-option{
    color: gray;
    text-align: center;
    margin-bottom: -1px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-items: center;
  }

  .selected-option {
    color: #0d2a4c;
    border-bottom: 2px solid #0d2a4c;
  }

  .arrow-dropdown{
    height: 11px;
    transform: rotate(90deg);
    
  }

  .empty-blocklist{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    font-family: $defultTextFont;
    font-size: 12px;
    color: $dark-gray;
    font-style: italic;
  }