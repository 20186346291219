@import '../../../GlobalVariables/colors.scss';
._reportSentModal {
  .modal-2 {
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 50; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    // background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
    align-content: center;
    .modal-content-top-2 {
      background-color: $white;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.1) !important;
      border-radius: 15px;
      width: 300px;
      height: 30vh;

      margin-top: 16%;
    }

    .report-sent-main {
      width: 100%;
      height: 10vh;
    }
    .report-sent-close-div{
        width: 100%;
        height: 3vh;

    }

    .close{
        display: flex;
        width: 14px;
        height: 14px;
        margin-right: 8%;
        margin-top: 4%;
    }

    .report-sent-name{
      font-size: 20px;
      
      margin-left: 29.5%;
      font-weight:500;
      
    }

    
    .report-sent-user-random-des {
        margin-left: 15.5%;
        color: #9D9D9D;
        font-size: 14px;
        margin-top: 6%;
        font-weight: 100;
        width: 200px;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 400;
      }

      .tick-square-div{     
        align-items: center;
        justify-content: center;
        margin-top: 8%;
        
      }

      .tick-square{
        width: 30px;
        height: 30px;
        margin-left: 43%;
      }
  }
}

@media screen and (max-width: 600px) {
  ._reportSentModal {
    .modal-2 {
   
      .modal-content-top-2 {
       
      }
    
    }
  }
}

@media only screen and (max-width: 600px) {
  ._reportSentModal {
    .modal-2 {
   
      .modal-content-top-2 {
      margin: auto;
      }
    
    }
  } 
}

