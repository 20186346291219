@import '../../../GlobalVariables/colors.scss';
._PrivacyModal {
  .privacy-modal-black-background {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 50; /* Sit on top */
    left: 0;
    top: 0;
    width: 50%;
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(157, 157, 157, 0.35); /* Black w/ opacity */
    align-content: center;
  }
  .privacy-modal-content-inner {
    background-color: $white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px;
    width: 500px;
    height: 70vh;
    margin-top: 15vh;
    .privacy-modal-content-center {
      background: $white;
      margin: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      color: $primary-blue;
      align-content: center;
      border-bottom: 1px solid #0d2a4c60;
      font-family: "Roboto";
      display: flex;
      padding-bottom: 10px;

      .header {
        margin-left: 20px;
        margin-top: 15px;
        font-size: 16px;
        font-family: "Roboto";
        font-weight: 500;
        width: 85%;
      }
      .close {
        height: 16px;
        width: 16px;
        align-self: center;
        cursor: pointer;
      }
    }
    .privacy-inner-container {
      display: block;
      flex-wrap: wrap;
      justify-content: center;
      background-color: $white;
      margin: 20px 18px;
      font-size: 0.75rem;
      overflow-y: auto;
      height: 58vh;
      padding: 16px;
      border-radius: 8px;
      color: #707070;
      .pol {
        font-family: "roboto";
        font-size: 0.8rem;
        text-align: justify;
        .div-pol {
          .head-pol {
            font-size: 0.85rem;
            font-weight: 500;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  ._PrivacyModal {
    .privacy-modal-black-background {
      width: 100%;
    }
    
    .privacy-modal-content-inner {
      width: 90%;
      height: 80vh;
      margin-top: 10vh;
      .privacy-modal-content-center{
        .header{
            font-size: 15px;
        }
    }
      .privacy-inner-container {
        height: 68vh;
        margin: 10px 8px;
  
        .pol{
            font-size: 0.7rem;
            .div-pol {
                .head-pol {
                  font-size: 0.75rem;
                }
              }
        }
      }
    }
    
  }
}
