@import '../../GlobalVariables/colors.scss';
._Modal {
  .modal-black-background {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 50; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
    align-content: center;
  }
  .modal-content-inner {
    background-color: $white;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px;
    margin-top: 13%;
    width: 500px;
    .modal-content-center {
      background: $white;
      margin: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      color: $primary-blue;
      align-content: center;
      font-size: 20px;
      border-bottom: 1px solid #0d2a4c60;
      font-family: "Roboto";
      font-weight: 500;
      display: flex;
      padding-bottom: 10px;

      .plus-container-left {
        background-color: $primary-blue;
        width: 40px;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .info {
          height: 16px;
          width: 16px;
          filter: brightness(0) invert(1);
        }
      }
      .header {
        margin-left: 10px;
        margin-top: 10px;
        font-size: 14px;
        font-family: "Roboto";
        font-weight: 500;
        width: 75%;
      }
      .close {
        height: 14px;
        width: 14px;
        align-self: center;
        cursor: pointer;
        margin-top: 10px;
      }
    }
    .inner-container {
      display: block;
      height: auto;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      padding-bottom: 30px;

      .button-container {
        display: flex;
        justify-content: flex-end;
        margin-right: 40px;
        font-size: 0.8rem;
        .no-button {
          background-color: $primary-blue;
          border: none;
          cursor: pointer;
          color: $white;
          padding: 2.5% 5%;
          border-radius: 6px;
          margin-right: 20px;
          font-size: 0.8rem;
          font-weight: 500;
          width: max-content;
        }
        .yes-button {
          background-color: $white;
          border: 1px solid $border-light-gray;
          cursor: pointer;
          color: $black;
          border-radius: 6px;
          font-size: 0.8rem;
          font-weight: 500;
          padding: 2.5% 5%;

          .loader {
            border: 1px solid $text-black; /* Light grey */
            border-top: 1px solid $white; /* Blue */
            border-radius: 50%;
            width: 15px;
            height: 15px;
            animation: spin 2s linear infinite;
            align-self: center;
            justify-content: center;
            display: flex;
            align-items: center;
            margin: auto;
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
    .delete-container {
      padding: 8% 10%;
      display: block;

      .password-labl {
        font-size: 1rem;
        font-weight: 500;
        font-family: "Roboto";
        color: #1e1e1e;
      }
      .password-cont {
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        .pwd-err {
          font-size: 11px;
          color: #ff0000;
          font-weight: 500;
          align-self: center;
          margin-top: 3px;
          .password-err{
            height: 13px;
            width: 13px;
            margin-right: 5px;
            margin-top: -1.5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  ._Modal{
  
    .modal-content-inner {
      width:90%;
     margin-top: 45%;
      .modal-content-center{
        .header{
          width: 65%;
        }
      }
      .inner-container{
        .button-container{
          justify-content: center;
          margin-right: 0;
          margin-top: 2vh;
        }
       
      }
      .delete-container{
        .password-cont{
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
