@import '../../../GlobalVariables/colors.scss';
._adminModal {
  .modal-1 {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 50; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    // background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
    align-content: center;
    .modal-content-top {
      background-color: $white;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.1) !important;
      border-radius: 15px;
      margin-top: 13%;
      width: 440px;
    }
    .modal-content-add-admin {
      background: $white;
      margin: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      color: $primary-blue;
      align-content: center;
      font-size: 20px;
      border-bottom: 1px solid $primary-blue;
      font-family: "Roboto";
      font-weight: 500;
      display: flex;
      padding-bottom: 10px;
      .plus-container-left {
        background-color: $primary-blue;
        width: 40px;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .plus {
          height: 18px;
          width: 18px;
          filter: brightness(0) invert(1);
        }
      }
      .add-header {
        margin-left: 10px;
        margin-top: 10px;
        font-size: 16px;
        font-family: "Roboto";
        font-weight: 500;
        width: 75%;
      }
      .close {
        height: 14px;
        width: 14px;
        align-self: center;
        cursor: pointer;
        margin-top: 10px;
      }
    }
    .inner-container {
      display: block;
      padding-bottom: 30px;
      .search-container {
        padding: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        .search-input {
          width: 350px;
          border: 1px solid $bottom-border-gray;
          height: 35px;
          border-radius: 20px;
          padding: 10px;
        }
        .search-div {
          width: 350px;
          border: 1px solid $bottom-border-gray;
          height: 35px;
          border-radius: 20px;
          padding-top: 4px;
          padding-left: 10px;

          .name-container {
            display: flex;
            background-color: $header-gray;
            width: max-content;
            padding: 3px 10px;
            border-radius: 16px;
            .name-div {
              font-family: "roboto";
              font-size: 12px;
              color: $text-black;
              margin-right: 10px;
            }
          }
          .clear {
            height: 8px;
            width: 8px;
            align-self: center;
            cursor: pointer;
          }
        }
      }
      .group-members-container {
        display: block;
        .header-members {
          margin-left: 30px;
          font-family: "Roboto";
          font-size: 14px;
          font-style: italic;
          color: $slight-gray;
          margin-bottom: 10px;
        }
        .memebrs-list {
          margin: auto;
          background-color: $sending-blue;
          width: 350px;
          padding: 20px;
          height: 250px;
          overflow-y: auto;
          .members {
            display: flex;
            position: relative;
            cursor: pointer;

            .circle-add {
              height: 50px;
              width: 50px;
              background-color: $white;
              border-radius: 50%;

              .img {
                border-radius: 50%;
              }
            }
            .div-name {
              display: block;
              align-self: center;
              flex: 2;
              text-align: left;
              .name {
                font-family: "roboto";
                font-size: 14px;
                color: $text-black;
                margin-left: 15px;
                align-self: center;
                text-transform: capitalize;
              }
              .email {
                font-family: "roboto";
                font-size: 11px;
                color: $text-black;
                margin-left: 15px;
                align-self: center;
              }
            }
          }
        }
      }
    }
    .circle {
      background-color: $primary-blue;
      margin: auto;
      padding: 20px;
      width: 60px;
      float: right;
      margin-top: -28px;
      height: 60px;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -2px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 600px) {
  ._adminModal {
    .modal-1 {
      .modal-content-top {
        width: 96%;
      }
    }
  }
}
