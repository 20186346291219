@import '../../../GlobalVariables/colors.scss';
$defultTextFont: "SF Pro Display", sans-serif;

.Image_Preview {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.35);
  width: 100%;
  position: absolute;
  z-index: 2;
  padding: 30px 50px;
  .image-preview-container {
    background-color: $white;

    border-radius: 16px;
    height: 92vh;
    box-shadow: 0 0 6px rgb(58, 58, 58);
    position: relative;
    .header {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 10px;
      display: flex;
      flex: 1;
      height: 6.1vh;
      border-bottom: 0.5px solid $primary-blue;

      .right-container {
        flex: 2;
        display: flex;
        .text {
          font-family: "Roboto";
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.04;
          letter-spacing: normal;
          text-align: left;
          color: $primary-blue;
          align-self: center;
          margin-left: 25px;
        }
        .img-cont {
          background-color: $primary-blue;
          padding: 12px;
          border-bottom-left-radius: 13px;
          border-bottom-right-radius: 13px;

          .image {
            height: 20px;
            width: 20px;
            filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(138deg)
              brightness(112%) contrast(101%);
          }
        }
      }
      .left-container {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        .image-container1 {
          display: flex;

          .pop-up-img {
            background-color: $modal-background;
            position: absolute;
            z-index: 10;
            font-size: 12px;
            padding: 8px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            width: 150px;
            box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.16);
            top: 45px;
            right: 70px;

            .item {
              display: flex;
              align-self: center;
              font-family: $defultTextFont;
              color: $text-black;
              font-size: 13px;
              margin-bottom: 3px;
              padding: 3px;
              cursor: pointer;

              .img {
                width: 17px;
                height: 17px;
                align-self: center;
                margin-right: 8px;
                margin-left: -2px;
                .download-img-d {
                  height: 20px;
                  width: 20px;
                  align-self: center;
                  cursor: pointer;
                }
              }
            }
            .item:hover {
              background-color: rgba(0, 0, 0, 0.1);
            }
          }
          .image-download {
            height: 20px;
            width: 20px;
            align-self: center;
            cursor: pointer;
            filter: invert(46%) sepia(98%) saturate(969%) hue-rotate(186deg)
              brightness(97%) contrast(97%);
            cursor: pointer;
            margin-right: 20px;
            margin-top: 10px;
          }

          .image {
            height: 14px;
            width: 14px;
            align-self: center;
            cursor: pointer;
          }
        }
      }
    }
    .bottom-conatiner {
      padding: 25px 60px;
      position: relative;
      display: flex;
      flex: 1;
      height: 76vh;
      .arrow-container {
        flex: 1;
        display: flex;
        .arrow-div {
          align-self: center;
          height: 40px;
          width: 40px;
          background-color: $arrow-background-opacity;
          border-radius: 50%;
          padding: 10px;
          margin: 10px;
          display: flex;
          justify-content: center;
          
          cursor: pointer;
          .arrow-style {
            height: 15px;
            width: 15px;
            transform: rotate(180deg);
            filter: invert(46%) sepia(98%) saturate(969%) hue-rotate(186deg)
              brightness(97%) contrast(97%);
          }
        }
      }
      .image-container {
        flex: 6;
        .imagePrev {
          height: 68vh;
          width: 100%;
          display: none;
          object-fit: contain;
          .filePrev {
            width: 100%;
            display: flex;
            justify-content: center;
          }

          .video-player-wrapper {
            position: absolute;
            right: 0;
            left: 0;
            width: 74%;
            margin: auto;

            .video-player {
              width: 100%;
              margin: auto;

              background: black;
              height: 80vh !important;
            }

            .video-Controler {
              .x {
                display: block;
              }
              img {
                height: 22px;
                cursor: pointer;
                padding: 5px;
              }
              cursor: default;
              height: 40px;
              width: 90%;
              margin-top: -40px;
              margin-left: 5%;
              background: rgba(black, 0.5);
              position: relative;
              display: flex;
              justify-content: space-between;
              padding: 9px 20px;
              text-align: center;

              .play-push {
                img {
                  margin: 0 3px;
                }
              }

              .seeker {
                width: 80%;
                display: flex;
                text-align: center;
                margin-top: 1px;

                input {
                  width: 100%;
                }
              }

              .playing-time {
                font-size: 12px;
                color: white;
                margin-top: 2px;
              }
              .time-styles {
                font-size: 12px;
                color: white;
                font-family: "Roboto";
                margin-top: 2px;
              }
              .volume {
                cursor: pointer;
                display: flex;
                .contr {
                  display: none;
                }
              }
              .expand {
              }
              .volume:hover + .expand {
                display: none;
              }
              .volume:hover {
                .contr {
                  display: block;
                }
              }
            }
            input[type="range"] {
              -webkit-appearance: none;
              width: 100%;
              height: 2px;
              background: $white;
              background-image: linear-gradient($primary-blue, $primary-blue);
              background-size: 70% 100%;
              background-repeat: no-repeat;
              margin-top: 9px;
            }

            input[type="range"]::-webkit-slider-thumb {
              -webkit-appearance: none;
              height: 20px;
              width: 20px;
              cursor: pointer;
              transition: background 0.3s ease-in-out;
            }

            input[type="range"]::-webkit-slider-runnable-track {
              -webkit-appearance: none;
              box-shadow: none;
              border: none;
              background: transparent;
            }
          }
        }
      }
      .arrow-container-right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        .arrow-div {
          align-self: center;
          height: 40px;
          width: 40px;
          background-color: $arrow-background-opacity;
          border-radius: 50%;
          padding: 10px;
          margin: 10px;
          display: flex;
          justify-content: center;
          cursor: pointer;
          .arrow-style {
            height: 15px;
            width: 15px;
            filter: invert(46%) sepia(98%) saturate(969%) hue-rotate(186deg)
              brightness(97%) contrast(97%);
            margin-top: 2px;
          }
        }
      }
    }
    .image-list-container {
      border-top: 0.5px solid $dark-gray;
      padding: 2vh;
      margin-left: 20px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      height: 8vh;
      align-self: center;
      position: relative;
      .bottom-images {
        height: 6vh;
        width: 6vh;
        border-radius: 5px;
        margin-right: 15px;
      }
      .bottom-images-selected {
        height: 6vh;
        width: 6vh;
        border-radius: 5px;
        margin-right: 15px;
        background-color: $sending-blue;
        padding: 0.7vh;
      }
    }
  }
  .attachment {
    height: 300px;
    width: 300px;
    border-radius: 20px;
    background-color: $attachment-color;
    margin: auto;
    margin-top: 80px;

    .img-mic {
      height: 120px;
      width: 120px;
      align-self: center;
    }
  }
  .audioWidth {
    width: 400px;
    margin: auto;
    margin-top: 30px;
  }
  .attachment2 {
    height: 4.6vh;
    border-radius: 5px;
    background-color: $attachment-color;
    justify-content: center;
    display: flex;
    .img-mic2 {
      height: 30px;
      width: 30px;
      align-self: center;
    }
  }
}

@media screen and (max-width: 600px) {
  .Image_Preview {
    padding: 40px 10px;

    .image-preview-container {
      .bottom-conatiner {
        padding: 10px 10px;
      }
      .header {
        .left-container {
          .image-container {
            .download-img-a {
              .image-download {
                right: 15%;
              }
            }
          }
        }
      }
    }
  }
}

