.attachment-scroller {
  position: relative;
  .left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    margin-left: -10px;
    background-color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #dcdcdc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img{
        height: 15px;
        width: 15px;
        transform:rotate(-180deg);
    }
  }
  .right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    margin-right: -12px;
    background-color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #dcdcdc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img{
        height: 15px;
        width: 15px;
    }
  }
  .scroll-attachments {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    background-color: #fff;
    .attachement-container-n {
      border: 1px solid #dcdcdc;
      height: max-content;
      border-radius: 5px;
      margin-right: 10px;
      position: relative;
      min-width: 200px;
      padding: 6px;
      max-width: 200px;

      .attachment-container-editor {
        display: flex;
        .attachment-image-n {
          height: 50px;
          width: 50px;
          object-fit: cover;
          border-radius: 5px;
        }
        .attachment-mic {
          height: 50px;
          width: 100px;
          background-color: #c5c5c5;
          padding: 10px;
          border-radius: 10px;
        }
        .file-text {
          overflow: hidden !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 13px;
          font-family: "Lato";
          max-width: 80%;
          color: "black";
          font-weight: 500;
          padding-top: 3px;
          padding-left: 10px;
          .file-type-text {
            font-weight: 400;
            color: #5a5a5a;
            text-transform: uppercase;
          }
        }
      }
      .close-icon-n {
        height: 16px;
        width: 16px;
        position: absolute;
        top: 0;
        right: 2px;
        cursor: pointer;
        display: none;
      }
      .plus-more {
        background-color: rgba(0, 0, 0, 0.541);
        position: absolute;
        height: 50px;
        width: 100px;
        top: 6px;
        display: flex;
        justify-content: center;
        align-self: center;
        align-items: center;
        color: #fff;
      }
    }
    .attachement-container-n:hover .close-icon-n {
      display: block;
    }
    .add-more {
      height: 62px;
      width: 62px;
      background-color: #c5c5c5;
      border-radius: 10px;
      font-size: 40px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-self: center;
      font-family: "Roboto";
    }
  }::-webkit-scrollbar {
    background: transparent; /* make scrollbar transparent */
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
}

@media screen and (max-width: 600px) {
  .attachment-scroller{
    .left{
      margin-left: 0px;
    }
    .right{
      margin-right: 0px;
    }
    .scroll-attachments{
      padding-left: 10px;
    }
  }
}