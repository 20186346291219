@import "../../GlobalVariables/colors.scss";

._AudioComponent {
  display: flex;
  margin: 3px;
  .btnStyle {
    filter: invert(46%) sepia(95%) saturate(976%) hue-rotate(186deg)
      brightness(98%) contrast(96%);
    height: 21px;
    width: 21px;
    cursor: pointer;
  }

  @function webkit-slider-thumb-shadow($i: -1) {
    $val: #{$i}px 0 0 -#{(10px - 2px) / 2} $dark-gray;
    @if $i == -1 {
      @for $k from 5 through 140 {
        $val: #{$val}, webkit-slider-thumb-shadow($k);
      }
    }
    @return $val;
  }
  .btnStyle2 {
    filter: invert(46%) sepia(95%) saturate(976%) hue-rotate(186deg)
      brightness(98%) contrast(96%);
    height: 26px;
    width: 26px;
    cursor: pointer;
  }
  $height: 30px;
  $thumb-height: 10px;
  $track-height: 2px;
  // colours
  $upper-color: #cfcfcf;
  $lower-color: #0199ff;
  $thumb-color: #0199ff;
  $thumb-hover-color: #55c2ff;

  $upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100%
    50% / 100% $track-height no-repeat transparent;
  $lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100%
    50% / 100% $track-height no-repeat transparent;

  // Webkit cannot style progress so we fake it with a long shadow
  // on the thumb element
  @function webkit-slider-thumb-shadow($i: 1) {
    $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};
    @if $i == 1 {
      @for $k from 2 through 1000 {
        $val: #{$val}, webkit-slider-thumb-shadow($k);
      }
    }
    @return $val;
  }

  .e-range {
    display: block;
    appearance: none;
    width: 100%;
    margin: 0 10px;
    height: $height;
    overflow: hidden;
    cursor: pointer;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }

  // Webkit
  .e-range::-webkit-slider-runnable-track {
    width: 100%;
    height: $height;
    background: $lower-background;
  }

  .e-range::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: $thumb-height;
    width: $thumb-height;
    background: $white;
    border-radius: 100%;
    border: 1px solid $primary-blue;
    top: 50%;
    margin-top: (-$thumb-height/2);
    box-shadow: webkit-slider-thumb-shadow();
    transition: background-color 150ms;
  }

  // Firefox
  .e-range::-moz-range-track,
  .e-range::-moz-range-progress {
    width: 100%;
    height: $height;
    background: $upper-background;
  }

  .e-range::-moz-range-progress {
    background: $lower-background;
  }

  .e-range::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: $thumb-height;
    width: $thumb-height;
    background: $white;
    border-radius: 100%;
    border: 1px solid $primary-blue;
    transition: background-color 150ms;
  }

  // Internet Exploder
  .e-range::-ms-track {
    width: 100%;
    height: $height;
    border: 0;
    // color needed to hide track marks
    color: transparent;
    background: transparent;
  }

  .e-range::-ms-fill-lower {
    background: $lower-background;
  }

  .e-range::-ms-fill-upper {
    background: $upper-background;
  }

  .e-range::-ms-thumb {
    appearance: none;
    height: $thumb-height;
    width: $thumb-height;
    background: $white;
    border-radius: 100%;
    border: 1px solid $primary-blue;
    transition: background-color 150ms;
    // IE Edge thinks it can support -webkit prefixes
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  // .slider {
  //   -webkit-appearance: none;
  //   width: 100%;
  //   height: 2px;
  //   background-color:$primary-blue;
  //   outline: none;
  //   -webkit-transition: .2s;
  //   transition: opacity .2s;
  //   align-self: center;
  //   margin-left: 10px;
  //   margin-right: 15px;
  //   border-radius: 10px;
  // }

  // .slider::-webkit-slider-thumb {
  //   position: relative;
  //   -webkit-appearance: none;
  //   appearance: none;
  //   width: 10px;
  //   height: 10px;
  //   background:$white;
  //   border:1px solid $primary-blue;
  //   cursor: pointer;
  //   border-radius: 100%;
  // top: 50%;
  // margin-top: (-10/2);
  //   box-shadow: webkit-slider-thumb-shadow();
  //   transition: background-color 150ms;
  // }
  // .slider::-moz-range-track,
  // .slider::-moz-range-progress {
  //   width: 100%;
  //   height: 30px;
  //   background: $dark-gray;
  // }

  .time {
    font-family: "roboto";
    font-size: 11px;
    color: $dark-gray;
    align-self: center;
  }
  .loader {
    border: 1px solid $white;
    border-radius: 50%;
    border-top: 1px solid $primary-blue;
    width: 30px;
    height: 20px;
    -webkit-animation: spin 1.5s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;
    margin: auto;
  }
}
