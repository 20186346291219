@import '../../GlobalVariables/colors.scss';

.__BixTextEditor-Wrapper {
  position: sticky;
  border-radius: 16px;
  padding: 10px;
  background: $editor-background;

  .offline-text {
    color: red;
    font-size: 12px;
    margin-right: 16px;
  }

  .text-area {
    display: flex;
    justify-content: space-around;

    .subject-collopse {
      cursor: pointer;
      position: relative;
      align-self: center;
      margin-top: 20px;
      img {
        position: absolute;
        cursor: pointer;
        min-width: 18px;
        height: 18px;
        bottom: 0;
      }
    }
    .send-btn-wrapper {
      cursor: pointer;
      position: relative;
      margin-right: 20px;
      margin-top: 20px;
      align-self: center;

      .img-send {
        position: absolute;
        bottom: 0;
        min-width: 18px;
      }
      .send-anim {
        position: absolute;
        bottom: 0;
        min-width: 18px;
        animation: send 1s;
        animation-fill-mode: forwards;
      }
      @keyframes send {
        0% {
          min-width: 18px;
          border-radius: 50%;
        }
        100% {
          min-width: 24px;
          background-color: $primary-blue;
          padding: 5px;
          border-radius: 50%;
        }
      }
      img:hover {
        min-width: 16px;
      }
    }
    .popOver {
      position: absolute;
      background-color: $modal-background;
      right: 4px;
      bottom: 85%;
      border-radius: 4px;
      padding-top: 12px;
      padding-bottom: 12px;
      width: max-content;
      font-family: "roboto";
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      color: $text-black;
      font-size: 14px;
    }
    .sections {
      display: flex;
      margin-bottom: 5px;
      cursor: pointer;
      padding: 2px 20px;
    }
    .sections:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    .editable-text-area {
      font-family: "Roboto";
      width: 90%;
      margin: 0 25px;
      padding: 5px;
      outline: none;
      background: $editor-background !important;
      cursor: text;
    }
    .editable-text-area2 {
      font-family: "Roboto";
      width: 100%;
      outline: none;
      background: $editor-background !important;
      font-size: 12px;
      max-height: 120px;
      overflow-y: scroll;
      margin-left: 5px;

      .editable {
        position: relative;
        outline: none;
        overflow-wrap: break-word;
        white-space: break-spaces;
        font-variant-ligatures: none;
        font-feature-settings: "liga" 0;
      }
    }

    .attachment-area {
      display: flex;
      flex-wrap: wrap;
      .attachment {
        display: flex;
        background-color: $white;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-weight: bold;
        border-radius: 5px;
        margin-right: 15px;
        margin-bottom: 10px;
        align-items: center;
      }
      .close-icon {
        height: 30px;
        width: 26px;
        margin-left: 30px;
        cursor: pointer;
      }
    }
  }
  .text-editor-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 10px;
    .left-options {
      display: flex;
      justify-content: space-between;
      background-color: transparent;
      .button-option {
        text-align: center;
        border-radius: 5px;
        margin-right: 3px;
        font-size: 13px;
        font-weight: bold;
        color: $dark-gray;
        background: $editor-background;
        border: none;
        cursor: pointer;
        width: 20px;
      }
      // .button-option:hover {
      //   opacity: 0.5;
      // }
      .active {
        background: rgba(157, 157, 157, 0.29);
        text-align: center;
        border-radius: 5px;
        margin-right: 3px;
        font-size: 13px;
        font-weight: bold;
        color: $dark-gray;
        border: none;
        cursor: pointer;
        width: 20px;
      }
    }
    .right-options {
      display: flex;
      .editor-bottom-text {
        display: flex;
        width: 260px;
        .text {
          color: $dark-gray;
          font-size: 11px;
          font-family: "Roboto";
          font-weight: normal;
          align-self: center;
          margin-right: 10px;
          width: max-content;
          .left-text {
            font-weight: 600;
          }
        }
      }

      .single-option {
        height: 18px;
        width: 18px;
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .text-editor-footer {
      .right-options {
        .text {
          display: none;
        }
      }
    }
  }
}
[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block;
  color: $dark-gray;
  font-size: 12px;
}
.block-menu-trigger {
  display: none !important;
}
.placeholder {
  color: $dark-gray;
  font-weight: normal;
  text-decoration: none;
  font-style: normal;
}
.ProseMirror {
  justify-content: center;
  align-content: center;
  max-height: 100px;
  overflow-y: scroll;
}
.sc-gtsrHT {
  display: none !important;
}
.MuiList-root {
  background-color: $modal-background;
  box-shadow: none;
}

.sc-bkbkJK {
  display: none !important;
}
.heading-actions {
  display: none !important;
}
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.underline {
  text-decoration: underline;
}

.placeholder:before {
  background-color: blue;
  display: none;
  color: $dark-gray;
  content: attr(placeholder);
}
.placholder-span {
  color: $dark-gray;
  position: absolute;
  margin-top: -18px;
}

.pop-up {
  background-color: $modal-background;
  position: absolute;
  z-index: 3;
  font-size: 12px;
  padding: 8px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 120px;
  margin-top: -80px;
  margin-left: -115px;
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.16);
  .box-style {
    background-color: $white;
    height: 18px;
  }

  .item {
    display: flex;
    align-self: center;
    font-family: Roboto;
    color: $text-black;
    font-size: 12px;
    margin-bottom: 3px;
    padding: 3px;
    cursor: pointer;

    .img {
      width: 17px;
      height: 17px;
      align-self: center;
      margin-right: 8px;
      margin-left: -2px;
    }
  }
  .item:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
