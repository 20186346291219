@import '../../../GlobalVariables/colors.scss';
.passwordInputContainer {
  font-family: "roboto";
  .password-input-label {
    font-size: 14px;
    color: $primary-blue;
  }
  .input-cont {
    min-width: 100%;
    border-bottom: $login-underline-gray 1px solid;
    display: flex;
    padding-bottom: 5px;
    width: 100%;
    flex: 1;
    .icon {
      height: 15px;
      width: 15px;
      cursor: pointer;
      margin-right: 10px;
      margin-top: 3px;
    }
    .icon-2 {
        height: 17px;
        width: 17px;
        cursor: pointer;
      }
    input {
      width: 100%;
      border: none;
      outline: none;
      color: $black;
      font-size: 14px;
    }
  }
  ::placeholder {
    color: $dark-gray;
    font-family:"roboto";
    font-size: 14px;
  }

  .required {
    font-size: 11px;
    font-weight: normal;
    color: $form-fail;
    align-items: center;
    margin-top: 5px;
    margin-bottom: -10px;
  }
}
