@import '../../../GlobalVariables/colors.scss';
.__SendNewMessage {
  .modal-content-new {
    box-shadow: 2px 6px 28px 0px rgba(0, 0, 0, 0.75) !important;
    border-radius: 10px !important;
    right: 0;
    bottom: 0;
    position: absolute;
    margin-right: 10px;
    margin-bottom: 10px;
    z-index: 2;
    width: 600px;
  }
  .modal-card-body {
    padding: 0 !important;
    width: 100%;
    margin-left: 0;
    display: inline;
  }
  .send-new-message-others-nav {
    width: 100%;
    height: 60px;
    background: $primary-blue;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    padding: 13px;
    color: $white;
    font-size: 14px;
    align-items: center;
    font-weight: 600;
    .send-new-message-others-close {
      cursor: pointer;
      height: 15px;
      width: 15px;
      margin-right: 15px;
    }
    div {
      margin-right: 20px;
      cursor: default;
    }
  }
  .send-new-message-others-div1{
    display: flex;
    width: 100%;
  }

  .send-new-message-others-div2{
    display: flex;
  }

  .tagging-people{
    display: none;
    
  }

  .selected-tagging-people{
     margin-right: 5px;
      font-size: 13px;
      font-family: "Roboto";
      display: flex;
      font-weight: 300;
      

    
    
  }

  .dash2{
    font-size: 11px;
    margin-top: -5px;
  }



  

  .send-new-message-others-to {
    display: flex;
    border-bottom: $gray solid 0.7px;
    margin: 20px;
    
    .input-cont{
      display: block;
      width: 100%;
      .top-cont{
        display: flex;
        width: 100%;
      }
    }
    .cc-option{
      cursor: pointer;
      float: right;
      padding-top: 5px;
  
    }



    .cc-selected{
      display: none;

      float: left;
    }

    .cc-option2{
      display: none;
    }

  



    .lable {
      margin-right: 5px;
      margin-bottom: 10px;
      font-size: 13px;
      font-family: "Roboto";
      display: flex;
      font-weight: 500;
      margin-top: 10px;
      .dash {
        margin-left: 5px;
      }

      

     
    }
    input {
      min-width:49%;
      width: 100%;
      margin-left: 10px;

    }
  }
  .react-multi-email {
    max-width: 88%;
   
}

  .main-message-box-footer-new {
    padding: 10px;
    .subject-input {
      height: 38px;
      margin: 20px 20px 4px;
      padding: 8px;
      border-radius: 16px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: $light-gray;
      display: flex;
      input {
        width: 100%;
        background: $light-gray;
        border: none;
        padding: 7px;
        align-self: center;
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        outline: none;
      }
      img {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
    }
  }

  



  .react-multi-email [data-tag] {
    background: rgba($color: $dark-gray, $alpha: 0.4);
    border-radius: 16px;
    font-weight: normal;
    color: $text-black;
    font-size: 12px;
    padding: 6px 20px;
    margin-left: 10px;
  }

}
@media screen and (max-width: 600px) {
  .__SendNewMessage{
    .send-new-message-others-nav {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  
    .custom-toolbar-example{
      width: 100%;
    }
    .modal-content-new {
      margin-right: 0px;
      width: 100%;
      margin-bottom: 0;
    }
    .main-message-box-footer-new{
      padding: 0;
    }
  }
  
}
