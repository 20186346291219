@import '../../GlobalVariables/colors.scss';
$defultTextFont: "SF Pro Display", sans-serif;


.multi-email{
    border: 0px solid transparent;
    padding-top: 9px;
}

.multi-email::placeholder{
    color: $placeholder-gray;
    font-family:"Roboto";
    font-size: 13px;

}

.email-labels{
    display: flex;
    flex-wrap: wrap;
    padding-left: 2px;
}

.single-label{
    font-family: $defultTextFont;
    font-size: 13px;
    background-color: $gray;
    width: max-content;
    padding: 3px 20px 3px 20px;
    margin-left: 10px;
    margin-top: 6px;
    border-radius: 16px;
    display: flex;

}

.closex{
    color: $text-black;
    margin-left: 10px;
    font-family: $defultTextFont;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-items: center;
    cursor: pointer;

}


.sug-background{
    display: block;
    position: absolute;
    z-index: 1;
    width: 70%;
    overflow-y: auto;
    overflow-x: hidden;
    align-content: center;
    background-color: $white;
    max-height: 175px;
    box-shadow: 2px 5px 8px 0px  !important;
    margin-top: 45px;
    margin-left: 10px;
    transition: opacity 0.3s;

}

.sugestion-wrapper{
    visibility: visible;
    background-color: $white;
    // position: absolute;
    overflow-y: auto;
    z-index: 1;
    opacity: 1;

}